import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { cloneDeep } from 'lodash';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { fetchCustomerList } from '../../../redux/customer/list';
import { fetchPatientList } from '../../../redux/patient/list';
import { fetchDoctorList } from '../../../redux/doctor/list';
import { addAppointment } from '../../../redux/appointment/ops';
import {
  TIME_SLOT,
  TIME_SLOT_GROOMING
} from './components/AppointmentTimeSlot';

import AppointmentForm from './components/AppointmentForm';

const useStyles = makeStyles({
  root: {
    '& .MuiMenu-paper': {
      display: 'none'
    }
  },
  paper: {
    display: 'none !important'
  },
  menuRoot: {
    '& .MuiMenu-paper': {
      display: 'none'
    }
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  timeSlotButtonSelected: {}
});

const AppointmentAddPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // useEffect(() => {
  //   dispatch(fetchCustomerList());
  //   dispatch(fetchPatientList());
  //   dispatch(fetchDoctorList());
  // }, []);

  const { data: medicalRecordTypeList } = useSelector(
    state => state.medicalRecordType.list
  );
  const { data: ownerList } = useSelector(state => state.customer.list);
  const { data: patientList } = useSelector(state => state.patient.list);
  const { data: doctorList } = useSelector(state => state.doctor.list);

  const { completed } = useSelector(state => state.appointment.ops);
  const isFetching = useSelector(state => state.loading.appointment);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      owner: '',
      patient: '',
      doctor: '',
      date: new Date(),
      startTime: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      owner: Yup.string().required('Cannot be empty'),
      patient: Yup.string().required('Cannot be empty'),
      doctor: Yup.string().required('Cannot be empty'),
      date: Yup.date().required('Cannot be empty'),
      startTime: Yup.string().required('Cannot be empty'),
      duration: Yup.number().required('Cannot be empty')
    }),
    onSubmit: values => {}
  });

  const [endTime, setEndTime] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(TIME_SLOT);
  useEffect(() => {
    setSelectedTimeSlot(
      cloneDeep(values.name !== 'GROOMING' ? TIME_SLOT : TIME_SLOT_GROOMING)
    );
  }, [values.name]);

  useEffect(() => {
    let firstOccurenceKey = undefined;

    for (let key of Object.keys(selectedTimeSlot)) {
      if (selectedTimeSlot[key]) {
        firstOccurenceKey = key;
        break;
      }
    }

    setFieldValue('startTime', firstOccurenceKey || '');

    const duration = Object.keys(selectedTimeSlot).reduce(
      (total, curr) => (selectedTimeSlot[curr] ? ++total : total),
      0
    );

    setFieldValue('duration', duration || '');

    const lastOccuringTimeSlot = Object.keys(selectedTimeSlot)
      .reverse()
      .find(key => selectedTimeSlot[key]);

    if (!lastOccuringTimeSlot) return;

    const [hour, minute] = lastOccuringTimeSlot?.split(':');
    let newEndTime;
    if (minute === '00') {
      newEndTime = `${hour}:30`;
    } else if (minute === '30') {
      const nextHour = (parseInt(hour) + 1).toString().padStart(2, '0');
      newEndTime = `${nextHour}:00`;
    }

    newEndTime && setEndTime(newEndTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeSlot]);

  useEffect(() => {
    if (!values.startTime) setEndTime(undefined);
  }, [values.startTime]);

  return <AppointmentForm isEditing={true} />;
};

export default AppointmentAddPage;

const AppointmentTimeSlot = ({ selectedTimeSlot, setSelectedTimeSlot }) => {
  const isSlotAdjacent = selectedKey => {
    const keyList = Object.keys(selectedTimeSlot);

    let firstSelectedOccurenceIndex = -1;
    let lastSelectedOccurenceIndex = -1;

    for (let i = 0; i < keyList.length; i++) {
      if (selectedTimeSlot[keyList[i]]) {
        firstSelectedOccurenceIndex = i;
        break;
      }
    }

    if (firstSelectedOccurenceIndex === -1) return true;

    for (let i = keyList.length - 1; i > 0; i--) {
      if (selectedTimeSlot[keyList[i]]) {
        lastSelectedOccurenceIndex = i;
        break;
      }
    }

    const selectedKeyIndex = keyList.indexOf(selectedKey);

    if (
      selectedKeyIndex === firstSelectedOccurenceIndex - 1 ||
      selectedKeyIndex === firstSelectedOccurenceIndex + 1 ||
      selectedKeyIndex === lastSelectedOccurenceIndex - 1 ||
      selectedKeyIndex === lastSelectedOccurenceIndex + 1
    ) {
      return true;
    }
    return false;
  };

  const handleTimeSlotClick = e => {
    const key = e.currentTarget.value;

    if (!selectedTimeSlot[key]) {
      if (isSlotAdjacent(key)) {
        setSelectedTimeSlot({
          ...selectedTimeSlot,
          [key]: !selectedTimeSlot[key]
        });
      } else {
        const newSelectedTimeSlot = { ...selectedTimeSlot };
        for (let iKey of Object.keys(newSelectedTimeSlot)) {
          newSelectedTimeSlot[iKey] = iKey === key ? true : false;
        }
        setSelectedTimeSlot(newSelectedTimeSlot);
      }
    } else {
      setSelectedTimeSlot({
        ...selectedTimeSlot,
        [key]: !selectedTimeSlot[key]
      });
    }
  };

  return (
    <Grid container>
      {Object.keys(selectedTimeSlot).map(key => (
        <Grid item xs={3} key={key}>
          <Button
            value={key}
            onClick={handleTimeSlotClick}
            color={selectedTimeSlot[key] ? 'primary' : 'default'}
            variant={selectedTimeSlot[key] ? 'contained' : 'text'}
            fullWidth
          >
            {key}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
