import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    Assignment as AssignmentIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import { endOfWeek, format, parseISO, startOfDay } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
    Card,
    CardBody,
    CardHeader
} from '../../../_metronic/_partials/controls';
import { sortCaret } from '../../../_metronic/_helpers';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import moment from "moment"
import qs from "qs"
import {
    fetchAppointmentByDoctor,
    fetchAppointmentByDoctorAndDateRange,
} from '../../../redux/appointment/list';
import appointmentModelAppointment from 'app/models/Appointment';

const AppointmentListDoctor = (props) => {
    let contentTimer;
    const dispatch = useDispatch();
    const { id } = useParams();
    const { role } = useSelector(state => state.auth);

    const history = useHistory();

    const editSearchQueryValue = (value) => {
        clearTimeout(contentTimer);

        contentTimer = setTimeout(async () => {
            setSearchQuery(value)
            changeHistoryPush(value)
            // history.push(`/appointment?q=${value}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
        }, 500);
    }

    const [searchQuery, setSearchQuery] = useState("")
    const [searchRef, setSearchRef] = useState(null)
    const [defaultSearchValue, setDefaultSearchValue] = useState(null)

    const usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    useEffect(() => {
        // dispatch(fetchAppointment());
    }, []);

    const { data } = useSelector(state => state.appointment.list);
    const isFetching = useSelector(state => state.loading.appointment);
    const { completed } = useSelector(state => state.appointment.ops);
    const previousCompleted = usePrevious(completed);
    const { completed: approveCompleted } = useSelector(
        state => state.appointment.approve
    );

    useEffect(() => {
        if (completed && completed !== previousCompleted) {
            fetchAppointmentConditionally();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed, previousCompleted]);

    useEffect(() => {
        approveCompleted && fetchAppointmentConditionally();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveCompleted]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [appointmentType, setAppointmentType] = useState('');

    const onChangeStartDate = (newValue) => {
        setStartDate(newValue)
        changeHistoryPush()
    }

    const onChangeEndDate = (newValue) => {
        setEndDate(newValue)
        changeHistoryPush()
    }

    const changeHistoryPush=(value)=>{

        let sd = moment(startDate).format('yyyy-MM-DD HH:mm:ss')
        let ed = moment(endDate).format('yyyy-MM-DD HH:mm:ss')

        if(value){
          history.push(`/doctor/${id}/appointment?q=${value}&startDate=${sd}&endDate=${ed}`)
        }else{
          history.push(`/doctor/${id}/appointment?q=${searchQuery}&startDate=${sd}&endDate=${ed}`)
        }
      }


    useEffect(() => {
        setStartDate(startOfDay(new Date()));
        // setEndDate(addDays(startOfDay(new Date()), 1));
        setEndDate(endOfWeek(startOfDay(new Date()), { weekStartsOn: 1 }));
    }, []);

    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        setFilteredData(data);
    }, [data, appointmentType]);


  useEffect(()=>{
    const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix : true})
    if(queryParams.q){
      setDefaultSearchValue(queryParams.q)
      setSearchQuery(queryParams.q)
    }

    console.log("QP", queryParams.startDate, queryParams.endDate)
    if(queryParams.startDate){
      setStartDate(new Date(queryParams.startDate))
    }
    if(queryParams.endDate){
      setEndDate(new Date(queryParams.endDate))
    }
  }, [])

    useEffect(() => {
        fetchAppointmentConditionally();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const getAppointmentByDoctorAndDateRangeForDoctor = async (formattedStartDate, formattedEndDate) => {
        const appointmentModel = new appointmentModelAppointment()

        try {
            let result = await appointmentModel.getDoctorAppointmentByWeekForDoctor(formattedStartDate, formattedEndDate)
            dispatch(({ type: 'appointment.list/FETCH_SUCCESS', payload: result }))
        }catch(e){
            console.log('error', e)
        }
    }
    const fetchAppointmentConditionally = () => {
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd HH:mm:ss');
            console.log('formattedEndDate', formattedEndDate)
            if(role === 'DOCTOR'){
                getAppointmentByDoctorAndDateRangeForDoctor(formattedStartDate, formattedEndDate)
            }else{
                dispatch(
                    fetchAppointmentByDoctorAndDateRange(id, formattedStartDate, formattedEndDate)
                );
            }
        } else if (startDate === null && endDate === null) {
            dispatch(fetchAppointmentByDoctor());
        }
    };
    const columns = [
        {
            dataField: 'start_time',
            text: 'time',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return cell && format(parseISO(cell), 'yyyy-MM-dd HH:mm');
            },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'customer_name',
            text: 'customer',
            formatter: (cell, row) => {
                return <Link to={`/customer/${row?.customer_id}`}>{cell}</Link>;
            }
        },
        {
            dataField: 'patient_name',
            text: 'patient'
        },
        {
            dataField: 'doctor_name',
            text: 'doctor'
        },
        {
            dataField: 'booking_type_name',
            text: 'type',
            formatter: (cell, row) => {
                return row.bookingType?.booking_type_name
            }
        },
        {
            dataField: 'status',
            text: 'status'
        }
    ];

    const handleClearDateClick = () => {
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <>
            <Card>
                <CardHeader title="My Appointment List">

                </CardHeader>
                <CardBody>
                    <DataTable
                        data={filteredData}
                        loading={isFetching}
                        totalDataCount={filteredData.length}
                        columns={columns}
                        editLink="/appointment/"
                        noEditAction
                        handleSearchChange={(value) => {
                            // setSearchQuery(value)
                            editSearchQueryValue(value)
                            // console.log("boi", value)
                        }}
                        setSearchRef={setSearchRef}
                        renderCustomActions={row => {
                            console.log('isi row', row)
                            return (
                                <Link to={`/appointment/${row}`}>
                                    <IconButton
                                        size="small"
                                    >
                                        <AssignmentIcon />
                                    </IconButton>
                                </Link>
                            );
                        }}
                        customActionBar={
                            <Grid container alignItems="center">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item style={{ marginLeft: '1rem' }}>
                                        <DatePicker
                                            value={startDate}
                                            onChange={onChangeStartDate}
                                            label="Start date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            style={{
                                                width: '160px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{ marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                    >
                                        <span> - </span>
                                    </Grid>
                                    <Grid item>
                                        <DatePicker
                                            disabled={!startDate}
                                            value={endDate}
                                            onChange={onChangeEndDate}
                                            label="End date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            style={{
                                                width: '160px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleClearDateClick} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                    {/* <Grid item style={{ marginLeft: '1rem' }}>
                    <TextField
                      value={appointmentType}
                      onChange={e => {
                        setAppointmentType(e.target.value);
                      }}
                      select
                      label="Type"
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      <MenuItem value="CHECK-UP">CHECK-UP</MenuItem>
                      <MenuItem value="GROOMING">GROOMING</MenuItem>
                      <MenuItem value="RAJANTI TALK WITH ANIMALS">
                        RAJANTI TALK WITH ANIMALS
                      </MenuItem>
                    </TextField>
                  </Grid> */}
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }
                    />
                </CardBody>
            </Card>

        </>
    )
}

export default AppointmentListDoctor
