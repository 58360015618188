import React from 'react';
import { Button, Grid, Radio } from '@material-ui/core';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

const DiagnosisResultCard = ({ data, handleReset }) => {
  const handleAddToMedicalRecord = () => {
    alert('Feature coming soon');
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Diagnosis Result"></CardHeader>
        <CardBody>
          <Grid
            container
            item
            xs={12}
            style={{
              fontSize: '0.9rem',
              borderBottom: '1px solid #00000040',
              padding: '0.5rem 0',
              fontWeight: 'bold'
            }}
          >
            <Grid item style={{ width: '50px' }}></Grid>
            <Grid item xs={4}>
              Disease
            </Grid>
            <Grid item xs={4}>
              Matched Symptoms
            </Grid>
            <Grid item></Grid>
          </Grid>
          {data.map(disease => (
            <Grid
              container
              item
              xs={12}
              style={{
                fontSize: '1.1rem',
                borderBottom: '1px solid #00000040',
                padding: '0.5rem 0'
              }}
              alignItems="center"
            >
              <Grid item style={{ width: '50px' }}>
                <Radio color="primary" size="small" />
              </Grid>
              <Grid item xs={4}>
                {disease.key}
              </Grid>
              <Grid item xs={4}>
                {disease.symptoms.map(
                  (symptom, index) =>
                    `${index > 0 ? ', ' : ''}${symptom.symptom_name}`
                )}
              </Grid>
              <Grid
                item
              >{`${disease.symptoms_met} of ${disease.total_disease_symptoms} symptoms met`}</Grid>
            </Grid>
          ))}
          <Grid
            container
            spacing={1}
            item
            xs={12}
            style={{ marginTop: '2rem' }}
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddToMedicalRecord}
              >
                Add to Medical Record
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleReset}>Redo Diagnosis</Button>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </Grid>
  );
};

export default DiagnosisResultCard;
