import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'visitReminder.add/ADD_BEGIN';
export const ADD_SUCCESS = 'visitReminder.add/ADD_SUCCESS';
export const ADD_FAILED = 'visitReminder.add/ADD_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addVisitReminder = data => async dispatch => {
  dispatch(addBegin());

  const formattedData = {
    booking_type_name: data.appointmentType,
    target_send_date: data.date,
    patient_id: data.patientID,
    description: data.description
  };

  try {
    await api.post('add-visit-reminder', formattedData);

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case ADD_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case ADD_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
