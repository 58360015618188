import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchCustomerList } from '../../../redux/customer/list';

export default function CustomerListPage() {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Initial fetch
  useEffect(() => {
    dispatch(fetchCustomerList());
  }, []);

  const { data } = useSelector(state => state.customer.list);
  const isFetching = useSelector(state => state.loading.customer);

  const columns = [
    {
      dataField: 'user_name',
      text: 'name'
    },
    {
      dataField: 'mobile',
      text: 'phone'
    },
    {
      dataField: 'email',
      text: 'email'
    }
  ];

  return (
    <Card>
      <CardHeader title="Customer list">
        <ListHeaderToolbar addLink="/customer/add" addText="New Customer" />
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/customer/"
        />
      </CardBody>
    </Card>
  );
}
