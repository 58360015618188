import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';

import ButtonLink from '../../components/common/ButtonLink';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchDiseaseList } from '../../../redux/disease/list';

const DiseaseListPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Initial fetch
  useEffect(() => {
    dispatch(fetchDiseaseList());
  }, []);

  const { data } = useSelector(state => state.disease.list);
  const isFetching = useSelector(state => state.loading.disease);

  const columns = [
    {
      dataField: 'disease_name',
      text: 'Disease'
    }
  ];

  return (
    <Card>
      <CardHeader title="Disease list">
        <CardHeaderToolbar>
          <ButtonLink link="/disease/add" variant="contained" color="primary">
            New Disease
          </ButtonLink>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/disease/"
        />
      </CardBody>
    </Card>
  );
};

export default DiseaseListPage;
