import React from 'react';
import { Grid, Button } from '@material-ui/core';

const AppointmentTimeSlot = ({ selectedTimeSlot, setSelectedTimeSlot }) => {
  const isSlotAdjacent = selectedKey => {
    const keyList = Object.keys(selectedTimeSlot);

    let firstSelectedOccurenceIndex = -1;
    let lastSelectedOccurenceIndex = -1;

    for (let i = 0; i < keyList.length; i++) {
      if (selectedTimeSlot[keyList[i]]) {
        firstSelectedOccurenceIndex = i;
        break;
      }
    }

    if (firstSelectedOccurenceIndex === -1) return true;

    for (let i = keyList.length - 1; i > 0; i--) {
      if (selectedTimeSlot[keyList[i]]) {
        lastSelectedOccurenceIndex = i;
        break;
      }
    }

    const selectedKeyIndex = keyList.indexOf(selectedKey);

    if (
      selectedKeyIndex === firstSelectedOccurenceIndex - 1 ||
      selectedKeyIndex === firstSelectedOccurenceIndex + 1 ||
      selectedKeyIndex === lastSelectedOccurenceIndex - 1 ||
      selectedKeyIndex === lastSelectedOccurenceIndex + 1
    ) {
      return true;
    }
    return false;
  };

  const handleTimeSlotClick = e => {
    const key = e.currentTarget.value;

    if (!selectedTimeSlot[key]) {
      if (isSlotAdjacent(key)) {
        setSelectedTimeSlot({
          ...selectedTimeSlot,
          [key]: !selectedTimeSlot[key]
        });
      } else {
        const newSelectedTimeSlot = { ...selectedTimeSlot };
        for (let iKey of Object.keys(newSelectedTimeSlot)) {
          newSelectedTimeSlot[iKey] = iKey === key ? true : false;
        }
        setSelectedTimeSlot(newSelectedTimeSlot);
      }
    } else {
      setSelectedTimeSlot({
        ...selectedTimeSlot,
        [key]: !selectedTimeSlot[key]
      });
    }
  };

  return (
    <Grid container>
      {Object.keys(selectedTimeSlot).map(key => (
        <Grid item xs={3} key={key}>
          <Button
            value={key}
            onClick={handleTimeSlotClick}
            color={selectedTimeSlot[key] ? 'primary' : 'default'}
            variant={selectedTimeSlot[key] ? 'contained' : 'text'}
            fullWidth
          >
            {key}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default AppointmentTimeSlot;

export const TIME_SLOT = {
  '08:00': false,
  '08:30': false,
  '09:00': false,
  '09:30': false,
  '10:00': false,
  '10:30': false,
  '11:00': false,
  '11:30': false,
  '12:00': false,
  '12:30': false,
  '13:00': false,
  '13:30': false,
  '14:00': false,
  '14:30': false,
  '15:00': false,
  '15:30': false,
  '16:00': false,
  '16:30': false,
  '17:00': false,
  '17:30': false,
  '18:00': false,
  '18:30': false,
  '19:00': false,
  '19:30': false,
  '20:00': false,
  '20:30': false,
  '21:00': false,
  '21:30': false
};

export const TIME_SLOT_GROOMING = {
  '08:00': false,
  '08:30': false,
  '09:00': false,
  '09:30': false,
  '10:00': false,
  '10:30': false,
  '11:00': false,
  '11:30': false,
  '12:00': false,
  '12:30': false,
  '13:00': false,
  '13:30': false,
  '14:00': false,
  '14:30': false
};
