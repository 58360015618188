/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

const AsideMenuList = ({ layoutProps }) => {
  const { doctorID } = useSelector(state => state.auth);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive(`/doctor/${doctorID}/schedule`, false)}`}>
          <NavLink className="menu-link" to={`/doctor/${doctorID}/schedule`}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">My Schedule</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(`/doctor/${doctorID}/appointment`, false)}`}
        >
          <NavLink className="menu-link" to={`/doctor/${doctorID}/appointment`}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">My Appointment</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(`/v2/medical-records`, false)}`}
        >
          <NavLink className="menu-link" to={`/v2/medical-records`}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Medical Records</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default AsideMenuList;
