import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
  deleteButton: {
    marginRight: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem !important',
    },
  },
})