import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { fetchAnimalCategoryList } from '../../../redux/animalCategory/list';
import { fetchAnimalType } from '../../../redux/animalType/detail';
import {
  editAnimalType,
  deleteAnimalType
} from '../../../redux/animalType/ops';

const AnimalEditPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Animal Category
  const { data: animalCategoryList } = useSelector(
    state => state.animalCategory.list
  );
  useEffect(() => {
    dispatch(fetchAnimalCategoryList());
  }, []);

  const { id } = useParams();
  const isFetching = useSelector(state => state.loading.animalType);

  const { data } = useSelector(state => state.animalType.detail);
  useEffect(() => {
    dispatch(fetchAnimalType(id));
  }, []);

  const { completed } = useSelector(state => state.animalType.ops);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    resetForm,
    setFieldValue,
    setFieldError
  } = useFormik({
    initialValues: {
      category: data?.animal_category.id || '',
      name: data?.animal_name || ''
    },
    validationSchema: Yup.object({
      category: Yup.string().required('Cannot be empty'),
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(editAnimalType(id, values));
    },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Animal type details">
        <HeaderToolbar
          isEditing={isEditing}
          handleEdit={() => setIsEditing(true)}
          handleCancel={() => {
            resetForm();
            setIsEditing(false);
          }}
        />
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="category"
                select
                value={values.category}
                onChange={event => {
                  setFieldValue('category', event.target.value);
                }}
                label="Category *"
                helperText={touched.category ? errors.category : ''}
                error={touched.category && errors.category ? true : false}
              >
                {animalCategoryList.map(({ id, category_name }) => (
                  <MenuItem value={id}>{category_name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Animal Type *"
                helperText={touched.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <ActionBar
              isEditing={isEditing}
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleDelete={() => dispatch(deleteAnimalType(id))}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default AnimalEditPage;
