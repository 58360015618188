import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { Assignment as AssignmentIcon } from '@material-ui/icons';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import OperationTable from 'app/models/OperationTable';

const OperationTableListPage = () => {
  let [operationTableData, setOperationTableData] = useState([]);

  const fetchOperationLists = async () => {
    let operationTableModel = new OperationTable();
    try {
      let result = await operationTableModel.getAllOperation();

      setOperationTableData(result);
      console.log(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };

  useEffect(() => {
    fetchOperationLists();
  }, []);

  const columns = [
    {
        text: 'Name',
        dataField: 'name'
      },
    {
      text: 'Type',
      dataField: 'type'
    },
    {
      text: 'Clinic',
      dataField: 'clinic.name'
    }
  ];

  return (
    <>
      <Card>
        <CardHeader title="Operation Table">
          <ListHeaderToolbar addLink="/operation/add" addText="New Operation Table" />
        </CardHeader>
        <CardBody>
          <DataTable
            data={operationTableData}
            totalDataCount={operationTableData.length}
            columns={columns}
            editLink='/operation/edit/'
          />
        </CardBody>
      </Card>
    </>
  );
};

export default OperationTableListPage;
