import { Typography } from '@material-ui/core';
import {differenceInMinutes, format, parseISO} from 'date-fns';
import clsx from 'clsx';

import { useStyles, CELL_HEIGHT } from '../TimetableCss';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";

import moment from "moment";

const EventMarker = ({ event, faded = false, key, banned }) => {
    const backgroundStatus = () => {
        // console.log("EVS", event.status, event.faded)
        if (event.status === 'DONE') {
            return classes.eventDone;
        } else if (event.status === 'WAITING') {
            return classes.eventWaiting;
        } else if (event.status === 'BAN') {
            return classes.eventBan;
        } else {
            return classes.eventUnDone;
        }
    }
    const classes = useStyles();

    // const diffMinutes =
    //   (15.0 *
    //     Math.ceil(differenceInMinutes(event.endDate, event.startDate) / 15.0)) /
    //   60;
    const diffMinutes = differenceInMinutes(event.endDate, event.startDate) / 60;

    const clickableProps = event.clickAction
        ? {
            onClick: () => event.clickAction?.(event),
            tabIndex: 0
        }
        : {};

    const minute0 = toJakartaDate(parseISO(moment(event.slotStartTime).set("minute", 0).toISOString()))

    const startMinute = differenceInMinutes(event.startDate, minute0);

    // console.log("DIFF", startMinute, diffMinutes,event.startDate, event.endDate, event.slotStartTime)

    const unitNoRem = CELL_HEIGHT.replace(/[^0-9]/g, '');

    return (
        <OverlayTrigger
            placement={moment(event.startDate).weekday() === 0 ? "left" : "right"}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">
                {
                    banned ?
                        <div style={{color:"black"}}>Time Slot Banned</div>
                        :
                        <>
                            {event.title}
                            {event.clinic_name ? <><br /><small>Clinic: {event.clinic_name}</small></> : ""}
                            {event.content ? <><br /><small>Patient: {event.content}</small></> : ""}
                            {event.customer ? <><br /><small>Owner: {event.customer}</small></> : ""}
                            {event.startDate ? <><br /><small>Mulai: {moment(event.startDate).format("HH:mm")}</small></> : ""}
                            {event.endDate ? <><br /><small>Selesai: {moment(event.endDate).format("HH:mm")}</small></> : ""}
                        </>
                }
                {/*{startMinute}/{diffMinutes} - {event.slotStartTime+""} / {event.endDate+""}*/}
                {/*<br/>*/}

            </Tooltip>}
        >
            <div
                {...clickableProps}
                className={clsx({
                    [classes.event]: true,
                    [backgroundStatus()]: true,
                    [classes.eventFaded]: faded,
                    [classes.eventClickable]: event.clickAction
                })}
                // style={{
                //     position:"relative"
                // }}
                style={{
                    height: `calc((${diffMinutes} * ${CELL_HEIGHT}) - 4px)`,
                    top: `calc(${startMinute / 60} * ${CELL_HEIGHT})`
                }}
            >
                {unitNoRem * diffMinutes >= unitNoRem / 2 && (
                    <Typography
                        className={classes.eventTitle}
                        style={{
                            whiteSpace: "pre-line"
                        }}
                    >{event.title}<br />
                    <small>{event.content ? `(${event.content})` : ""}</small></Typography>
                )}
                {/* <Typography className={classes.eventTime}>
        {`${format(event.startDate, 'HH:mm')} - ${format(
          event.endDate,
          'HH:mm'
        )}`}
      </Typography> */}
            </div>
        </OverlayTrigger>
    );
};

const toJakartaDate = date => {
    let tzDate = new Date(
        date.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
    );

    return tzDate;
};

export default EventMarker;
