import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

const BEGIN = 'resetPassword/BEGIN';
const SUCCESS = 'resetPassword/SUCCESS';
const FAILED = 'resetPassword/FAILED';

const begin = () => ({ type: BEGIN });
const success = () => ({ type: SUCCESS });
const failed = error => ({ type: FAILED, payload: error });
export const resetPassword = data => async dispatch => {
  dispatch(begin());

  const formattedData = {
    email: data.email,
    password: data.password
  };

  try {
    await api.post('reset-user', formattedData);

    dispatch(success());
    dispatch(setSuccessNotification("Successfully reset user's password"));
  } catch (error) {
    dispatch(failed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const resetPasswordByID = data => async dispatch => {
  dispatch(begin());

  const formattedData = {
    id: data.ID,
    password: data.password
  };

  try {
    await api.post('reset-user', formattedData);

    dispatch(success());
    dispatch(setSuccessNotification("Successfully reset user's password"));
  } catch (error) {
    dispatch(failed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
