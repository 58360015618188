import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Link as MuiLink } from '@material-ui/core';
import { WhatsApp as WhatsAppIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import crypto from 'crypto';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import {
  TextField,
  PasswordTextField
} from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { DataTableLocal as DataTable } from 'app/components/common/DataTable';

import { fetchCustomer } from '../../../redux/customer/detail';
import { editCustomer, deleteCustomer } from '../../../redux/customer/ops';
import { fetchPatientListByCustomerID } from 'redux/patient/list';

const CustomerEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { role } = useSelector(state => state.auth);
  const isFetching = useSelector(state => state.loading.customer);

  const { data } = useSelector(state => state.customer.detail);
  useEffect(() => {
    dispatch(fetchCustomer(id));
  }, []);

  const { completed } = useSelector(state => state.customer.ops);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.user_name || '',
      password: '',
      email: data?.email || '',
      phone: data?.mobile || '',
      dateOfBirth: data?.birthdate ? parseISO(data?.birthdate) : null,
      address: data?.address || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      password: Yup.string(),
      email: Yup.string().email('Invalid email'),
      phone: Yup.string().required('Cannot be empty'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable(),
      address: Yup.string()
    }),
    onSubmit: values => {
      const formattedValues = {
        ...values,
        password:
          values.password !== ''
            ? crypto
                .createHash('sha256')
                .update(values.password)
                .digest('hex')
            : data.password,
        dateOfBirth: format(values.dateOfBirth, 'yyyy-MM-dd')
      };

      dispatch(editCustomer(id, formattedValues));
    },
    enableReinitialize: true
  });

  const waNumber =
    data && data?.mobile.charAt(0) === '0'
      ? `62${data?.mobile.substring(1)}`
      : data?.mobile || '';

  return (
    <>
      <Card>
        <CardHeader title="Customer details">
          <HeaderToolbar
            isEditing={isEditing}
            handleEdit={() => setIsEditing(true)}
            handleCancel={() => {
              resetForm();
              setIsEditing(false);
            }}
          />
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Full Name *"
                  helperText={touched.name && errors.name ? errors.name : ''}
                  error={touched.name && errors.name ? true : false}
                />
              </Grid>
              {/* {isEditing && (
                <Grid item xs={12}>
                  <PasswordTextField
                    disabled={!isEditing}
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    label="Password *"
                    helperText={
                      touched.password && errors.password
                        ? errors.password
                        : "Leave empty to not change user's password"
                    }
                    error={touched.password && errors.password ? true : false}
                  />
                </Grid>
              )} */}
              {(role === 'ADMIN' || role === 'CS') && (
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Email"
                  helperText={touched.email && errors.email ? errors.email : ''}
                  error={touched.email && errors.email ? true : false}
                />
              </Grid>
              )}
              {(role === 'ADMIN' || role === 'CS') && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs>
                      <TextField
                        disabled={!isEditing}
                        id="phone"
                        value={values.phone}
                        onChange={handleChange}
                        label="Phone"
                        helperText={
                          touched.phone && errors.phone ? errors.phone : ''
                        }
                        error={touched.phone && errors.phone ? true : false}
                      />
                    </Grid>
                    {data && !isEditing && (
                      <Grid item>
                        <MuiLink
                          href={`https://wa.me/${waNumber}`}
                          target="_blank"
                        >
                          <IconButton color="primary" size="small">
                            <WhatsAppIcon />
                          </IconButton>
                        </MuiLink>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {(role === 'ADMIN' || role === 'CS') && (
              <Grid item xs={12}>
                <KeyboardDatePicker
                  disabled={!isEditing}
                  id="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={date => setFieldValue('dateOfBirth', date, true)}
                  label="Date of Birth"
                  helperText={
                    touched.dateOfBirth && errors.dateOfBirth
                      ? 'Invalid date'
                      : ''
                  }
                  error={
                    touched.dateOfBirth && errors.dateOfBirth ? true : false
                  }
                  disableFuture
                  clearable
                  format="dd/MM/yyyy"
                />
              </Grid>
              )}
              {(role === 'ADMIN' || role === 'CS') && (
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="address"
                  value={values.address}
                  onChange={handleChange}
                  label="address"
                  helperText={
                    touched.address && errors.address ? errors.address : ''
                  }
                  error={touched.address && errors.address ? true : false}
                />
              </Grid>
              )}
              <ActionBar
                isEditing={isEditing}
                isFetching={isFetching}
                handleSubmit={handleSubmit}
                handleDelete={() => dispatch(deleteCustomer(id))}
              />
            </Grid>
          </form>
        </CardBody>
      </Card>
      <PatientList customerID={id} />
    </>
  );
};

export default CustomerEditPage;

const PatientList = ({ customerID }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPatientListByCustomerID(customerID));
  }, []);

  let { data, isLoading } = useSelector(state => state.patient.list);

  const columns = [
    {
      dataField: 'patient_name',
      text: 'name'
    },
    {
      dataField: 'animal_name',
      text: 'animal'
    },
    {
      dataField: 'pet_owner_name',
      text: 'owner'
    }
  ];

  return (
    <Card>
      <CardHeader title="Patient list" />
      <CardBody>
        <DataTable
          data={data}
          loading={isLoading}
          totalDataCount={data.length}
          columns={columns}
          editLink="/patient/"
        />
      </CardBody>
    </Card>
  );
};
