import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';

import TextField from 'app/components/common/TextField';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { login, reset } from '../../../redux/auth';

const Login = () => {
  const dispatch = useDispatch();

  const { user, isFetching, error } = useSelector(state => state.auth);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginClick = () => {
    dispatch(login(username, password));
  };

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      window.location.reload();
    }
  }, [user]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="max-h-120px"
                  src={toAbsoluteUrl('/media/logos/logo.jpeg')}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  drh. Rajanti & Friends Admin Panel
                </h3>
                {/* <p className="font-weight-lighter text-white opacity-80">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur, culpa?
                </p> */}
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2020 CODEDOC.ID
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <div
                className="login-form login-signin"
                id="kt_login_signin_form"
              >
                {/* begin::Head */}
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleLoginClick();
                  }}
                >
                  <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                      {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                      Admin Login
                    </h3>
                    <div style={{ marginTop: '4rem', maxWidth: '300px' }}>
                      <TextField
                        value={username}
                        onChange={e => {
                          setUsername(e.target.value);
                        }}
                        label="Username"
                        style={{ marginBottom: '1rem' }}
                      />
                      <TextField
                        value={password}
                        onChange={e => {
                          setPassword(e.target.value);
                        }}
                        label="Password"
                        type="password"
                        style={{ marginBottom: '1rem' }}
                      />
                      <Button
                        disabled={isFetching}
                        onClick={handleLoginClick}
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                        startIcon={
                          isFetching && (
                            <CircularProgress
                              size={16}
                              style={{ color: 'gray' }}
                            />
                          )
                        }
                        style={{ marginBottom: '1rem' }}
                      >
                        Login
                      </Button>
                      <div style={{ color: 'red' }}>{error}</div>
                    </div>
                  </div>
                </form>
                {/* end::Head */}

                {/*begin::Form*/}
                {/* <form
                  // onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework"
                > */}
                {/* <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                    <div className="alert-text ">
                      Use account <strong>admin@demo.com</strong> and password{' '}
                      <strong>demo</strong> to continue.
                    </div>
                  </div> */}

                {/* <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder="Email"
                      type="email"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        'email'
                      )}`}
                      name="email"
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder="Password"
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        'password'
                      )}`}
                      name="password"
                      {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.password}
                        </div>
                      </div>
                    ) : null}
                  </div> */}
                {/* <div className="form-group d-flex flex-wrap justify-content-center align-items-center"> */}
                {/* <Link
                      to="/auth/forgot-password"
                      className="text-dark-50 text-hover-primary my-3 mr-2"
                      id="kt_login_forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                      Forgot Password
                    </Link> */}
                {/* <button
                    id="kt_login_signin_submit"
                    type="submit"
                    // disabled={formik.isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    onClick={handleLoginClick}
                  > */}
                {/* <span>Click to continue</span> */}
                {/* {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )} */}
                {/* </button> */}
                {/* </div> */}
                {/* </form> */}
                {/*end::Form*/}
              </div>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 Metronic
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

export default Login;
