import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import LoadingCard from '../../components/common/LoadingCard';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import SymptomAndLocationSelect from './components/SymptomAndLocationSelect';
import SymptomAndLocationSelectDisabled from './components/SymptomAndLocationSelectDisabled';
import SymptomAddDialog from './components/SymptomAddDialog';
import { usePrevious } from 'hooks/usePrevious';

import { fetchAnimalTypeList } from '../../../redux/animalType/list';
import { fetchSymptomList } from '../../../redux/symptom/list';
import { fetchSymptomLocationList } from '../../../redux/symptomLocation/list';
import { fetchMedicineList } from '../../../redux/medicine/list';
import { fetchDisease } from '../../../redux/disease/detail';
import { deleteDisease } from '../../../redux/disease/ops';
import { editDisease } from '../../../redux/disease/edit';

export default function DiseaseEditPage() {
  const dispatch = useDispatch();
  const { replace } = useHistory();

  useEffect(() => {
    dispatch(fetchAnimalTypeList());
    dispatch(fetchSymptomList());
    dispatch(fetchSymptomLocationList());
    dispatch(fetchMedicineList());
    dispatch(fetchDisease(id));
  }, []);

  const { id } = useParams();
  const isFetching = useSelector(state => state.loading.disease);
  const { data } = useSelector(state => state.disease.detail);
  const { isLoadingDelete } = useSelector(state => state.disease.ops);
  const isLoadingDeletePrevious = usePrevious(isLoadingDelete);

  const { isLoading: isLoadingEdit } = useSelector(state => state.disease.edit);
  const isLoadingEditPrevious = usePrevious(isLoadingEdit);

  useEffect(() => {
    if (!isLoadingEdit && isLoadingEditPrevious !== undefined && isLoadingEdit !== isLoadingEditPrevious) {
      setIsEditing(false);
      dispatch(fetchDisease(id));
    }
  }, [dispatch, id, isLoadingEdit, isLoadingEditPrevious]);

  useEffect(() => {
    if (!isLoadingDelete && isLoadingDeletePrevious !== undefined && isLoadingDelete !== isLoadingDeletePrevious) {
      replace('/disease');
    }
  }, [isLoadingDelete, isLoadingDeletePrevious, replace]);

  const { data: animalTypeList } = useSelector(state => state.animalType.list);
  const { data: symptomList } = useSelector(state => state.symptom.list);
  const { data: medicineList, isLoading } = useSelector(state => state.medicine.list);

  useEffect(() => {
    setStaticSymptomAndLocationList(data?.symptom_location_list);
    setSelectedSymptoms([]);
    const mappedMedicineList = data?.medicine_list?.map(medicine =>
      medicineList.find(currMedicine => currMedicine.id === medicine)
    );
    setMappedMedicineList(mappedMedicineList);
  }, [data]);
  const [mappedMedicineList, setMappedMedicineList] = useState([]);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      name: (data && data?.disease_name) || '',
      animalType: (data && data?.animal_type_id) || '',
      addedSymptoms: [],
      medicines: mappedMedicineList
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      animalType: Yup.string().required('Cannot be empty'),
      addedSymptoms: Yup.array(),
      medicines: Yup.array()
    }),
    onSubmit: formValues => {
      const { addedSymptoms, ...otherFormValues } = formValues;

      const symptoms = [
        ...addedSymptoms.map(addedSymptom => ({
          symptomId: addedSymptom.symptom.id,
          locationId: addedSymptom.location
        })),
        ...staticSymptomAndLocationList
      ];

      const formattedValues = {
        ...otherFormValues,
        symptoms
      };

      dispatch(editDisease(id, formattedValues));
    },
    enableReinitialize: true
  });

  // symptomLocation
  const { data: symptomLocationList } = useSelector(state => state.symptomLocation.list);
  const [filteredSymptomLocationList, setFilteredSymptomLocationList] = useState([]);
  const filterSymptomLocationList = animalType => {
    setFilteredSymptomLocationList(symptomLocationList.filter(location => location.animal_type_id === animalType));
  };
  // symptomLocation - END

  // SymptomAndLocationSelectDisabled
  const [staticSymptomAndLocationList, setStaticSymptomAndLocationList] = useState([]);
  const deleteStaticSymptomValue = index => {
    const filteredList = staticSymptomAndLocationList.filter((_, currIndex) => index !== currIndex);
    setStaticSymptomAndLocationList(filteredList);
  };
  useEffect(() => {
    // const symptomAndLocationList = data?.symptom_location_obj[values.animalType] || [];
    const symptomAndLocationList = data?.symptom_location_list;
    setStaticSymptomAndLocationList(symptomAndLocationList);
  }, [values.animalType]);
  // SymptomAndLocationSelectDisabled - END

  // SymptomAndLocationSelect
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const addSymptomValue = (index, selectedSymptom) => {
    const newSelectedSymptoms = [...selectedSymptoms];
    newSelectedSymptoms[index] = selectedSymptom;
    setSelectedSymptoms(newSelectedSymptoms);
  };
  const deleteSymptomValue = index => {
    const newSelectedSymptoms = selectedSymptoms.filter((_, currIndex) => index !== currIndex);
    setSelectedSymptoms(newSelectedSymptoms);
  };
  const openDialog = () => setIsOpen(true);

  const symptomSelectProps = {
    symptomList,
    symptomLocationList: filteredSymptomLocationList,
    addSymptomValue,
    deleteSymptomValue,
    openDialog
  };

  useEffect(() => {
    setFieldValue('addedSymptoms', selectedSymptoms);
  }, [selectedSymptoms]);

  useEffect(() => {
    filterSymptomLocationList(values.animalType);
  }, [values.animalType]);
  // SymptomAndLocationSelect - END

  // SymptomAddDialog
  const { completed: symptomCompleted } = useSelector(state => state.symptom.ops);
  useEffect(() => {
    symptomCompleted && dispatch(fetchSymptomList());
  }, [symptomCompleted]);

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  // SymptomAddDialog - END

  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    resetForm();
    setStaticSymptomAndLocationList(data?.symptom_location_list);
    setSelectedSymptoms([]);
    setIsEditing(false);
  };

  if (isFetching || isLoading) return <LoadingCard />;

  return (
    <>
      <Card>
        <CardHeader title="Disease details">
          <HeaderToolbar isEditing={isEditing} handleEdit={() => setIsEditing(true)} handleCancel={handleCancel} />
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Disease Name *"
                  helperText={touched.name ? errors.name : ''}
                  error={touched.name && errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  id="animalType"
                  select
                  value={values.animalType}
                  onChange={event => {
                    setFieldValue('animalType', event.target.value);
                    filterSymptomLocationList(event.target.value);
                  }}
                  label="Animal Type *"
                  helperText={touched.animalType ? errors.animalType : ''}
                  error={touched.animalType && errors.animalType ? true : false}
                >
                  {animalTypeList.map(type => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.animal_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {staticSymptomAndLocationList?.map((symptom, index) => (
                  <SymptomAndLocationSelectDisabled
                    disabled={!isEditing}
                    key={index}
                    index={index}
                    value={symptom}
                    deleteValue={deleteStaticSymptomValue}
                  />
                ))}
                {isEditing && (
                  <>
                    {selectedSymptoms.map((symptom, index) => (
                      <SymptomAndLocationSelect
                        key={`${symptom.symptom.id}${index}`}
                        index={index}
                        value={symptom}
                        {...symptomSelectProps}
                      />
                    ))}
                    <SymptomAndLocationSelect
                      key={selectedSymptoms.length || 0}
                      index={selectedSymptoms.length || 0}
                      helperText={touched.symptoms ? errors.symptoms : ''}
                      error={touched.symptoms && errors.symptoms ? true : false}
                      {...symptomSelectProps}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={!isEditing}
                  multiple
                  id="medicines"
                  value={values.medicines || []}
                  onChange={(event, value) => setFieldValue('medicines', value)}
                  options={medicineList}
                  getOptionLabel={option => option?.medicine_name}
                  getOptionSelected={(option, value) => option?.id === value?.id}
                  filterSelectedOptions
                  size="small"
                  noOptionsText={
                    <Grid>
                      No medicines found
                      <Button
                        onMouseDown={event => event.preventDefault()}
                        onClick={() => {}}
                        color="primary"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        Create Medicine
                      </Button>
                    </Grid>
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      helperText={touched.medicines ? errors.medicines : ''}
                      error={touched.medicines && errors.medicines ? true : false}
                      label="Medicines"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <ActionBar
                isEditing={isEditing}
                isFetching={isLoadingEdit}
                handleSubmit={handleSubmit}
                handleDelete={() => dispatch(deleteDisease(id))}
              />
            </Grid>
          </form>
        </CardBody>
      </Card>
      <SymptomAddDialog open={isOpen} handleClose={handleClose} />
    </>
  );
}
