import api from '../../api/userAPI';
import { setErrorNotification } from '../snackbarNotification';
import APIRequest from "../../utils/APIRequest";

export const FETCH_BEGIN = 'appointment.detail/FETCH_BEGIN';
export const FETCH_SUCCESS = 'appointment.detail/FETCH_SUCCESS';
export const FETCH_FAILED = 'appointment.detail/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchAppointment = id => async dispatch => {
  dispatch(fetchBegin());

  try {
    // const res = await api.get(`retrieve-appointment?id=${id}`);

    // const res = await api.get(`retrieve-booked-appointment-schedule?id=${id}`);
    // dispatch(fetchSuccess(res.data.result[0]));

    const res = await APIRequest.set(`v1/appointment-user/admin/${id}`);

    console.log("ADETAILz", res)

    res.customer_id = res.owner.id
    res.customer_name = res.owner.user_name
    res.customer_mobile = res.owner.mobile

    res.doctor_id = res.doctor.id
    res.doctor_name = res.doctor.doctor_name

    res.co_doctor_id = null
    res.co_doctor_name = null

    res.patient_id = res.patients[0].id
    res.patient_name = res.patients[0].patient_name

    res.booking_type_name = res.bookingType.booking_type_name
    res.duration = res.bookingType.duration


    let res2 = {
      "id":13490,
      "start_time":"2021-12-28 09:00:00",
      "end_time":"2021-12-28 09:30:00",
      //"proof_of_payment":"payment_attachment-1640302061103.jpg",
      "description":"Santy Supardi Praktisi: Milo\n\n1. Milo senang tinggal bersama keluarga. Dia tahu bhw dia disayang oleh seluruh anggota keluarga. Milo minta maaf karena waktu kejadian \"pingsan\" itu, dia sdh bikin cemas semuanya.\n\n2. Seperti ada kelainan jantung bawaan yg membuat Milo tiba2 \"pingsan\". Saat skrg Milo merasa sehat2 saja, tidak ada keluhan apa2.\n\n3. Milo mau tetap disayang walaupun sdh bikin repot semuanya. Milo bilang, dia bisa jaga diri, biar gak terlalu over excited.\n\n\nDrh Chika Praktisi MP: MILO\n1.\tMilo senang hidup bersama keluarga owner, ini adalah keluarga Milo.\n2.\tMilo tidak ingat kejadiannya waktu peristiwa dia pingsan. Sekarang Milo merasa baik2 saja. Tapi sepertinya Milo nafasnya suka sesak kalau kelelahan walaupun ga dia rasakan,\n3.\tMilo ingin tetap disayang dan dimanja seperti sekarang aja sudah cukup, ga berharap macam2. Tapi juga jangan banyak dilarang-larang.",
      "additional_storage":"1. Apakah Milo senang hidup bersama keluarga kami? Apa yg Milo rasakan?\n2. Milo pernah rontgen dan ternyata jantungnya bengkak dan sempat pingsan, detak jantung berhenti. Bersyukur Milo msh bisa diselamatkan. Kita pengen tau apa yg Milo rasakan sekarang? Apakah ada rasa sakit atau tidak enak?\n3. Apa yang Milo inginkan dan harapkan dari kami?",
      //"status":"DONE",
      "doctor_id":10,
      "doctor_name":"Team RTwA",
      "co_doctor_id":null,
      "co_doctor_name":null,
      "patient_id":7896,
      "patient_name":"MILO",
      //"birthdate":"2021-01-23T17:00:00.000Z",
      //"breed":"POODLE",
      //"age":1,
      //"age_month":15,
      //"age_string":"1 year(s) and 3 month(s) old",
      "customer_id":1161,
      "customer_name":"Mellisa",
      "customer_mobile":"+6285386130950",
      "booking_type_name":"(ONLINE) RAJANTI TALKS WITH ANIMALS WITH TEAM RTWA",
      "duration":3,
      //"medical_record_description":"Santy Supardi Praktisi: Milo\n\n1. Milo senang tinggal bersama keluarga. Dia tahu bhw dia disayang oleh seluruh anggota keluarga. Milo minta maaf karena waktu kejadian \"pingsan\" itu, dia sdh bikin cemas semuanya.\n\n2. Seperti ada kelainan jantung bawaan yg membuat Milo tiba2 \"pingsan\". Saat skrg Milo merasa sehat2 saja, tidak ada keluhan apa2.\n\n3. Milo mau tetap disayang walaupun sdh bikin repot semuanya. Milo bilang, dia bisa jaga diri, biar gak terlalu over excited.\n\n\nDrh Chika Praktisi MP: MILO\n1.\tMilo senang hidup bersama keluarga owner, ini adalah keluarga Milo.\n2.\tMilo tidak ingat kejadiannya waktu peristiwa dia pingsan. Sekarang Milo merasa baik2 saja. Tapi sepertinya Milo nafasnya suka sesak kalau kelelahan walaupun ga dia rasakan,\n3.\tMilo ingin tetap disayang dan dimanja seperti sekarang aja sudah cukup, ga berharap macam2. Tapi juga jangan banyak dilarang-larang.",
      //"medication":"",
      //"date_created":"2021-12-28T08:42:50.000Z"
    }

    let formattedRes = {
      ...res2,
      id : res.id,
      start_time : res.start_time,
      end_time : res.end_time,
      created_at : res.created_at,
      expire_at : res.expire_at,
      clinic : res.clinic,
      operation_table : res.operation_table,
      owner: res.owner,
      description: res.description,
      "additional_storage": res.description,
      doctor_id : res.doctor.id,
      doctor_name : res.doctor.doctor_name,
      co_doctor_id : null,
      co_doctor_name : null,
      patient_id : res.patients[0].id,
      patient_name : res.patients[0].patient_name,
      patients: res.patients,
      status: res.status,
      customer_id : res.owner.id,
      customer_name : res.owner.user_name,
      customer_mobile : res.owner.mobile,
      customer_email : res.owner.email,
      booking_type_name : res.bookingType.booking_type_name,
      duration : res.bookingType.duration,
      payment_status : res.payment_status

      // "booking_type_name":"(ONLINE) RAJANTI TALKS WITH ANIMALS WITH TEAM RTWA",
      // "duration":3,
      // "doctor_id":10,
      // "doctor_name":"Team RTwA",
    }

    dispatch(fetchSuccess(formattedRes));

    console.log("RBAS", formattedRes, res)


  } catch (error) {
    console.log("RBASE", error)
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: null, isLoading: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isLoading: false };
    case FETCH_FAILED:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: null,
  isLoading: false,
  error: null
};
