import React from 'react';
import ImageMap from 'image-map';

const CatAnatomyMap = ({
  show,
  handleHeadClick,
  handleChestClick,
  handleBodyClick,
  handleFrontLegClick,
  handleBellyClick,
  handleHindLegClick,
  handleHipsClick,
  handleTailClick
}) => {
  const handleDefaultClick = () => {
    alert('woi');
  };

  ImageMap('img[usemap]');
  return (
    <>
      <img
        src="/media/diagnosis/cat_anatomy_new.jpg"
        alt="cat_anatomy"
        useMap="#image-map"
        style={{ maxWidth: '100%', display: show ? 'block' : 'none' }}
      />
      <map name="image-map">
        <area
          onClick={handleHeadClick}
          alt="head"
          title="head"
          coords="426,244,456,280,497,302,560,298,578,239,517,155,483,161"
          shape="poly"
        />
        <area
          onClick={handleChestClick}
          alt="chest"
          title="chest"
          coords="457,283,495,303,449,366,376,374"
          shape="poly"
        />
        <area
          onClick={handleBodyClick}
          alt="body"
          title="body"
          coords="379,377,458,284,430,244,215,238,197,311,229,319,247,345,288,343,341,354"
          shape="poly"
        />
        <area
          onClick={handleFrontLegClick}
          alt="front-leg"
          title="front-leg"
          coords="449,370,426,405,413,447,411,479,438,489,428,512,392,501,379,468,376,379"
          shape="poly"
        />
        <area
          onClick={handleBellyClick}
          alt="belly"
          title="belly"
          coords="237,385,298,385,376,378,354,359,313,346,271,344,247,347"
          shape="poly"
        />
        <area
          onClick={handleHindLegClick}
          alt="hind-leg"
          title="hind-leg"
          coords="147,347,112,324,97,362,78,390,45,417,42,461,51,503,87,505,87,491,73,481,76,437,159,389,153,452,206,514,243,510,242,489,219,487,191,449,237,386,248,346,221,313,195,311,159,323"
          shape="poly"
        />
        <area
          onClick={handleHipsClick}
          alt="hips"
          title="hips"
          coords="197,314,219,235,166,237,131,253,111,276,111,291,112,322,147,349,157,329,182,312"
          shape="poly"
        />
        <area
          onClick={handleTailClick}
          alt="tail"
          title="tail"
          coords="130,257,104,224,82,175,79,131,72,90,53,66,46,47,54,38,77,45,95,70,104,116,111,168,129,197,167,242"
          shape="poly"
        />
      </map>
    </>
  );
};

export default CatAnatomyMap;
