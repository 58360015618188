import { combineReducers } from 'redux';

import deleteDoctor from './delete';
import list from './list';
import listByAppointmentType from './listByAppointmentType';
import detail from './detail';
import ops from './ops';
import upsertAppointmentType from './upsertAppointmentType';

export default combineReducers({
  delete: deleteDoctor,
  list,
  listByAppointmentType,
  detail,
  ops,
  upsertAppointmentType
});
