import React from 'react';
import { Grid } from '@material-ui/core';

const NoDataIndicator = () => {
  return (
    <Grid container alignItems='center' justify='center' spacing={1} style={{ padding: '1rem' }}>
      <Grid item>No data</Grid>
    </Grid>
  )
}

export default NoDataIndicator;

export const indicatorFactory = () => <NoDataIndicator />