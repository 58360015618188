import { combineReducers } from 'redux';

import add from './add';
import deleteReducer from './delete';
import detail from './detail';
import edit from './edit';
import list from './list';

export default combineReducers({
  add,
  delete: deleteReducer,
  detail,
  edit,
  list
});

export * from './add';
export * from './delete';
export * from './edit';
