import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchSymptomList } from '../../../redux/symptom/list';

const SymptomListPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  // Initial fetch
  useEffect(() => {
    dispatch(fetchSymptomList());
  }, []);

  const { data } = useSelector(state => state.symptom.list);
  const isFetching = useSelector(state => state.loading.symptom);

  const columns = [
    {
      dataField: 'symptom_name',
      text: 'Symptom'
    }
  ];

  return (
    <Card>
      <CardHeader title="Symptom list">
        <ListHeaderToolbar addLink="/symptom/add" addText="New Symptom" />
      </CardHeader>
      <CardBody>
        <DataTable
          data={data}
          loading={isFetching}
          totalDataCount={data.length}
          columns={columns}
          editLink="/symptom/"
        />
      </CardBody>
    </Card>
  );
};

export default SymptomListPage;
