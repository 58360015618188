import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'doctor.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'doctor.ops/ADD_SUCCESS';
export const ADD_FAILED = 'doctor.ops/ADD_FAILED';
export const EDIT_BEGIN = 'doctor.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'doctor.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'doctor.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'doctor.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'doctor.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'doctor.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addDoctor = data => async dispatch => {
  dispatch(addBegin());

  const formattedData = {
    user_name: data.name,
    password: data.password,
    email: data.email,
    mobile: data.phone,
    birthdate: data.dateOfBirth,
    address: '-'
  };

  try {
    const res = await api.post('register-doctor', formattedData);

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));

    if (error?.response.data.error === 'DUPLICATE-ENTRY') {
      dispatch(setErrorNotification('Doktor/praktisi already exists'));
    } else {
      dispatch(setErrorNotification(error.toString()));
    }
  }
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editDoctor = (id, data) => async dispatch => {
  dispatch(editBegin());

  const formattedData = {
    id,
    user_name: data.name,
    email: data.email,
    mobile: data.phone,
    birthdate: data.dateOfBirth
  };

  try {
    await api.post('update-doctor', formattedData);

    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully edited data'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case EDIT_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, error: null };

    case ADD_SUCCESS:
    case EDIT_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true };

    case ADD_FAILED:
    case EDIT_FAILED:
    case DELETE_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
};

const initialState = {
  completed: false,
  error: null
};
