import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { fetchCustomerList } from '../../../redux/customer/list';
import { fetchAnimalTypeList } from '../../../redux/animalType/list';
import { addPatient } from '../../../redux/patient/ops';

const PatientAddPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerList());
    dispatch(fetchAnimalTypeList());
  }, []);

  const { data: ownerList } = useSelector(state => state.customer.list);
  const { data: animalTypeList } = useSelector(state => state.animalType.list);

  const { completed } = useSelector(state => state.patient.ops);
  const isFetching = useSelector(state => state.loading.patient);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      animalType: '',
      breed: '',
      owner: '',
      age: 0,
      ageMonth: 0,
      gender: 'MALE',
      patientImage: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      animalType: Yup.string().required('Cannot be empty'),
      breed: Yup.string().required('Cannot be empty'),
      owner: Yup.string().required('Cannot be empty'),
      age: Yup.number()
        .min(0, 'Must be greater than 0')
        .required('Cannot be empty'),
      ageMonth: Yup.number()
        .min(0, 'Must be greater than 0')
        .required('Cannot be empty'),
      gender: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      const formattedValues = {
        ...values,
        owner: values.owner.id,
        age: `${values.age}.${values.ageMonth}`
      };

      dispatch(addPatient(formattedValues));
    }
  });

  const [ownerInput, setOwnerInput] = useState('');
  const [genderInput, setGenderInput] = useState('');

  return (
    <Card>
      <CardHeader title="Patient details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.owner}
                onChange={(event, newValue) => {
                  setFieldValue('owner', newValue);
                }}
                options={ownerList}
                getOptionLabel={option =>
                  option ? `${option.user_name} (${option.mobile})` : ''
                }
                inputValue={ownerInput}
                onInputChange={(event, newInputValue) => {
                  setOwnerInput(newInputValue);
                }}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText={
                      touched.owner && errors.owner ? errors.owner : ''
                    }
                    error={touched.owner && errors.owner ? true : false}
                    label="Owner *"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="animalType"
                select
                value={values.animalType}
                onChange={event => {
                  setFieldValue('animalType', event.target.value);
                }}
                label="AnimalType *"
                helperText={touched.animalType ? errors.animalType : ''}
                error={touched.animalType && errors.animalType ? true : false}
              >
                {animalTypeList.map(({ id, animal_name }) => (
                  <MenuItem key={id} value={id}>
                    {animal_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="breed"
                value={values.breed}
                onChange={handleChange}
                label="Breed *"
                helperText={touched.breed && errors.breed ? errors.breed : ''}
                error={touched.breed && errors.breed ? true : false}
              />
            </Grid>
            <Grid item xs={3}>
              {/* <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth"
                helperText={
                  touched.dateOfBirth && errors.dateOfBirth
                    ? 'Invalid date'
                    : ''
                }
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
              /> */}
              <TextField
                id="age"
                value={values.age}
                onChange={handleChange}
                label="Age (years old) *"
                helperText={touched.age && errors.age ? errors.age : ''}
                error={touched.age && errors.age ? true : false}
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                id="ageMonth"
                value={values.ageMonth}
                onChange={event => {
                  setFieldValue('ageMonth', event.target.value);
                }}
                label="Age (months old) *"
                helperText={
                  touched.ageMonth && errors.ageMonth ? errors.ageMonth : ''
                }
                error={touched.ageMonth && errors.ageMonth ? true : false}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                id="gender"
                value={values.gender}
                label="Gender *"
                helperText={
                  touched.gender && errors.gender ? errors.gender : ''
                }
                onChange={(event, newValue) => {
                  console.log(newValue.props.value)
                  setFieldValue('gender', newValue.props.value);
                }}
                error={touched.gender && errors.gender ? true : false}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <input
                id="patientImage"
                onChange={event => {
                  setFieldValue('patientImage', event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="patientImage">
                <Button color="primary" variant="outlined" component="span">
                  Upload Patient Image
                </Button>
                <span style={{ marginLeft: '1rem' }}>
                  {values.patientImage?.name || ''}
                </span>
              </label>
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default PatientAddPage;
