import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import SymptomAndLocationSelect from './components/SymptomAndLocationSelect';
import SymptomAddDialog from './components/SymptomAddDialog';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { fetchAnimalTypeList } from '../../../redux/animalType/list';
import { fetchSymptomList } from '../../../redux/symptom/list';
import { fetchSymptomLocationList } from '../../../redux/symptomLocation/list';
import { fetchMedicineList } from '../../../redux/medicine/list';
import { addDisease } from '../../../redux/disease/ops';

export default function DiseaseAddPage() {
  // Prereq Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnimalTypeList());
    dispatch(fetchSymptomList());
    dispatch(fetchSymptomLocationList());
    dispatch(fetchMedicineList());
  }, []);

  const { completed } = useSelector(state => state.disease.ops);
  const isFetching = useSelector(state => state.loading.disease);
  useEffect(() => {
    if (completed) {
      resetForm();
      setSelectedSymptoms([]);
    }
  }, [completed]);

  const { data: animalTypeList } = useSelector(state => state.animalType.list);
  const { data: symptomList } = useSelector(state => state.symptom.list);
  const { data: symptomLocationList } = useSelector(
    state => state.symptomLocation.list
  );
  const { data: medicineList } = useSelector(state => state.medicine.list);

  // SymptomAndLocationSelect
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const addSymptomValue = (index, selectedSymptom) => {
    const newSelectedSymptoms = [...selectedSymptoms];
    newSelectedSymptoms[index] = selectedSymptom;
    setSelectedSymptoms(newSelectedSymptoms);
  };
  const deleteSymptomValue = index => {
    const newSelectedSymptoms = selectedSymptoms.filter(
      (selectedSymptom, currIndex) => index !== currIndex
    );
    setSelectedSymptoms(newSelectedSymptoms);
  };
  const openDialog = () => setIsOpen(true);

  const [
    filteredSymptomLocationList,
    setFilteredSymptomLocationList
  ] = useState([]);
  const filterSymptomLocationList = animalType => {
    setFilteredSymptomLocationList(
      symptomLocationList.filter(
        location => location.animal_type_id === animalType
      )
    );
  };

  const symptomSelectProps = {
    symptomList,
    symptomLocationList: filteredSymptomLocationList,
    addSymptomValue,
    deleteSymptomValue,
    openDialog
  };

  useEffect(() => {
    setFieldValue('symptoms', selectedSymptoms);
  }, [selectedSymptoms]);
  // SymptomAndLocationSelect - END

  // SymptomAddDialog
  const { completed: symptomCompleted } = useSelector(
    state => state.symptom.ops
  );
  useEffect(() => {
    symptomCompleted && dispatch(fetchSymptomList());
  }, [symptomCompleted]);

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  // SymptomAddDialog - END

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      animalType: '',
      symptoms: [],
      medicines: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      animalType: Yup.string().required('Cannot be empty'),
      symptoms: Yup.array().required('Cannot be empty'),
      medicines: Yup.array()
    }),
    onSubmit: values => {
      const formattedSymptoms = values.symptoms
        // Filters duplicate
        .filter(
          ({ symptom, location }, index, self) =>
            index ===
            self.findIndex(
              ({ symptom: s, location: l }) =>
                s.id === symptom.id && l === location
            )
        )
        .map(({ symptom, location }) => ({
          symptomId: symptom.id,
          locationId: location
        }));

      const formattedValues = {
        ...values,
        symptoms: formattedSymptoms
      };

      dispatch(addDisease(formattedValues));
    }
  });

  const handleReset = () => {
    resetForm();
    setSelectedSymptoms([]);
  };

  return (
    <>
      <Card>
        <CardHeader title="Disease details"></CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Disease Name *"
                  helperText={touched.name ? errors.name : ''}
                  error={touched.name && errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="animalType"
                  select
                  value={values.animalType}
                  onChange={event => {
                    setFieldValue('animalType', event.target.value);
                    filterSymptomLocationList(event.target.value);
                  }}
                  label="Animal Type *"
                  helperText={touched.animalType ? errors.animalType : ''}
                  error={touched.animalType && errors.animalType ? true : false}
                >
                  {animalTypeList.map(type => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.animal_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {selectedSymptoms.map((symptom, index) => (
                  <SymptomAndLocationSelect
                    key={`${symptom.symptom.id}${index}`}
                    index={index}
                    value={symptom}
                    {...symptomSelectProps}
                  />
                ))}
                <SymptomAndLocationSelect
                  key={selectedSymptoms.length || 0}
                  index={selectedSymptoms.length || 0}
                  helperText={touched.symptoms ? errors.symptoms : ''}
                  error={touched.symptoms && errors.symptoms ? true : false}
                  {...symptomSelectProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="medicines"
                  value={values.medicines}
                  onChange={(event, value) => setFieldValue('medicines', value)}
                  options={medicineList}
                  getOptionLabel={option => option.medicine_name}
                  filterSelectedOptions
                  size="small"
                  noOptionsText={
                    <Grid>
                      No medicines found
                      <Button
                        onMouseDown={event => event.preventDefault()}
                        onClick={() => {}}
                        color="primary"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        Create Medicine
                      </Button>
                    </Grid>
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      helperText={touched.medicines ? errors.medicines : ''}
                      error={
                        touched.medicines && errors.medicines ? true : false
                      }
                      label="Medicines"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <img
                  id="1"
                  usemap="#image-map"
                  src="/media/diagnosis/catanatomy.png"
                  alt="catanatomy"
                  style={{ maxWidth: '100%' }}
                />
                <map name="image-map">
                  <area
                    alt="Head"
                    title="Head"
                    onClick={handleMapClick}
                    coords="788,252,86"
                    shape="circle"
                  />
                  <area
                    alt="Body"
                    title="Body"
                    onClick={handleMapClick}
                    coords="596,301,100"
                    shape="circle"
                  />
                  <area
                    alt="Leg"
                    title="Leg"
                    onClick={handleMapClick}
                    coords="747,451,96"
                    shape="circle"
                  />
                  <area
                    alt="Leg"
                    title="Leg"
                    onClick={handleMapClick}
                    coords="439,444,100"
                    shape="circle"
                  />
                </map>
              </Grid> */}
              <ActionBar
                isFetching={isFetching}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
              />
            </Grid>
          </form>
        </CardBody>
      </Card>
      <SymptomAddDialog open={isOpen} handleClose={handleClose} />
    </>
  );
}
