import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PasswordTextField, TextField } from 'app/components/common/TextField';
import ActionBar from 'app/components/common/ActionBar/ActionBar';
import Dialog from 'app/components/common/Dialog';

import { resetPassword } from 'redux/resetPassword';
import { fetchUserList } from 'redux/user/list';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.user.list);
  const { completed, isLoading: isResetPasswordLoading } = useSelector(
    state => state.resetPassword
  );

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState();

  useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  useEffect(() => {
    if (completed) {
      resetForm();
      setIsConfirmationDialogOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validationSchema: Yup.object({
      user: Yup.string().required('Cannot be empty'),
      password: Yup.string().required('Cannot be empty')
    }),
    onSubmit: () => {
      setIsConfirmationDialogOpen(true);
    },
    enableReinitialize: true
  });

  const [userInput, setUserInput] = useState('');

  const handleResetClick = () => {
    const formattedValues = {
      email: values.user.email,
      password: values.password
    };

    dispatch(resetPassword(formattedValues));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              value={values.user}
              onChange={(_, newValue) => {
                setFieldValue('user', newValue);
              }}
              options={data}
              getOptionLabel={option =>
                option ? `${option.user_name} (${option.email})` : ''
              }
              inputValue={userInput}
              onInputChange={(_, newInputValue) => {
                setUserInput(newInputValue);
              }}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={touched.user && errors.user ? errors.user : ''}
                  error={touched.user && errors.user ? true : false}
                  label="User *"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              id="password"
              value={values.password}
              onChange={handleChange}
              label="New Password *"
              helperText={
                touched.password && errors.password ? errors.password : ''
              }
              error={touched.password && errors.password ? true : false}
            />
          </Grid>
          <ActionBar isFetching={isResetPasswordLoading}>
            <Button
              onClick={handleSubmit}
              type="submit"
              color="primary"
              variant="contained"
            >
              Reset Password
            </Button>
          </ActionBar>
        </Grid>
      </form>
      <Dialog
        open={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        title="Reset user's password?"
        content="This action cannot be undone!"
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={handleResetClick}
          >
            Reset Password
          </Button>
        }
      />
    </>
  );
};

export default ResetPasswordForm;
