import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import { TextField } from 'app/components/common/TextField';
import { ActionBarAdd as ActionBar } from 'app/components/common/ActionBar';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import TimePicker from 'app/components/common/TimePicker';

import { fetchAppointmentTypesByDoctor } from 'redux/appointmentType/listByDoctor';
import { fetchDoctorListByAppointmentType } from 'redux/doctor/listByAppointmentType';
import {
  addAppointmentSlot,
  deleteAppointmentSlot
} from 'redux/appointmentSlot/ops';

import { makeStyles } from '@material-ui/core/styles';
import { parseISO } from 'date-fns/esm';
import AppointmentModel from "../../../../model/AppointmentModel";
import {Autocomplete} from "@material-ui/lab";

import OperationTable from 'app/models/OperationTable';
import Clinic from 'app/models/Clinic';

import swal from "sweetalert2";
import moment from "moment"

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '800px',
    maxWidth: '800px'
  },
  title: {
    marginBottom: '2rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  datePicker: {
    marginBottom: '1rem'
  },
  actionContainer: {
    justifyContent: 'flex-start',
    marginTop: '2rem',
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const appointmentCategory = [
  {
    name : "Poliklinik",
    id : 1
  },
  {
    name : "Grooming",
    id : 2
  },
  {
    name : "Operasi",
    id : 3
  },
  {
    name : "RTWA",
    id : 4
  },
  {
    name : "All",
    id : 7
  },
]

const AddAppointmentSlotDialogV2 = ({ isOpen, handleClose, doctorID, data }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  let [clinics, setClinics] = useState([])
  let [operationTables, setOperationTables] = useState([])

  const { completed } = useSelector(state => state.appointmentSlot.ops);

  
  const fetchClinics = async () => {
    let clinicModel = new Clinic()

    try{
      let result = await clinicModel.getAllClinic()
      setClinics(result)

    }catch(e){
      console.log('error: ', e)
    }

  }

  const fetchOperationTables = async () => {
    let operationTableModel = new OperationTable()

    try{
      let result = await operationTableModel.getAllOperation()
      setOperationTables(result)

    }catch(e){
      console.log('error: ', e)
    }
  }

  useEffect(() => {
    fetchClinics()
    fetchOperationTables()
  }, []);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleReset,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      doctor: doctorID || '',
      category_id: '',
      clinic_type: null,
      coDoctor: null,
      date: null,
      time_start: null,
      time_end: null
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required('Cannot be empty'),
      date: Yup.date().required('Cannot be empty'),
      clinic_type: Yup.string().required('Cannot be empty'),
      time_start: Yup.date().required('Cannot be empty'),
      time_end: Yup.date().required('Cannot be empty'),
    }),
    onSubmit: async values => {

      const formattedStart = parseISO(
          `${format(values.date, 'yyyy-MM-dd')} ${format(
              values.time_start,
              'HH:mm'
          )}:00`,
          'yyyy-MM-dd HH:mm:ss'
      );
      const formattedEnd = parseISO(
          `${format(values.date, 'yyyy-MM-dd')} ${format(
              values.time_end,
              'HH:mm'
          )}:00`,
          'yyyy-MM-dd HH:mm:ss'
      );

      const formattedNewStart = moment(formattedStart)
      const formattedNewEnd = moment(formattedEnd)

      console.log("request payload", {
        category_id: values.category_id,
        coDoctor: values.coDoctor,
        startDate: formattedNewStart.toDate(),
        endDate: formattedNewEnd.toDate(),
        clinic_id: values.clinic_type,
        doctor_id: parseInt(doctorID)
      })

      try{
        let result = await AppointmentModel.addAppointmentSlot(
                            values.category_id,
                            parseInt(doctorID),
                            values.coDoctor,
                            formattedNewStart.toDate(),
                            formattedNewEnd.toDate(),
                            values.clinic_type,
                          )

        console.log(result)
        let confirmResult = await swal.fire({
          title: "Success",
          text: "Jadwal berhasil dibuat",
          icon: 'success',
          confirmButtonText: "Go back",
          customClass: {
            container: 'swal-z'
          }
        });
        handleClose(true)
      }catch (e) {
        console.log(e)
        let confirmResult = await swal.fire({
          title: "Error",
          text: e.error_message ? e.error_message : "Kesalahan Terjadi",
          icon: 'error',
          confirmButtonText: "Kembali",
          customClass: {
            container: 'swal-z'
          }
        });
      }

    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (data) {
      console.log(data)
      setFieldValue('category_id', data?.booking_type_name || '');
      setFieldValue('coDoctor', data?.co_doctor_name || '');
      setFieldValue('date', parseISO(data?.start_time) || '');
      setFieldValue('time_start', parseISO(data?.start_time) || '');
      setFieldValue('time_end', parseISO(data?.start_time) || '');
    }
  }, [data]);

  useEffect(() => {
    if (completed) {
      resetForm();
      handleClose();
    }
  }, [completed]);

  /// [JOHN COBA TAPI GAGAL: appointmentType dalam values return '' melulu padahal udah di call setFieldValue onchange appointment type]
  // useEffect(()=>{
  //   console.log(values)
  //   if (values.appointmentType){
  //     const selectedAppointmentType = appointmentTypes.find(
  //         appointmentType =>
  //             appointmentType.booking_type_name === values.appointmentType
  //     );
  //
  //     dispatch(
  //         fetchDoctorListByAppointmentType(values.appointmentType)
  //     )
  //   }
  // }, [values.coDoctor]);
  /// [JOHN AKHIRNYA BIKIN INI]
  const getDoctorsListForAppointment = (appointmentType)=>{
    dispatch(
        fetchDoctorListByAppointmentType(appointmentType)
    )
  }

  const handleDeleteClick = () => {
    dispatch(deleteAppointmentSlot(data.id));
  };

  const handleCreateClick = () => {
    history.push('/appointment/add', {
      ...data
    });
  };

  const renderTimeInput = props => {
    return (
      <TextField
        disabled={props.disabled}
        value={`${props.value}`}
        onChange={props.onChange}
        onClick={props.onClick}
        label={props.label}
      />
    );
  };

  const shouldNotBeEditable = Boolean(data);
  const [coDoctorInput, setCoDoctorInput] = useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ container: classes.container, paper: classes.paper }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle className={classes.title}>
          Appointment Slot Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={Boolean(data)}
                id="category_id"
                select
                value={values.category_id}
                onChange={event => {
                  setFieldValue('category_id', event.target.value);
                }}
                label="Appointment Type *"
                helperText={
                  touched.category_id ? errors.category_id : ''
                }
                error={
                  touched.category_id && errors.category_id
                    ? true
                    : false
                }
              >
                {appointmentCategory.map((obj,key) => (
                  <MenuItem
                    key={obj.id}
                    value={obj.id}
                  >
                    {`${
                      obj.name
                    }`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
            <TextField
                id="clinic_type"
                select
                value={values.clinic_type}
                onChange={event => {
                  setFieldValue('clinic_type', event.target.value);
                }}
                label="Clinic Type *"
              >
                {clinics.map((obj,key) => (
                  <MenuItem
                    key={obj.id}
                    value={obj.id}
                  >
                    {`${
                      obj.name
                    }`}
                  </MenuItem>
                ))}
                </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  disabled={shouldNotBeEditable}
                  value={values.coDoctor === '' || values.coDoctor === null ? 'N/A' : values.coDoctor}
                  onChange={(_, newValue) => {
                    setFieldValue('coDoctor', _.target.value);
                  }}
                  getOptionLabel={option => option?.doctor_name || ''}
                  inputValue={coDoctorInput}
                  onInputChange={(_, newInputValue) => {
                    setCoDoctorInput(newInputValue);
                  }}
                  size="small"
                  label="Co-Doctor / Partner Praktisi"
                  renderInput={params => (
                      <TextField
                          {...params}
                          helperText={
                            touched.doctor && errors.doctor ? errors.doctor : ''
                          }
                          error={touched.doctor && errors.doctor ? true : false}
                          label="Co-Doctor / Partner Praktisi"
                          variant="outlined"
                      />
                  )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <KeyboardDatePicker
                disabled={Boolean(data)}
                value={values.date}
                onChange={date => setFieldValue('date', date, true)}
                label="Date *"
                helperText={touched.date && errors.date ? 'Invalid date' : ''}
                error={touched.date && errors.date ? true : false}
                clearable
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                disabled={Boolean(data)}
                value={values.time_start}
                onChange={date => setFieldValue('time_start', date, true)}
                clearable
                label="Time Start *"
                TextFieldComponent={renderTimeInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                  disabled={Boolean(data)}
                  value={values.time_end}
                  onChange={date => setFieldValue('time_end', date, true)}
                  clearable
                  label="Time End *"
                  TextFieldComponent={renderTimeInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          {data ? (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Create Appointment
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleDeleteClick}
              >
                Cancel Slot
              </Button>
            </>
          ) : (
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Add
            </Button>
          )}

          {/* <ActionBar
            isFetching={false}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
          /> */}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAppointmentSlotDialogV2;
