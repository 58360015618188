import { useEffect, useState } from 'react';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { TextField } from 'app/components/common/TextField';
import TextFormatter from "../../../../utils/TextFormatter";

const PatientForm = ({ formik, isEditing, animalTypeList, ownerList }) => {
  const { role } = useSelector(state => state.auth);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = formik;

  const [ownerInput, setOwnerInput] = useState('');

  useEffect(() => {
    const selectedOwner = ownerList.find(
      currOwner => currOwner.id === values.owner
    );
    selectedOwner &&
      setOwnerInput(`${selectedOwner.user_name} (${selectedOwner.mobile})`);
  }, [values.owner, ownerList]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {values?.picture && values?.picture !== 'No Attachment' && (
          <Grid item xs={12}>
            <img
              src={TextFormatter.getImageURL(values?.picture)}
              alt="patient"
              style={{ height: '160px', marginBottom: '1rem' }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            disabled={!isEditing}
            id="name"
            value={values.name}
            onChange={handleChange}
            label="Name *"
            helperText={touched?.name && errors?.name ? errors?.name : ''}
            error={touched?.name && errors?.name ? true : false}
          />
        </Grid>
        {role !== 'DOCTOR' && (
          <Grid item xs={12}>
            <Autocomplete
              disabled={!isEditing}
              value={values.owner}
              onChange={(event, newValue) => {
                setFieldValue('owner', newValue?.id);
              }}
              options={ownerList}
              getOptionLabel={option =>
                option ? `${option.user_name} (${option.mobile})` : ''
              }
              getOptionSelected={(option, val) => {
                return option.id === val;
              }}
              inputValue={ownerInput}
              onInputChange={(event, newInputValue) => {
                event?.type !== 'blur' && setOwnerInput(newInputValue);
              }}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={
                    touched?.owner && errors?.owner ? errors?.owner : ''
                  }
                  error={touched?.owner && errors?.owner ? true : false}
                  label="Owner *"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            disabled={!isEditing}
            id="animalType"
            select
            value={values.animalType}
            onChange={event => {
              setFieldValue('animalType', event.target.value);
            }}
            label="AnimalType *"
            helperText={touched?.animalType ? errors?.animalType : ''}
            error={touched?.animalType && errors?.animalType ? true : false}
          >
            {animalTypeList.map(({ id, animal_name }) => (
              <MenuItem key={id} value={id}>
                {animal_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={!isEditing}
            id="breed"
            value={values.breed}
            onChange={handleChange}
            label="Breed *"
            helperText={touched?.breed && errors?.breed ? errors?.breed : ''}
            error={touched?.breed && errors?.breed ? true : false}
          />
        </Grid>
        {!isEditing ? (
          <Grid item xs={6}>
            <TextField
              disabled={true}
              id="ageString"
              value={values.ageString}
              onChange={handleChange}
              label="Age *"
              helperText={touched?.age && errors?.age ? errors?.age : ''}
              error={touched?.age && errors?.age ? true : false}
              type="text"
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              <TextField
                id="age"
                value={values.age}
                onChange={handleChange}
                label="Age (years old) *"
                helperText={touched?.age && errors?.age ? errors?.age : ''}
                error={touched?.age && errors?.age ? true : false}
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                id="ageMonth"
                value={values.ageMonth}
                onChange={event => {
                  setFieldValue('ageMonth', event.target.value);
                }}
                label="Age (months old) *"
                helperText={
                  touched?.ageMonth && errors?.ageMonth ? errors?.ageMonth : ''
                }
                error={touched?.ageMonth && errors?.ageMonth ? true : false}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
              </TextField>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField
            disabled={!isEditing}
            select
            id="gender"
            value={values.gender}
            onChange={(event, newValue) => {
              setFieldValue('gender', newValue.props.value);
            }}
            label="Gender *"
            helperText={touched?.gender && errors?.gender ? errors?.gender : ''}
            error={touched?.gender && errors?.gender ? true : false}
          >
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </TextField>
        </Grid>
        {isEditing && (
          <Grid item xs={12}>
            <input
              id="patientImage"
              onChange={event => {
                setFieldValue('patientImage', event.currentTarget.files[0]);
              }}
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              type="file"
            />
            <label htmlFor="patientImage">
              <Button color="primary" variant="outlined" component="span">
                Upload Patient Image
              </Button>
              <span style={{ marginLeft: '1rem' }}>
                {values.patientImage?.name || ''}
              </span>
            </label>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PatientForm;
