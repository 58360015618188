import React from 'react';
import { Button, Grid } from '@material-ui/core';

import ActionBar from './ActionBar';

const ActionBarAdd = ({ isFetching, handleSubmit, handleReset }) => {
  return (
    <ActionBar isFetching={isFetching}>
      <Grid item>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={handleReset}>Clear</Button>
      </Grid>
    </ActionBar>
  );
};

export default ActionBarAdd;
