import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addDays, parseISO, startOfWeek } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '_metronic/_partials/controls';
import TimetableV2 from 'app/components/common/TimetableV2';
import AddAppointmentSlotDialogV2 from './components/AddAppointmentSlotDialogV2';
import AddAppointmentDialogV2 from './components/AddAppointmentDialogV2';
import EditAppointmentDialog from './components/EditAppointmentDialog';
import BlockAppointmentDialog from './components/BlockAppointmentDialog';

import AppointmentModel from "../../../model/AppointmentModel";

import moment from "moment"
import Doctor from 'app/models/Doctor';
import { Modal } from 'react-bootstrap';

const DoctorTimetablePage = () => {

  const { id } = useParams();
  const history = useHistory();

  const [date, setDate] = useState(addDays(startOfWeek(new Date()), 1));
  const { role } = useSelector(state => state.auth);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsV2, setAppointmentsV2] = useState([]);
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState();
  const [showOptions, setShowOptions] = useState();
  const [doctor, setDoctor] = useState({})

  const fetchDoctor = async () => {
    let doctorModel = new Doctor()
    try{
      let result = await doctorModel.getDoctorById(id)
      setDoctor(result)
    }catch(e){
      console.log("error: ",e)
    }
  }

  useEffect(()=>{
    console.log("bruh")
    getAppointmentsV2()
    fetchDoctor()
  },[date])

  const getAppointmentsV2 = async() =>{
    try{
      let dateStart = moment(date).toISOString()
      let dateEnd = moment(date).add(1, "week").toISOString()

      if(role === "DOCTOR"){

        let result = await AppointmentModel.getDoctorAppointmentByWeekForDoctor(dateStart, dateEnd)

        console.log("ROSU", result, new Date())

        let date = new Date()
        date.setHours(0)
        date.setMinutes(0)

        setAppointmentsV2(result.map((obj,key)=>{
          return {
            ...obj,
            ID: obj.id,
            startDate: toJakartaDate(parseISO(obj.start_time)),
            slotStartTime : toJakartaDate(parseISO(obj.start_time)),
            endDate: toJakartaDate(parseISO(obj.end_time)),
            title: obj.category_name,
            content: "",
            status : "SLOT CREATED",
            customer : obj.customer_name,
            faded : true,
            clickAction: (obj, obj2) => {
              console.log("ROSE", obj, obj2)
              setSelectedAppointmentSlot(obj)
              setShowOptions(true)
            },
            booked : [obj]
          }
        }))

        // setAppointmentsV2([
        //   {
        //     // ...obj,
        //     ID: 1,
        //     startDate: toJakartaDate(date),
        //     slotStartTime : toJakartaDate(date),
        //     endDate: toJakartaDate(date),
        //     title: "",
        //     content: "",
        //     status : "SLOT CREATED",
        //     customer : "",
        //     faded : true,
        //     booked : result,
        //     clickAction: (obj, obj2) => {
        //       console.log("ROSE", obj, obj2)
        //       setSelectedAppointmentSlot(obj)
        //     }
        //   }
        // ])

      }else{
        let result = await AppointmentModel.getDoctorAppointmentByWeek(id, dateStart, dateEnd)

        console.log("ROSU", result)

        setAppointmentsV2(result.map((obj,key)=>{
          return {
            ...obj,
            ID: obj.id,
            startDate: toJakartaDate(parseISO(obj.start_time)),
            slotStartTime : toJakartaDate(parseISO(obj.start_time)),
            endDate: toJakartaDate(parseISO(obj.end_time)),
            title: obj.category_name,
            content: "",
            status : "SLOT CREATED",
            customer : obj.customer_name,
            faded : true,
            clickAction: (obj, obj2) => {
              console.log("ROSE", obj, obj2)
              setSelectedAppointmentSlot(obj)
              setShowOptions(true)
            }
          }
        }))
      }



    }catch (e) {
      console.error(e)
    }
  }

  const { data: appointmentSlots } = useSelector(
    state => state.appointmentSlot.list
  );
  const { data: bookedAppointments } = useSelector(
    state => state.appointment.list
  );

  useEffect(() => {
    let newAppointments = [];
    const mappedAppointmentSlots = appointmentSlots.map(appointmentSlot => ({
      ID: appointmentSlot.id,
      startDate: toJakartaDate(parseISO(appointmentSlot.start_time)),
      slotStartTime: toJakartaDate(parseISO(appointmentSlot.start_time)),
      endDate: toJakartaDate(parseISO(appointmentSlot.end_time)),
      title: appointmentSlot.booking_type_name,
      content: appointmentSlot.patient_name,
      status : appointmentSlot.status,
      faded: true,
      booked : appointmentSlot.booked,
      clickAction: ID => {
        setSelectedAppointmentSlot(appointmentSlot);
        setIsAddSlotDialogOpen(true);
      }
    }));

    const mappedBookedAppointments = bookedAppointments.map(
      appointmentSlot => ({
        ID: appointmentSlot.id,
        startDate: toJakartaDate(parseISO(appointmentSlot.start_time)),
        slotStartTime : toJakartaDate(parseISO(appointmentSlot.start_time)),
        endDate: toJakartaDate(parseISO(appointmentSlot.end_time)),
        title: appointmentSlot.booking_type_name,
        content: appointmentSlot.patient_name,
        status : appointmentSlot.status,
        customer : appointmentSlot.customer_name,
        clickAction: ID => {
          history.push(`/appointment/${ID}`);
        }
      })
    );

    newAppointments = [
      ...newAppointments,
      ...mappedAppointmentSlots,
      ...mappedBookedAppointments
    ];

    setAppointments(newAppointments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSlots, bookedAppointments]);

  const handleDateChange = date => {
    setDate(date);
  };

  const [isAddSlotDialogOpen, setIsAddSlotDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isBlockDialogOpen, setIsBlockDialogOpen] = useState(false);

  const handleClick = () => {
    setIsAddSlotDialogOpen(true);
    setSelectedAppointmentSlot(null)
  };

  return (
    <>
      <Card>
        <CardHeader title={`${doctor && doctor.doctor_name}'s Schedule`}>
          <CardHeaderToolbar>
            { role !== 'DOCTOR' &&
              <Button onClick={handleClick} variant="contained" color="primary">
              Add Appointment Slot
            </Button>
            }
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={date}
              onChange={handleDateChange}
              shouldDisableDate={date => date.getDay() !== 1}
              label="Start of week"
              variant="inline"
              inputVariant="outlined"
              size="small"
              format="EEEE, dd MMM yyyy"
              style={{ marginBottom: '2rem' }}
            />
          </MuiPickersUtilsProvider>
          <div style={{ overflowX: 'scroll' }}>
            <TimetableV2 date={date} events={appointmentsV2} 
              setAddModalVisibility={setIsAddDialogOpen} 
              setBlockModalVisibility={setIsBlockDialogOpen}
              setEditModalVisibility={setIsEditDialogOpen}/>
          </div>
        </CardBody>
      </Card>

      <AddAppointmentSlotDialogV2
        isOpen={isAddSlotDialogOpen}
        handleClose={(refresh) => {
          setIsAddSlotDialogOpen(false);
          if(refresh){
            getAppointmentsV2()
          }
        }}
        doctorID={id}
        data={selectedAppointmentSlot}
      />

      <Modal show={showOptions} onHide={() => setShowOptions(false)}>
          <Modal.Header>
            <Modal.Title>Choose Options</Modal.Title>
            <IconButton
              onClick={() => setShowOptions(false)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body>
            <div>
              Category: <b>{selectedAppointmentSlot?.title}</b><br/>
              Status: <b>{selectedAppointmentSlot?.status}</b><br/>
              Start Time: <b>{moment(selectedAppointmentSlot?.start_time).format("DD/MM/YYYY HH:mm:ss")}</b><br/>
              End Time: <b>{moment(selectedAppointmentSlot?.end_time).format("DD/MM/YYYY HH:mm:ss")}</b>
            </div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button color="primary" variant="contained" className='mr-2' onClick={() => setIsAddDialogOpen(true)}>Add Appointment</Button>
            <Button color="secondary" variant="contained" className='mr-2' onClick={() => setIsEditDialogOpen(true)}>Edit Appointment</Button>
            <Button color="default" variant="contained" onClick={() => setIsBlockDialogOpen(true)}>Block Appointment</Button>
          </Modal.Footer>
          <AddAppointmentDialogV2
              isOpen={isAddDialogOpen}
              handleClose={(refresh) => {
                setIsAddDialogOpen(false);
                getAppointmentsV2()
              }}
              doctorID={id}
              data={selectedAppointmentSlot}
            />
          <EditAppointmentDialog
            isOpen={isEditDialogOpen}
            handleClose={(refresh) => {
              setIsEditDialogOpen(false);
              getAppointmentsV2()
            }}
            doctorID={id}
            data={selectedAppointmentSlot}
          />
          <BlockAppointmentDialog
            isOpen={isBlockDialogOpen}
            handleClose={(refresh) => {
              setIsBlockDialogOpen(false);
              getAppointmentsV2()
            }}
            doctorID={id}
            data={selectedAppointmentSlot}
          />
      </Modal>
    </>
  );
};

export default DoctorTimetablePage;

const toJakartaDate = date => {
  let tzDate = new Date(
    date.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
  );

  return tzDate;
};
