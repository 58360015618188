import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import AppointmentTypeForm from './components/AppointmentTypeForm';

import { addAppointmentType } from 'redux/appointmentType/ops';

const AppointmentTypeAddPage = () => {
  const dispatch = useDispatch();

  const { completed, isFetching } = useSelector(
    state => state.appointmentType.ops
  );

  useEffect(() => {
    if (completed) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      duration: 3,
      isPaymentProofRequired: false,
      category_id: 1
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      duration: Yup.number().required('Cannot be empty'),
      isPaymentProofRequired: Yup.boolean().required('Cannot be empty')
    }),
    onSubmit: values => {
      // console.log(values);
      dispatch(addAppointmentType(values));
    }
  });

  return (
    <AppointmentTypeForm
      isEditing={true}
      values={values}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      errors={errors}
      touched={touched}
      actionBar={
        <ActionBar
          isFetching={isFetching}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
        />
      }
    />
  );
};

export default AppointmentTypeAddPage;
