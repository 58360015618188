import ApiRequest from '../../utils/APIRequest';

export default class Clinic {
  getAllClinic = async () => {
    return await ApiRequest.set('v1/clinics', 'GET');
  };

  addClinic = async newClinic => {
    return await ApiRequest.set('v1/clinic', 'POST', newClinic);
  };

  editClinic = async (id, clinicData) => {
    return await ApiRequest.set('v1/clinic/' + parseInt(id), 'PUT', clinicData);
  };

  getClinicById = async (id) => {
    return await ApiRequest.set('v1/clinic/' + id, 'GET');
  };
}
