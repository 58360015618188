import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

// import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice';
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice';
import { remarksSlice } from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import { specificationsSlice } from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';

import appointment from './appointment';
import appointmentType from './appointmentType';
import appointmentSlot from './appointmentSlot';
import customer from './customer';
import patient from './patient';
import medicalRecordType from './medicalRecordType';
import doctor from './doctor';
import participant from './participant';
import medicalRecord from './medicalRecord';
import user from './user';
import visitReminder from './visitReminder';

import diagnosis from './diagnosis';
import disease from './disease';
import symptom from './symptom';
import symptomLocation from './symptomLocation';
import medicine from './medicine';

import animalCategory from './animalCategory';
import animalType from './animalType';

import resetPassword from './resetPassword';

import auth from './auth';
import loading from './loading';
import snackbarNotification from './snackbarNotification';

// export const rootReducer = combineReducers({
//   // auth: auth.reducer,
//   auth: authReducer,

//   customers: customersSlice.reducer,
//   products: productsSlice.reducer,
//   remarks: remarksSlice.reducer,
//   specifications: specificationsSlice.reducer,

//   customer: customerReducer
// });

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }

export default combineReducers({
  // Common Reducers
  auth,
  loading,
  snackbarNotification,

  appointment,
  appointmentType,
  appointmentSlot,
  customer,
  patient,
  medicalRecordType,
  doctor,
  participant,
  medicalRecord,
  user,
  visitReminder,

  diagnosis,
  disease,
  symptom,
  symptomLocation,
  medicine,

  animalType,
  animalCategory,

  resetPassword
});
