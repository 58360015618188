/**
 * @todo: fix the dynamic time range
 */
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  Popover, Select
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {addMinutes, format, parseISO} from 'date-fns';
import _ from 'lodash';

import TextField from 'app/components/common/TextField';
import DatePicker from 'app/components/common/DatePicker';
import AppointmentTimeSlot from './AppointmentTimeSlot';
import {TIME_SLOT, TIME_SLOT_GROOMING} from './AppointmentTimeSlot';
import {approveAppointment} from 'redux/appointment/approve';
import Appointment from "../../../models/Appointment";
import swal from "sweetalert2";
import OperationTable from "../../../models/OperationTable";
import TimePicker from "../../../components/common/TimePicker/TimePicker";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '600px'
  },
  title: {
    marginBottom: '2rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  actionContainer: {
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

let appointmentModel = new Appointment()

const RejectAppointmentDialogV2 = ({open, handleClose, appointmentId}) => {

  const classes = useStyles();

  const [rejectReason, setRejectReason] = useState(null);

  const handleButtonClick = async () => {

    try {
      let result = await appointmentModel.rejectAppointment(appointmentId, rejectReason)
      console.log(result)
      handleClose(true)
    } catch (e) {
      console.log(e)
    }

  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{container: classes.container, paper: classes.paper}}
    >
      <DialogTitle className={classes.title}>Appointment Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={rejectReason}
              onChange={(e) => {
                setRejectReason(e.target.value)
              }}
              label="Reject Reason"
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Grid container alignItems="stretch">
          <Grid item xs={12}>
            <Grid
              container
              alignItems="flex-end"
              justify="flex-end"
              style={{height: '100%'}}
            >
              <Grid item>
                <Button
                  style={{marginRight: 10}}
                  onClick={() => handleClose(false)}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleButtonClick}
                  color="primary"
                  variant="contained"
                >
                  Submit and Reject
                </Button>
              </Grid>
              {/* <Grid item style={{ marginLeft: '0.5rem' }}>
                <Button color="secondary" variant="outlined">
                  Reject
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RejectAppointmentDialogV2;
