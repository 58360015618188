import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Grid, IconButton,
  MenuItem
} from '@material-ui/core';

import {TextField} from 'app/components/common/TextField';
import TimePicker from 'app/components/common/TimePicker';

import {makeStyles} from '@material-ui/core/styles';
import UserModel from "../../../../model/UserModel";
import DoctorModel from "../../../../app/models/Doctor";
import CategoryModel from "../../../../model/CategoryModel";
import OperationTable from 'app/models/OperationTable';
import {Container, Row, Col, Modal} from "react-bootstrap";

import moment from "moment";
import Select from "react-select"
import {Autocomplete} from "@material-ui/lab";
import AppointmentModel from "../../../models/Appointment";
import swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '800px',
    maxWidth: '800px'
  },
  title: {
    marginBottom: '2rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  datePicker: {
    marginBottom: '1rem'
  },
  actionContainer: {
    justifyContent: 'flex-start',
    marginTop: '2rem',
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const formatDate = x => moment(x).local().format('D/M/YYYY, HH:mm')
const formatTime = x => moment(x).local().format('HH:mm')

let categories = []
let operasi = null

const ChangeDoctorDialog = ({isOpen, handleClose, doctor, appointmentId, bookingType}) => {

  //const history = useHistory();
  //const dispatch = useDispatch();
  const classes = useStyles();

  const [doctorSelection, setDoctorSelection] = useState([])
  const [selectedDoctor, setSelectedDoctor] = useState(null)

  useEffect(()=>{
    retrieveDoctor()
  },[])

  useEffect(()=>{
    console.log("CHANGING TO IS CALLED", doctor)

    if(doctor.value){
      console.log("CHANGING TO ", doctor)
      setSelectedDoctor(doctor)
    }
  },[doctor])

  const onClose = (refresh) => {
    handleClose(refresh)
  }

  const submitForm = async () => {

    try {
      let appointment = new AppointmentModel()
      let result = await appointment.appointmentEditDoctorID({
        appointment_user_id : appointmentId,
        doctor_id : selectedDoctor.value
      });

      onClose(true)

    } catch (e) {

      let error = await swal.fire({
        title: "Error",
        text: e.error_message ? e.error_message : "Terjadi Kesalahan",
        icon: 'error',
        confirmButtonText: "Go back",
        customClass: {
          container: 'swal-z'
        }
      });

      console.log(e)
    }
  }

  const retrieveDoctor = async () => {
    try {

      const result = await DoctorModel.getDoctorByBookingType(bookingType)

      console.log("doctor list", result)

      if (result) {
        let formatted = result.map(obj => {
          return {
            ...obj,
            label: `${obj.doctor_name}`,
            value: obj.id
          }
        })
        setDoctorSelection(formatted)
      } else {
        setDoctorSelection([])
      }

    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal show={isOpen} onHide={handleClose} classes={{container: classes.container, paper: classes.paper}}>
      <form>
        <Modal.Title className={classes.title}>Change Doctor</Modal.Title>
        <Modal.Body>
          <Row>
            <Col md={12}>
              Doctor
              <Autocomplete
                value={selectedDoctor}
                onChange={(_, newValue) => {
                  console.log("new val", newValue)
                  setSelectedDoctor(newValue);
                  //setBookingTypeSelection(newValue.booking_type_name.map(x=>{return {label:x.id,value:x}}))
                }}
                options={doctorSelection}
                getOptionLabel={option => (option ? `${option.label}` : '')}
                size="small"
                renderInput={params => <TextField {...params} variant="outlined"/>}
              />
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer className={classes.actionContainer}>
          <>
            <Button color="primary" variant="contained" onClick={() => submitForm()}>
              Create
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeDoctorDialog;
