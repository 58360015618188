import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, IconButton } from '@material-ui/core';
import DateFns from '@date-io/date-fns';
import { useEffect, useRef, useState } from 'react';
import moment from "moment"
import AppointmentModel from '../../../model/AppointmentModel';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const centerstyle = {width: '60%', margin: 'auto'}
const centerNothingStyle = {width: '100%', height : 400, display : "flex", alignItems : "center", justifyContent : "center"}

const nodatatext = <div style={centerNothingStyle}><h1>No Data</h1></div>
const sum = x => x?.length ? x.reduce((a,b)=>a+b,0) : 0

const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',  'Saturday', 'Sunday'];
const category = ['Poliklinik', 'Grooming', 'Operasi', 'RTWA']
const colorsCbfRainbowRGB = {
  red: [217,33,32],
  blue: [72,139,194],
  violet: [120,28,129],
  indigo: [64,67,153],
  green: [107,178,140],
  olive: [159,190,87],
  yellow: [210,179,63],
  orange: [231,126,49],
}

const dummydata = [
  [1, 4, 8, 12, 14, 15, 18],
  [2, 3, 6, 10, 14, 16, 17],
  [1, 6, 10, 11, 17, 18, 19],
  [13, 4, 7, 11, 13, 14, 0]
]
const dummydatadoc = [
  [2, 3, 6, 10, 14, 16, 17],
  [1, 4, 8, 12, 14, 15, 18],
  [13, 4, 7, 11, 13, 14, 0]
  [1, 6, 10, 11, 17, 18, 19],
]

const lineData  = (names, data)=> {
  let colors = Object.values(colorsCbfRainbowRGB)
  return {
    labels,
    datasets: names.map(n=>{
      let c = colors.shift().join(',')
      return{
        label: n,
        data: data[n],
        backgroundColor: `rgba(${c}, 0.5)`,
        borderColor: `rgb(${c})`,
      }
    }),
  }
}


const pieData = (names,data)=> {
  
  let colors = Object.values(colorsCbfRainbowRGB).map(c=>`rgba(${c.join(',')},0.5)`)
  colors = colors.slice(0,names.length)
  return {
    labels:names,
    datasets: [{
      label: 'Created Appointment',
      data:  names.map(n=>sum(data[n])),
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 1
    }],
  }
  
};

const lineoptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    }
  },
};

const pieoptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          
          let label = context.label || '';
          let val = context.raw
          let datas = context.dataset.data
          console.log(context)
          
          let total = sum(datas)
          let percentage = (val / total * 100)
          if(percentage.toString().length > 6) percentage = percentage.toFixed(2)

          
          label += `: ${val} (${percentage}%)`;
          
          
          return label;
        }
      }
    }
  }
};

function mondayOnly(date) {
  return date.getDay() !== 1;
}

const OperationTableListPage = () => {
    const [startDate, setStartDate] = useState(moment().startOf('isoWeek'));
    let endDate = moment(startDate).add(1,'weeks')
    let startstr  = moment(startDate).format('Do MMMM')
    let endstr = moment(endDate).format('Do MMMM')
    const [catData, setCatdata]  = useState([])
    const [docData, setDocdata]  = useState([])
    let doctors = Object.keys(docData)
    let categories = Object.keys(catData)
    let empty = (doctors.length + categories.length) === 0

    

    useEffect(async() => {
        let newdata = await AppointmentModel.getWeeklyReport(moment(startDate).format('YYYY/MM/DD'), endDate.format('YYYY/MM/DD'))
        console.log(newdata)
        setCatdata(newdata.category)
        setDocdata(newdata.doctor)


    }, [startDate]);

  return (
    
    <>
      <Card>
        <CardHeader title="Weekly Report"></CardHeader>
        <Grid container alignItems="center">
          <MuiPickersUtilsProvider utils={DateFns}>
            <Grid item style={{ marginLeft: '1rem' }}>
              <DatePicker
                value={startDate}
                onChange={(e)=>setStartDate(e)}
                label="Start date"
                variant="inline"
                inputVariant="outlined"
                size="small"
                style={{
                  width: '160px'
                }}
                disableFuture={true}
                shouldDisableDate={mondayOnly}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <CardHeader title="Category"></CardHeader>
        {empty? nodatatext: 
        <CardBody>
        <CardHeader title={`Created appointments (${startstr} - ${endstr})`}></CardHeader>
        <Line options={lineoptions} data={lineData(categories,catData)} />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <CardHeader title={`Total created appointments (${startstr} - ${endstr})`}></CardHeader>
        <div style={centerstyle}>
          <Pie options={pieoptions} data={pieData(categories,catData)} />
        </div>
      </CardBody>
        }
        
        <CardHeader title="Doctor"></CardHeader>
        {empty? nodatatext: 
        <CardBody>
        <CardHeader title={`Created appointments (${startstr} - ${endstr})`}></CardHeader>
        
        <Line options={lineoptions} data={lineData(doctors,docData)} />
        
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <CardHeader title={`Total created appointments (${startstr} - ${endstr})`}></CardHeader>
        <div style={centerstyle}>
          <Pie options={pieoptions} data={pieData(doctors,docData)} />
        </div>
      </CardBody>
        }
    
        
      </Card>
    </>
  );
};

export default OperationTableListPage;
