import { useFormik } from 'formik';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import DiagnoseBuilderForm from './components/DiagnoseBuilderForm';

const DiagnoseBuilderAddPage = () => {
  const formik = useFormik({
    initialValues: {
      criteria: {
        question: '',
        answers: [
          {
            answer: '',
            question: ''
          }
        ]
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Diagnose builder" />
      <CardBody>
        <DiagnoseBuilderForm formik={formik} isEditing={true} />
      </CardBody>
    </Card>
  );
};

export default DiagnoseBuilderAddPage;
