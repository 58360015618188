import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PasswordTextField } from 'app/components/common/TextField';
import ActionBar from 'app/components/common/ActionBar/ActionBar';

import { resetPasswordByID } from 'redux/resetPassword';

const ChangePasswordForm = () => {
  const dispatch = useDispatch();

  const { ID } = useSelector(state => state.auth);
  const { completed, isLoading: isResetPasswordLoading } = useSelector(
    state => state.resetPassword
  );

  useEffect(() => {
    if (completed) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Cannot be empty')
    }),
    onSubmit: formValues => {
      const formattedValues = {
        ID,
        password: formValues.password
      };

      dispatch(resetPasswordByID(formattedValues));
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PasswordTextField
            id="password"
            value={values.password}
            onChange={handleChange}
            label="New Password *"
            helperText={
              touched.password && errors.password ? errors.password : ''
            }
            error={touched.password && errors.password ? true : false}
          />
        </Grid>
        <ActionBar isFetching={isResetPasswordLoading}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Change Password
          </Button>
        </ActionBar>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
