/**
 * @todo: fix the dynamic time range
 */
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    MenuItem,
    Popover, Select
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {addMinutes, format, parseISO} from 'date-fns';
import _ from 'lodash';

import TextField from 'app/components/common/TextField';
import DatePicker from 'app/components/common/DatePicker';
import AppointmentTimeSlot from './AppointmentTimeSlot';
import {TIME_SLOT, TIME_SLOT_GROOMING} from './AppointmentTimeSlot';
import {approveAppointment} from 'redux/appointment/approve';
import Appointment from "../../../models/Appointment";
import swal from "sweetalert2";
import OperationTable from "../../../models/OperationTable";
import TimePicker from "../../../components/common/TimePicker/TimePicker";
import RejectAppointmentDialogV2 from "./RejectAppointmentDialogV2";

import moment from "moment"

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    paper: {
        width: '600px'
    },
    title: {
        marginBottom: '2rem',
        padding: '2rem 2rem 1rem 2rem',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    timeSlotContainer: {
        maxWidth: '360px'
    },
    actionContainer: {
        padding: '1.5rem 2rem 1.5rem 2rem',
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

let operationTableModel = new OperationTable()

const ApproveAppointmentDialogV2 = ({open, handleClose, data}) => {

    const classes = useStyles();

    const [operationTableOptions, setOperationTableOptions] = useState([])
    const [selectedOperationTable, setSelectedOperationTable] = useState(null)

    const [newStartTime, setNewStartTime] = useState(null)
    const [newEndTime, setNewEndTime] = useState(null)

    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)

    console.log("data", data)

    let patients = []

    for (let i = 0; i < data?.patients?.length; i++) {
        let age = data.patients_birthdate[i] ?? 0
        const millis = Date.now() - Date.parse(age);
        age = new Date(millis).getFullYear() - 1970;

        let p = {
            name: data.patients[i],
            weight: data.patients_weight[i],
            breed: data.patients_breed[i],
            age: age,
            animaltype: data.patients_animaltype[i]
        }
        patients.push(p)
    }

    useEffect(() => {
        fetchClinicSelection()
    }, [data?.clinic_id])

    useEffect(() => {
        if (data?.operation_table_id) {
            setSelectedOperationTable(data.operation_table_id)
        }
    }, [data?.operation_table_id])

    const fetchClinicSelection = async () => {
        console.log("FETCHING CLINICS", data?.clinic_id)
        if (data && data.clinic_id !== undefined) {
            try {
                let result = await operationTableModel.getAllOperationByClinicId(data.clinic_id)
                console.log("FETCHING CLINICS RESULT", result)
                setOperationTableOptions(result)
            } catch (e) {
                console.log(e)
            }

        }
    }

    const handleApproveClick = async () => {
        let appointmentModel = new Appointment()
        try {

            if (!data.proof_of_payment) {
                let result = await swal.fire({
                    title: 'Warning',
                    text: 'This appointment payment proof have not been uploaded. Are you sure you want to continue?',
                    icon: 'warning',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: "No",
                    customClass: {
                        container: 'swal-z'
                    }
                });

                console.log(result)

                if (!result.isConfirmed) {
                    return
                }
            }

            const result = await appointmentModel.confirmAppointment(
                data.id,
                newStartTime ? newStartTime : data.start_time,
                newEndTime ? newEndTime : data.end_time,
                selectedOperationTable ? selectedOperationTable : null
            )

            console.log(result)

            await swal.fire({
                title: 'Success',
                text: 'Appointment has been approved',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            });

            handleClose(true)

        } catch (e) {
            console.log(e)
            swal.fire({
                title: 'Error',
                text: e.error_message ? e.error_message : "An Error Occured",
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal-z'
                }
            });
        }
    };

    if (!data) return null;

    const startTime = data?.start_time
        ? format(parseISO(data?.start_time), 'HH:mm')
        : '...';
    const endTime = data?.end_time
        ? format(parseISO(data?.end_time), 'HH:mm')
        : '...';

    // const formatPatient = () => {
    //     let toReturn = ""
    //     if (data?.patients?.map) {
    //         for (let pIndex in data.patients) {
    //             let p = data.patients[pIndex]
    //             toReturn += `${p}${pIndex < data.patients.length - 1 ? ", " : ""}`
    //         }
    //     }
    //     console.log("TR", toReturn)
    //     return toReturn
    // }

    // const patients = formatPatient()

    const renderTimeInput = props => {
        return (
            <TextField
                disabled={props.disabled}
                value={`${props.value}`}
                onChange={props.onChange}
                onClick={props.onClick}
                label={props.label}
            />
        );
    };

    return (
        <>
            <Dialog
                open={open && !isRejectModalOpen}
                onClose={handleClose}
                classes={{container: classes.container, paper: classes.paper}}
            >
                <DialogTitle className={classes.title}>Appointment Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                value={data?.customer_name || ''}
                                label="Owner"
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <table class="table table-striped table-bordered dt-responsive nowrap" cellspacing="0"
                                   width="100%">
                                <thead>
                                Patients
                                <tr>
                                    <th>Name</th>
                                    <th>Weight</th>
                                    <th>Breed</th>
                                    <th>Age</th>
                                    <th>Animal type</th>

                                </tr>
                                </thead>
                                <tbody>
                                {patients.map(o => <tr>
                                    <td>{o.name}</td>
                                    <td>{o.weight}</td>
                                    <td>{o.breed}</td>
                                    <td>{o.age}</td>
                                    <td>{o.animaltype}</td>
                                </tr>)}

                                </tbody>
                            </table>
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                disabled
                                value={data?.doctor_name || ''}
                                label="Doctor"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                              disabled
                              value={moment(data?.start_time).format("DD MMM YYYY") || ''}
                              label="Date"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TimePicker
                                value={newStartTime ? newStartTime : data.start_time}
                                onChange={date => setNewStartTime(date)}
                                clearable
                                label="Start Time"
                                TextFieldComponent={renderTimeInput}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TimePicker
                                value={newEndTime ? newEndTime : data.end_time}
                                onChange={date => setNewEndTime(date)}
                                clearable
                                label="End Time"
                                TextFieldComponent={renderTimeInput}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <TextField
                                disabled
                                value={data?.booking_type_name || ''}
                                label="Booking Type"
                            />
                        </Grid>

                        <Grid item md={6}>
                            <TextField
                                disabled
                                value={data?.category_name || ''}
                                label="Booking Category"
                            />
                        </Grid>

                        <Grid item md={6}>
                            <TextField
                                disabled
                                value={data?.clinic_name || ''}
                                label="Clinic Name"
                            />
                        </Grid>
                        {data?.category_name === "Operasi" &&
                        <Grid item md={6}>
                            <TextField
                                id="operationType"
                                select
                                value={selectedOperationTable}
                                onChange={event => {
                                    setSelectedOperationTable(event.target.value)
                                }}
                                label="Operation Table *"
                            >
                                {operationTableOptions.map((obj, key) => (
                                    <MenuItem
                                        key={key}
                                        value={obj.id}
                                    >
                                        {`${
                                            obj.name
                                        }`} ({obj.type})
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        }

                        {
                            data.proof_of_payment &&
                            <Grid item xs={12} md={12}>
                                <TextField
                                    disabled
                                    value={``}
                                    label="Proof of Payment"
                                />
                                {
                                    data.proof_of_payment.includes("https://docs.google.com/gview?url") ?
                                      <iframe src={data.proof_of_payment}/>
                                      :
                                      <img
                                        style={{
                                            width: "100%",
                                            objectFit: "contain"
                                        }}
                                        src={data.proof_of_payment}/>
                                }

                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Grid container alignItems="stretch">
                        <Grid item xs={12}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justify="flex-end"
                                style={{height: '100%'}}
                            >
                                <Grid item>
                                    <Button
                                        style={{
                                            marginRight : 10
                                        }}
                                        onClick={()=>{
                                            setIsRejectModalOpen(true)
                                        }}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Reject
                                    </Button>
                                    <Button
                                        onClick={handleApproveClick}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Approve
                                    </Button>
                                </Grid>
                                {/* <Grid item style={{ marginLeft: '0.5rem' }}>
                <Button color="secondary" variant="outlined">
                  Reject
                </Button>
              </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <RejectAppointmentDialogV2
                open={isRejectModalOpen}
                handleClose={(isSubmission) => {
                    setIsRejectModalOpen(false)
                    if(isSubmission){
                        handleClose(true)
                    }
                }}
                appointmentId={data?.id}
            />
        </>
    );
};

export default ApproveAppointmentDialogV2;
