import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AsideMenuList } from './AsideMenuList';
import AsideMenuListDoctor from './AsideMenuListDoctor';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

export function AsideMenu({ disableScroll }) {
  const { role } = useSelector(state => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {role === 'DOCTOR' ? (
          <AsideMenuListDoctor layoutProps={layoutProps} />
        ) : (
          <AsideMenuList layoutProps={layoutProps} />
        )}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
