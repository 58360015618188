import api from '../../api/userAPI';

const LOGIN_BEGIN = 'auth/LOGIN_BEGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAILED = 'auth/LOGIN_FAILED';
const RESET = 'auth/RESET';

const loginBegin = () => ({
  type: LOGIN_BEGIN
});

const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: data
});

const loginFailed = error => ({
  type: LOGIN_FAILED,
  payload: error
});

export const login = (username, password) => async dispatch => {
  dispatch(loginBegin());

  const res = await api.post(`user-login`, {
    user_name: username,
    password: password
  });

  if (
    res.data.success &&
    (res.data.result[0].role === 'ADMIN' ||
      res.data.result[0].role === 'CS' ||
      res.data.result[0].role === 'DOCTOR')
  ) {
    console.log(res.data.result[0]);
    dispatch(
      loginSuccess({
        ID: res.data.result[0].user_id,
        doctorID: res.data.result[0].doctor_id,
        username: res.data.result[0].user_name,
        token: res.data.token,
        role: res.data.result[0].role
      })
    );
  } else {
    dispatch(loginFailed('Failed to login'));
  }
};

export const reset = () => ({
  type: RESET
});

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_BEGIN:
      return { ...state, isFetching: true, error: '' };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ID: payload.ID,
        doctorID: payload.doctorID,
        user: payload.username,
        token: payload.token,
        role: payload.role,
        isFetching: false
      };
    case LOGIN_FAILED:
      return { ...state, error: payload, isFetching: false };

    case RESET:
      return { ...state, isFetching: false, error: '' };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  ID: undefined,
  doctorID: undefined,
  user: undefined,
  token: undefined,
  role: undefined,
  isFetching: false,
  error: ''
};
