import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const DELETE_BEGIN = 'visitReminder.delete/DELETE_BEGIN';
export const DELETE_SUCCESS = 'visitReminder.delete/DELETE_SUCCESS';
export const DELETE_FAILED = 'visitReminder.delete/DELETE_FAILED';

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deleteVisitReminder = ID => async dispatch => {
  dispatch(deleteBegin());

  try {
    await api.delete(`delete-visit-reminder?id=${ID}`);

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case DELETE_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case DELETE_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
