import React, {
  // Suspense,
  lazy
} from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { /*LayoutSplashScreen, */ ContentRoute } from '../_metronic/layout';
import { BuilderPage } from './pages/BuilderPage';
import { MyPage } from './pages/MyPage';
import { DashboardPage } from './pages/DashboardPage';

import { ROUTE_CONFIG } from './RouteConfig';

const GoogleMaterialPage = lazy(() =>
  import('./modules/GoogleMaterialExamples/GoogleMaterialPage')
);
const ReactBootstrapPage = lazy(() =>
  import('./modules/ReactBootstrapExamples/ReactBootstrapPage')
);
const ECommercePage = lazy(() =>
  import('./modules/ECommerce/pages/eCommercePage')
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const { doctorID, role } = useSelector(state => state.auth);

  return (
    // <Suspense fallback={<LayoutSplashScreen/>}>
    <Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect
          exact
          from="/"
          to={
            role === 'DOCTOR' ? `/doctor/${doctorID}/schedule` : '/appointment'
          }
        />
      }
      <ContentRoute path="/dashboard" component={DashboardPage} />
      <ContentRoute path="/builder" component={BuilderPage} />
      <ContentRoute path="/my-page" component={MyPage} />
      <Route path="/google-material" component={GoogleMaterialPage} />
      <Route path="/react-bootstrap" component={ReactBootstrapPage} />
      <Route path="/e-commerce" component={ECommercePage} />

      {ROUTE_CONFIG.map(
        ({ path, component, blackList }) =>
          !blackList?.includes(role) && (
            <ContentRoute exact path={path} component={component} key={path} />
          )
      )}

      {/* <ContentRoute exact path="/customer" component={CustomerListPage} />
        <ContentRoute exact path="/customer/add" component={CustomerAddPage} />
        <ContentRoute exact path="/customer/:id" component={CustomerDetailPage} />

        <ContentRoute exact path='/symptom' component={SymptomListPage} />
        <ContentRoute exact path='/symptom/add' component={SymptomAddPage} />
        <ContentRoute exact path='/symptom/:id' component={SymptomEditPage} />

        <ContentRoute exact path='/disease' component={DiseaseListPage} />
        <ContentRoute exact path='/disease/add' component={DiseaseAddPage} />

        <ContentRoute exact path='/animalCategory' component={AnimalCategoryListPage} />
        <ContentRoute exact path='/animalCategory/add' component={AnimalCategoryAddPage} />
        <ContentRoute exact path='/animalCategory/:id' component={AnimalCategoryEditPage} />

        <ContentRoute exact path='/animalType' component={AnimalTypeListPage} />
        <ContentRoute exact path='/animalType/add' component={AnimalTypeAddPage} />
        <ContentRoute exact path='/animalType/:id' component={AnimalTypeEditPage} /> */}

      <Redirect to="/error" />
    </Switch>
    // </Suspense>
  );
}
