import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Grid, IconButton,
    MenuItem
} from '@material-ui/core';

import {TextField} from 'app/components/common/TextField';
import TimePicker from 'app/components/common/TimePicker';

import {makeStyles} from '@material-ui/core/styles';
import UserModel from "../../../../model/UserModel";
import DoctorModel from "../../../../app/models/Doctor";
import CategoryModel from "../../../../model/CategoryModel";
import OperationTable from 'app/models/OperationTable';
import {Container, Row, Col, Modal} from "react-bootstrap";

import moment from "moment";
import Select from "react-select"
import {Autocomplete} from "@material-ui/lab";
import AppointmentModel from "../../../../model/AppointmentModel";
import swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    paper: {
        width: '800px',
        maxWidth: '800px'
    },
    title: {
        marginBottom: '2rem',
        padding: '2rem 2rem 1rem 2rem',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    timeSlotContainer: {
        maxWidth: '360px'
    },
    datePicker: {
        marginBottom: '1rem'
    },
    actionContainer: {
        justifyContent: 'flex-start',
        marginTop: '2rem',
        padding: '1.5rem 2rem 1.5rem 2rem',
        borderTop: `1px solid ${theme.palette.divider}`
    }
}));

const formatDate = x => moment(x).local().format('D/M/YYYY, HH:mm')
const formatTime = x => moment(x).local().format('HH:mm')

let categories = []
let operasi = null
const AddAppointmentDialog = ({isOpen, handleClose}) => {

    //const history = useHistory();
    //const dispatch = useDispatch();
    const classes = useStyles();
    const { role } = useSelector(state => state.auth);
    

    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)

    const [ownerSelection, setOwnerSelection] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(null)
    const [selectedOwnerInputValue, setSelectedOwnerInputValue] = useState(null)

    const [doctorSelection, setDoctorSelection] = useState([])
    const [selectedDoctor, setSelectedDoctor] = useState(null)

    const [apslotSelection, setApSlotSelection] = useState([])
    const [selectedApSlot, setSelectedApSlot] = useState(null)

    const [selectedClinic, setSelectedClinic] = useState(null)

    const [patients, setPatients] = useState([])
    const [selectedPatient, setSelectedPatient] = useState(null)
    const [patientSelection, setPatientSelection] = useState([])
    const [selectedPatientInputValue, setSelectedPatientInputValue] = useState(null)

    
    const [bookingTypeSelection, setBookingTypeSelection] = useState([])
    const [selectedBookingType, setSelectedBookingType] = useState(null)
    const [selectedBookingTypeInputValue, setSelectedBookingTypeInputValue] = useState(null)
    
    
    const [operationTableSelection, setOperationTableSelection] = useState([])
    const [selectedOperationTableInputValue, setSelectedOperationTableInputValue] = useState(null)
    const [selectedOperationTableType, setSelectedOperationTableType] = useState(null)
    
    const [description, setDescription] = useState(null)

    const [takenTime, setakentime] = useState([])

    const onClose = (refresh) => {
        handleClose(refresh)
    }

    const submitForm = async() =>{
        try{
            if(patients.length === 0){
              swal.fire({
                title: "Error",
                text: "Mohon pilih setidaknya 1 pasien" ,
                icon: 'error',
                confirmButtonText: "Go back",
                customClass: {
                    container: 'swal-z'
                }
            });
              return
            }
            let patientIds = patients.map((p) => p.value)
            let result = await AppointmentModel.addAppointment(selectedApSlot.id, 
                selectedBookingType.value, startTime, endTime, patientIds, 
                selectedOwner.value, description, selectedOperationTableType?.value)
              console.log(result)
            let confirmResult = await swal.fire({
                title: "Success",
                text: "Appointment berhasil dibuat",
                icon: 'success',
                confirmButtonText: "Go back",
                customClass: {
                    container: 'swal-z'
                }
            });

            handleClose(true)

        }catch (e) {

            let error = await swal.fire({
                title: "Error",
                text: e.error_message ? e.error_message : "Terjadi Kesalahan" ,
                icon: 'error',
                confirmButtonText: "Go back",
                customClass: {
                    container: 'swal-z'
                }
            });

            console.log(e)
        }
    }

    const retrieveUser = async () => {
        try {

            const result = await UserModel.retrieveUser()

            if (result.result) {
                let formatted = result.result.map(obj => {
                    return {
                        label: `${obj.user_name} (${obj.mobile})`,
                        value: obj.id
                    }
                })
                setOwnerSelection(formatted)
            } else {
                setOwnerSelection([])
            }

        } catch (e) {
            console.log(e)
        }
    }

    const retrieveDoctor = async () => {
      try {

          const result = await DoctorModel.getAll()

          if (result) {
              let formatted = result.map(obj => {
                  return {
                      ...obj,
                      label: `${obj.doctor_name}`,
                      value: obj.id
                  }
              })
              setDoctorSelection(formatted)
          } else {
              setDoctorSelection([])
          }

      } catch (e) {
          console.log(e)
      }
  }

  const retrieveAppointmentSlot = async (catid,docid) => {
    try {

        const result = await AppointmentModel.getUpcomingAppointmentslotbyCategoryandDoctorId(catid,docid)

        if (result) {
            let formatted = result.map(obj => {
                return {
                    ...obj,
                    label: `${formatDate(obj.start_time)} - ${formatDate(obj.end_time)}`,
                    value: obj.id
                }
            })
            console.log(formatted)
            setApSlotSelection(formatted)
        } else {
            setApSlotSelection([])
        }

    } catch (e) {
        console.log(e)
    }
}

const retrieveTakenTime = async () => {
  try {

      const result = await AppointmentModel.getBookedByAppointmentslotId(selectedApSlot.id)

      if (result) {
          let formatted = result.map(obj => {
              return {
                  ...obj,
                  label: `${formatTime(obj.start_time)} - ${formatTime(obj.end_time)}`,
                  value: obj.id
              }
          })
          console.log(formatted)
          setakentime(formatted)
      } else {
          setakentime([])
      }

  } catch (e) {
      console.log(e)
  }
}

    const retrievePatient = async () => {
        try {
            setPatientSelection([])
            setSelectedPatient(null)

            const result = await UserModel.retrievePatientByOwnerId(selectedOwner.value)

            if (result.result) {
                let formatted = result.result.map(obj => {
                    return {
                        label: `${obj.patient_name}`,
                        value: obj.id
                    }
                })
                setPatientSelection(formatted)
            } else {
                setPatientSelection([])
            }

        } catch (e) {
            console.log(e)
        }
    }

    const retrieveBookingType = async () => {
        try {


            setBookingTypeSelection([])
            setSelectedBookingType(null)
            
            const result = await CategoryModel.getBookingTypeByCategoryIdAndDoctorIdV2(7, selectedDoctor.doctor_id)

            console.log("RPO", result)
            if (result) {
                let formatted = result.map(obj => {
                    return {
                        ...obj,
                        label: `${obj.booking_type_name} (${obj.duration * 10} mins)`,
                        value: obj.id,
                    }
                })
                setBookingTypeSelection(formatted)
            } else {
                setBookingTypeSelection([])
            }

        } catch (e) {
            console.log("RPO", e)
        }
    }

    const retrieveOperationTable = async () => {
        let operationTableModel = new OperationTable()
        try {
            const result = await operationTableModel.getAllOperationByClinicId(parseInt(selectedClinic))

            console.log("Operation table", result)
            if (result) {
                let formatted = result.map(obj => {
                    return {
                      ...obj,
                        label: obj.name,
                        value: obj.id
                    }
                })
                setOperationTableSelection(formatted)
                
            } else {
                setOperationTableSelection([])
                setSelectedOperationTableType(null)
                setSelectedOperationTableInputValue(null)
            }

        } catch (e) {
            console.log("RPO", e)
        }
    }

    const retrieveCategories = async()=>{
      categories = await CategoryModel.getAll()
      operasi  = categories.find(c=>c.name === "Operasi")
    }


    useEffect(async() => {
        retrieveUser()
        retrieveDoctor()
        retrieveCategories()
    }, []);
    
    useEffect(() => {
        if(selectedOwner)
        retrievePatient()
        else{
          setSelectedPatient(null)
          setPatientSelection([])
          setPatients([])
        }
        
    }, [selectedOwner]);

    useEffect(() => {
      if(selectedDoctor)
      retrieveBookingType()
      else{
        setSelectedBookingType(null)
        setBookingTypeSelection([])
      }
  }, [selectedDoctor]);

  useEffect(() => {
      setSelectedApSlot(null)
      setApSlotSelection([])
}, [selectedBookingType]);

  useEffect(() => {
    if(operasi && selectedClinic != null && selectedBookingType?.category_id === operasi?.id)
    retrieveOperationTable()
}, [selectedBookingType, selectedClinic]);

  useEffect(() => {
    if(selectedBookingType && selectedDoctor)
    retrieveAppointmentSlot(selectedBookingType.category_id, selectedDoctor.doctor_id)

}, [selectedDoctor, selectedBookingType]);

useEffect(() => {
  if(!selectedApSlot){
    resetime()
    setSelectedClinic(null)
  }
  else{
    setStartTime(selectedApSlot.start_time)
    setSelectedClinic(selectedApSlot.clinic_id)
    retrieveTakenTime()
  }
}, [selectedApSlot]);

useEffect(() => {
  if(!selectedClinic){
    setOperationTableSelection([])
    setSelectedOperationTableType(null)
  }
}, [selectedClinic]);

useEffect(() => {
  setEndTime(moment(startTime).add((selectedBookingType?.duration * 10 * patients?.length), 'minutes'))
}, [patients, selectedBookingType, startTime]);

    const resetime = () => {
        setStartTime(null)
        setEndTime(null)
    }

    const renderTimeInput = props => {
        return (
            <TextField
                disabled={props.disabled}
                value={`${props.value}`}
                onChange={props.onChange}
                onClick={props.onClick}
                label={props.label}
            />
        );
    };

    const handleStartTimeChange = (date) => {
        setEndTime(moment(date).add((selectedBookingType.duration * 10)/60, 'hours'))
        console.log(moment(date).add((selectedBookingType.duration * 10)/60, 'hours'))
    }

    const handleBookingTypeChange = (pickedBookingType) => {
        if(!pickedBookingType) return
        
        setSelectedBookingType(pickedBookingType)
        console.log(startTime)
        let dateObject = moment(startTime).toDate();
        console.log(dateObject)
        
        //setEndTime(moment(dateObject).add((pickedBookingType.duration * 10)), 'minute')
         setEndTime(moment(dateObject).add((pickedBookingType.duration * 10 * patients.length), 'minutes'))
        //console.log(moment(dateObject).add((pickedBookingType.duration * 10)/60, 'hours'))
    }


    return (
      <Modal show={isOpen} onHide={handleClose} classes={{ container: classes.container, paper: classes.paper }}>
        <form>
          <Modal.Title className={classes.title}>Add Appointment</Modal.Title>
          <Modal.Body>
            <Row>
              <Col style={{ marginTop: 10 }} md={12}>
                Doctor
                <Autocomplete
                  value={selectedDoctor}
                  onChange={(_, newValue) => {

                    setSelectedDoctor(newValue);
                    //setBookingTypeSelection(newValue.booking_type_name.map(x=>{return {label:x.id,value:x}}))
                  }}
                  options={doctorSelection}
                  getOptionLabel={option => (option ? `${option.label}` : '')}
                  size="small"
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </Col>
              <Col md={12}>
                Booking Type
                <Autocomplete
                  value={selectedBookingType}
                  onChange={(_, newValue) => {
                     setSelectedBookingType(newValue)
                    //handleBookingTypeChange(newValue);
                  }}
                  options={bookingTypeSelection}
                  getOptionLabel={option => (option ? `${option.label}` : '')}
                  inputValue={selectedBookingTypeInputValue}
                  onInputChange={(_, newInputValue) => {
                    setSelectedBookingTypeInputValue(newInputValue);
                  }}
                  input
                  size="small"
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </Col>
              <Col style={{ marginTop: 10 }} md={12}>
                Appointment slot
                <Autocomplete
                  value={selectedApSlot}
                  onChange={(_, newValue) => {

                    setSelectedApSlot(newValue);
                    //setBookingTypeSelection(newValue.booking_type_name.map(x=>{return {label:x.id,value:x}}))
                  }}
                  options={apslotSelection}
                  getOptionLabel={option => (option ? `${option.label}` : '')}
                  size="small"
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </Col>
              
              <Col style={{ marginTop: 10 }} md={12}>
                Owner
                <Autocomplete
                  value={selectedOwner}
                  onChange={(_, newValue) => {
                    console.log('ROSEV', newValue);
                    setSelectedOwner(newValue);
                  }}
                  options={ownerSelection}
                  getOptionLabel={option => (option ? `${option.label}` : '')}
                  inputValue={selectedOwnerInputValue}
                  onInputChange={(_, newInputValue) => {
                    console.log('ROSEV', newInputValue);
                    setSelectedOwnerInputValue(newInputValue);
                  }}
                  size="small"
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </Col>

              {patientSelection.length > 0 ? (
                <Col style={{ marginTop: 10 }} md={12}>
                  Patient
                  <Autocomplete
                    value={selectedPatient}
                    onChange={(_, newValue) => {
                      if (newValue == null) return;
                      console.log('ROSEV', newValue);
                      setSelectedPatient(newValue);
                      setPatients([...patients, newValue]);
                      //setEndTime(moment(endTime).add((selectedBookingType?.duration * 10)/60, 'hours'))
                    }}
                    options={patientSelection.filter(pS => !patients.includes(pS))}
                    getOptionLabel={option => (option ? `${option.label}` : '')}
                    inputValue={selectedPatientInputValue}
                    onInputChange={(_, newInputValue) => {
                      setSelectedPatientInputValue(newInputValue);
                    }}
                    size="small"
                    renderInput={params => <TextField {...params} variant="outlined" />}
                  />
                  {patients.length > 0 && (
                    <div className="my-3 pt-2">
                      {patients.map((p, index) => (
                        <div className="my-1">
                          <Row>
                            <Col>
                              <TextField
                                value={p?.label}
                                disabled={true}
                                label={`Pet ${index + 1}`}
                                variant="outlined"
                              />
                            </Col>
                            <Button
                              size="small"
                              onClick={() => {
                                setPatients(patients.filter((_, i) => i !== index));
                                setSelectedPatient('');
                              }}
                              color="secondary"
                              variant="contained"
                            >
                              X
                            </Button>

                            {/* <hr className='p-0 mt-0'></hr> */}
                          </Row>
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              ) : selectedOwner ? (
                <Col style={{ marginTop: 10 }} md={12}>
                  This Owner don't have a pet registered
                </Col>
              ) : null}

{selectedApSlot && (
                <>
                  <Col md={6}>
                    Start time
                    <TimePicker
                      value={startTime}
                      onChange={date => {
                        setStartTime(date);
                        handleStartTimeChange(date);
                      }}
                      clearable
                      TextFieldComponent={renderTimeInput}
                    />
                  </Col>
                  <Col md={6}>
                    End time
                    <TimePicker
                      value={endTime}
                      onChange={date => setEndTime(date)}
                      clearable
                      TextFieldComponent={renderTimeInput}
                      disabled
                    />
                  </Col>

                  <Col xs={12}>
                    <h6>Taken Time</h6>
                    {takenTime.map(t=><h6>{t.label}</h6>)}
                  </Col>
                </>
              )}

              { selectedBookingType?.category_id === operasi?.id &&
              <Col style={{ marginTop: 10 }} md={12}>
                Operation Table Type
                <Autocomplete
                  value={selectedOperationTableType}
                  onChange={(_, newValue) => {
                    setSelectedOperationTableType(newValue);
                  }}
                  options={operationTableSelection}
                  getOptionLabel={option => (option ? `${option.label}` : '')}
                  inputValue={selectedOperationTableInputValue}
                  onInputChange={(_, newInputValue) => {
                    setSelectedOperationTableInputValue(newInputValue);
                  }}
                  size="small"
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              </Col>
              }
              <Col style={{ marginTop: 10 }} md={12}>
                Description
                <TextField
                  multiline={true}
                  rows={3}
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                  variant="outlined"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className={classes.actionContainer}>
            <>
              {role !== 'DOCTOR' && (
                <Button color="primary" variant="contained" onClick={() => submitForm()}>
                  Create Appointment
                </Button>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </>
          </Modal.Footer>
        </form>
      </Modal>
    );
};

export default AddAppointmentDialog;
