import { combineReducers } from 'redux';

import list from './list';
import detail from './detail';
import ops from './ops';
import approve from './approve';
import edit from './edit';

export default combineReducers({
  list,
  detail,
  ops,
  approve,
  edit
});
