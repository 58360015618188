import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { EditHeaderToolbar as HeaderToolbar } from '../../components/common/HeaderToolbar';
import { TextField } from '../../components/common/TextField';
import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import { fetchSymptom } from '../../../redux/symptom/detail';
import { editSymptom, deleteSymptom } from '../../../redux/symptom/ops';

const SymptomEditPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchSymptom(id));
  }, []);

  const { data } = useSelector(state => state.symptom.detail);

  const { completed } = useSelector(state => state.symptom.ops);
  const isFetching = useSelector(state => state.loading.symptom);
  useEffect(() => {
    if (completed) {
      setIsEditing(false);
    }
  }, [completed]);

  const [isEditing, setIsEditing] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.symptom_name || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(editSymptom(id, values));
    },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Edit Symptom">
        <HeaderToolbar
          isEditing={isEditing}
          handleEdit={() => setIsEditing(true)}
          handleCancel={() => {
            resetForm();
            setIsEditing(false);
          }}
        />
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Symptom Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <ActionBar
              isEditing={isEditing}
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleDelete={() => dispatch(deleteSymptom(id))}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default SymptomEditPage;
