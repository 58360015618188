import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'appointmentType.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'appointmentType.ops/ADD_SUCCESS';
export const ADD_FAILED = 'appointmentType.ops/ADD_FAILED';
export const EDIT_BEGIN = 'appointmentType.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'appointmentType.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'appointmentType.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'appointmentType.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'appointmentType.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'appointmentType.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addAppointmentType = data => async dispatch => {
  dispatch(addBegin());

  const formattedData = {
    booking_type_name: data.name,
    duration: data.duration,
    payment_proof_required: data.isPaymentProofRequired,
    category_id: data.category_id,
  };

  try {
    const res = await api.post('add-booking-type', formattedData);

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

// const editBegin = () => ({ type: EDIT_BEGIN });
// const editSuccess = () => ({ type: EDIT_SUCCESS });
// const editFailed = error => ({ type: EDIT_FAILED, payload: error });
// export const editAnimalType = (id, data) => async dispatch => {
//   dispatch(editBegin());

//   const formattedData = {
//     id,
//     animal_category: data.category,
//     animal_name: data.name
//   };

//   try {
//     const res = await api.post('update-animal-type', formattedData);

//     dispatch(editSuccess());
//     dispatch(setSuccessNotification('Successfully edited data'));
//   } catch (error) {
//     dispatch(editFailed(error.toString()));
//     dispatch(setErrorNotification(error.toString()));
//   }
// };

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deleteAppointmentType = appointmentType => async dispatch => {
  dispatch(deleteBegin());

  const data = {
    booking_type_name: appointmentType
  };

  try {
    const res = await api.post(`delete-booking-type`, data);

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case EDIT_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, isFetching: true, error: null };

    case ADD_SUCCESS:
    case EDIT_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true, isFetching: false };

    case ADD_FAILED:
    case EDIT_FAILED:
    case DELETE_FAILED:
      return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isFetching: false,
  error: null
};
