import React, { useEffect, useCallback, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { CardMembership } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Grid, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import html2canvas from "html2canvas";

import patientNoImage from "../../../../assets/no_image_pet.jpeg"

const PatientCard = (props) => {


    let { patientData, showDialog } = props;
    console.log('isi patientData', patientData)
    const handleGenerate = async () => {
        const element = document.getElementById('print-card'),
            canvas = await html2canvas(element, { useCORS: true }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        link.href = data;
        link.download = `${patientData && patientData.patient_name}-card.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const handleClose = () => {
        props.toogleSelected(false)
    };

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'relative',
                        left: '190px'
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };



    return (
        <>
            <BootstrapDialog open={showDialog}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Generate Patient Card : {patientData && patientData.patient_name} <Button onClick={handleGenerate} sx={{
                        position: 'relative',
                        left: '50%'
                    }} variant="contained"
                        size="small"
                    >
                        Export
                    </Button>
                </BootstrapDialogTitle>
                <DialogContent sx={{
                    padding: '0px !important'
                }} dividers>
                    <div id="print-card" style={{
                        width: '550px',
                        height: '300px',
                        padding: '0px',
                        border: '1px solid #EF872F',
                        borderRadius: '10px',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            width: '200px',
                            height: '-webkit-fill-available',
                            padding: '10px',
                            borderRadius: '10px 0 0 10px',
                            display : "flex",
                            alignItems : "center",
                            justifyContent : "center",
                            flexDirection : "column"
                        }} className='bio1'>
                            <div>
                                {/*{(!patientData || !patientData.picture || patientData.picture == 'No Attachment').toString()}*/}
                                {
                                    (!patientData || !patientData.picture || patientData.picture == 'No Attachment') ? <img
                                        style={{
                                            borderRadius: '100%',
                                            width: '150px',
                                            height: '150px'
                                        }}
                                        src={patientNoImage}
                                        alt="patient"
                                    /> : <img
                                        style={{
                                            borderRadius: '100%',
                                            width: '150px',
                                            height: '150px',
                                            objectFit : "contain"
                                        }}
                                        src={patientData.picture.includes("patient_attachment-") ? `${process.env.REACT_APP_NEO_USER_API_PROD_CONFIG}/api/image?image_name=${patientData?.picture}` : patientData.picture}
                                        alt="patient"
                                    />
                                }
                            </div>
                            <img
                                style={{
                                    marginTop : 10
                                }}
                                className="logo-default max-h-60px"
                                alt="Logo"
                                src={toAbsoluteUrl('/media/card/logo.png')}
                            />

                        </div>
                        <div style={{
                            backgroundColor: 'rgb(239, 135, 47)',
                            width: 'inherit',
                            height: '-webkit-fill-available',
                            borderRadius: '0px 10px 10px 0px',
                        }} className='bio2'>
                            <div style={{
                                padding: '20px',
                                textAlign: 'left',
                                color: 'white',
                                fontWeight: 600
                            }}>
                                <p style={{
                                    fontSize: '2em',
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>{patientData && patientData.patient_name}</p>
                                <div style={{ border: '1px solid #fff' }}></div>

                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>Owner</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.pet_owner_name}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>Animal Name</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.animal_name}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>Gender</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.gender}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>No.Tatto</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.no_tatto}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>No.Microchip</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.no_microchip}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>DNA</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.dna}</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <div style={{ flex: 1 }}>Breed</div>
                                    <div style={{
                                        alignContent: 'flex-end',
                                        flex: 1
                                    }}>{patientData && patientData.breed}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </>
    )
}
export default PatientCard
