import axios from '../../api/userAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'appointmentType.listByDoctor/FETCH_BEGIN';
export const FETCH_SUCCESS = 'appointmentType.listByDoctor/FETCH_SUCCESS';
export const FETCH_FAILED = 'appointmentType.listByDoctor/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchAppointmentTypesByDoctor = doctorID => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios(
      `retrieve-booking-type-by-doctor-id?doctor_id=${doctorID}`
    );

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const initialState = {
  data: [],
  isFetching: false,
  error: null
};

const appointmentTypeListReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: [], isFetching: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isFetching: false };
    case FETCH_FAILED:
      return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default appointmentTypeListReducer;
