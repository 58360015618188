import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {
  Button,
  Grid,
  IconButton
} from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
  Done as DoneIcon,
  Link as LinkIcon
} from '@material-ui/icons';
import {parseISO} from 'date-fns';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../../_metronic/_partials/controls';
import LoadingCard from 'app/components/common/LoadingCard';
import ButtonGroupMini from 'app/components/common/ButtonGroupMini';
import Dialog from 'app/components/common/Dialog';
import AppointmentForm from './components/AppointmentForm';
import CustomerForm from 'app/pages/customer/components/CustomerForm';
import MedicalRecordForm from 'app/pages/medicalRecord/components/MedicalRecordForm';
import MedicalRecordListCard from 'app/pages/patient/components/MedicalRecordListCard';

import {fetchAppointment} from '../../../redux/appointment/detail';
import {fetchPatient} from 'redux/patient/detail';
import {fetchCustomerList} from '../../../redux/customer/list';
import {fetchDoctorList} from '../../../redux/doctor/list';
import {fetchAnimalTypeList} from 'redux/animalType/list';
import {deleteAppointment} from '../../../redux/appointment/ops';
import {fetchMedicalRecordListByAppointmentID} from 'redux/medicalRecord/list';
import {fetchCustomer} from 'redux/customer/detail';
import PatientForm from '../patient/components/PatientForm';
import AppointmentModel from "../../../model/AppointmentModel";
import ReminderListCard from '../reminder/ReminderListCard';
import ChangeDoctorDialog from "./components/ChangeDoctorDialog";
import AddAppointmentSlotDialogV2 from "../doctor/components/AddAppointmentSlotDialogV2";
import MedicalRecordListCardForAppointmentUser from "../patient/components/MedicalRecordListCardForAppointmentUser";

const AppointmentSummaryPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {id} = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [isChangeDoctorDialogOpen, setChangeDoctorDialogOpen] = useState(false)

  const {role} = useSelector(state => state.auth);
  const {data, isLoading} = useSelector(state => state.appointment.detail);
  const {isLoading: isLoadingDoctorList} = useSelector(
    state => state.doctor.list
  );

  const {isLoading: isLoadingEdit, completed} = useSelector(
    state => state.appointment.edit
  );

  const fetchAppointmentForDoctor = async () => {
    try {
      let res = await AppointmentModel.getAppointmentDetail(id)

      console.log("ADETAIL", res)

      dispatch(({
        type: 'appointment.detail/FETCH_SUCCESS',
        payload: {
          id: res.id,
          start_time: res.start_time,
          end_time: res.end_time,
          created_at: res.created_at,
          expire_at: res.expire_at,
          clinic: res.clinic,
          operation_table: res.operation_table,
          owner: res.owner,
          description: res.description,
          "additional_storage": res.description,
          doctor_id: res.doctor.id,
          doctor_name: res.doctor.doctor_name,
          co_doctor_id: null,
          co_doctor_name: null,
          patient_id: res.patients[0].id,
          patient_name: res.patients[0].patient_name,
          patients: res.patients,
          status: res.status,
          customer_id: res.owner.id,
          customer_name: res.owner.user_name,
          customer_mobile: res.owner.mobile,
          customer_email: res.owner.email,
          booking_type_name: res.bookingType.booking_type_name,
          duration: res.bookingType.duration,
          payment_status: res.payment_status
        }
      }))

    } catch (e) {
      console.log('error', e)
    }
  }


  useEffect(() => {
    role === 'DOCTOR' ? fetchAppointmentForDoctor() : dispatch(fetchAppointment(id));
    dispatch(fetchDoctorList());
    dispatch(fetchMedicalRecordListByAppointmentID(id));
  }, []);

  useEffect(() => {
    if (!isLoadingEdit && completed) {
      setIsEditing(false);
      dispatch(fetchAppointment(id));
    }
  }, [isLoadingEdit, completed]);

  const {data: patientData} = useSelector(state => state.patient.detail);
  useEffect(() => {
    data?.patient_id && dispatch(fetchPatient(data.patient_id));
  }, [data]);

  const handlePrintClick = () => {
    localStorage.setItem('print-appointment', JSON.stringify(data));
    localStorage.setItem(
      'print-appointment-patient',
      JSON.stringify(patientData)
    );
    window.open('/print/appointment');
  };

  const [
    isDeleteConfirmationDialogOpen,
    setIsDeleteConfirmationDialogOpen
  ] = useState(false);

  const handleDeleteClick = () => {
    dispatch(deleteAppointment(id));
  };

  const renderToolbar = () => {
    let html = (
      <>
        <ButtonGroupMini
          buttonList={[
            role !== 'DOCTOR'
            && {
              handleClick: () => {
                setChangeDoctorDialogOpen(true)
              },
              text: 'Change Doctor',
              color: 'primary',
              variant: 'contained'
            },
          ]}
        />
        <ButtonGroupMini
          buttonList={[
            role !== 'DOCTOR'
            && {
              handleClick: () => {
                history.push({
                  pathname: '/appointment/change/' + data.id,
                });
              },
              text: 'Change Owner / Patient',
              color: 'primary',
              variant: 'contained'
            },
          ]}
        />
        <ButtonGroupMini
          buttonList={[
            role !== 'DOCTOR'
            && {
              handleClick: () => {
                history.push({
                  pathname: '/appointment/edit/' + data.id,
                });
              },
              text: 'Edit Appointment',
              color: 'primary',
              variant: 'contained'
            },
            ...(data?.status !== 'DONE' && (data?.payment_status !== 'PAID' || data?.payment_status !== 'PAID_CONFIRMED')
              ? [
                {
                  handleClick: () => {
                    history.push({
                      pathname: '/medicalRecord/add/' + data.id,
                    });
                  },
                  text: 'Mark as done',
                  icon: DoneIcon
                }
              ]
              : []),
            ...(role !== 'DOCTOR'
              ? [
                {
                  handleClick: () => {
                    setIsDeleteConfirmationDialogOpen(true);
                  },
                  text: 'Cancel appointment',
                  icon: DeleteOutlineIcon
                }
              ]
              : [])
          ]}
        />
      </>
    );

    if (isEditing) {
      html = <Button onClick={() => setIsEditing(false)}>Cancel</Button>;
    }

    return <CardHeaderToolbar>{html}</CardHeaderToolbar>;
  };

  if (isLoading || isLoadingDoctorList) return <LoadingCard/>;

  console.log("datala", data)

  return (
    <>
      <AppointmentForm
        data={data}
        isEditing={isEditing}
        headerToolbar={renderToolbar()}
      />
      {data && (
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md>
            <Card>
              <Button onClick={handlePrintClick} size="large" fullWidth>
                Print Appointment
              </Button>
            </Card>
          </Grid>
        </Grid>
      )}
      <CustomerDetailCard customerID={data?.customer_id}/>
      {data?.patients && data?.patients.map((patient) => (<PatientDetailCard patientID={data?.patient_id}/>))}
      {(data?.payment_status === 'PAID' || data?.payment_status === 'PAID_CONFIRMED') && (
        <MedicalRecordListCardForAppointmentUser patientID={data?.patient_id}/>
      )}
      {data?.status === 'DONE' && (data?.payment_status === 'PAID' || data?.payment_status === 'PAID_CONFIRMED')
        && <ReminderListCard appointmentId={id}/>}
      <Dialog
        open={isDeleteConfirmationDialogOpen}
        handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
        title="Cancel appointment?"
        content="This action cannot be undone!"
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteClick}
          >
            Cancel Appointment
          </Button>
        }
      />
      <ChangeDoctorDialog
        isOpen={isChangeDoctorDialogOpen}
        handleClose={(refresh) => {
          setChangeDoctorDialogOpen(false);
          if (refresh) {
            dispatch(fetchAppointment(id));
          }
        }}
        doctorId={data?.doctor_id}
        doctor={{
          value : data?.doctor_id,
          label : data?.doctor_name
        }}
        appointmentId={id}
        bookingType={data?.booking_type_name}
      />
    </>
  );
};

export default AppointmentSummaryPage;

const CustomerDetailCard = ({customerID}) => {
  const dispatch = useDispatch();

  const {data, isLoading} = useSelector(state => state.customer.detail);

  useEffect(() => {
    if (customerID) dispatch(fetchCustomer(customerID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID]);

  const formik = {
    values: {
      name: data?.user_name || '',
      email: data?.email || '',
      phone: data?.mobile || '',
      dateOfBirth: data?.birthdate ? parseISO(data?.birthdate) : null,
      address: data?.address || ''
    }
  };

  if (isLoading) {
    return <LoadingCard/>;
  }

  return (
    <Card>
      <CardHeader title="Customer Details">
        <CardHeaderToolbar>
          <Link to={`/customer/${customerID}`}>
            <IconButton size="small" color="primary">
              <LinkIcon/>
            </IconButton>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomerForm formik={formik} isEditing={false}/>
      </CardBody>
    </Card>
  );
};

const PatientDetailCard = ({patientID}) => {
  const dispatch = useDispatch();

  const {data, isLoading} = useSelector(state => state.patient.detail);
  const {data: customerData} = useSelector(state => state.customer.list);
  const {data: animalTypeData} = useSelector(state => state.animalType.list);

  useEffect(() => {
    dispatch(fetchPatient(patientID));
    dispatch(fetchCustomerList());
    dispatch(fetchAnimalTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID]);

  const formik = {
    values: {
      name: data?.patient_name || '',
      animalType: data?.animal_type_id || '',
      breed: data?.breed || '',
      owner: data?.pet_owner_id || '',
      age: data?.age || 0,
      ageMonth: data?.age_month % 12 || 0,
      ageString: data?.age_string || '',
      gender: data?.gender || '',
      picture: data?.picture || null
    }
  };

  if (isLoading) {
    return <LoadingCard/>;
  }

  return (
    <Card>
      <CardHeader title="Patient Details">
        <CardHeaderToolbar>
          <Link to={`/patient/${patientID}`}>
            <IconButton size="small" color="primary">
              <LinkIcon/>
            </IconButton>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PatientForm
          formik={formik}
          isEditing={false}
          ownerList={customerData}
          animalTypeList={animalTypeData}
        />
      </CardBody>
    </Card>
  );
};

