import React from 'react';
import { Button } from '@material-ui/core';

import { CardHeaderToolbar } from "../../../../_metronic/_partials/controls";

const HeaderToolbarEdit = ({
  isEditing,
  handleEdit,
  handleCancel,
}) => {
  return (
    <CardHeaderToolbar>
      {
        !isEditing ?
          <Button onClick={handleEdit} color='primary' size='large'>Edit</Button> :
          <Button onClick={handleCancel} size='large'>Cancel</Button>
      }
    </CardHeaderToolbar>
  )
}

export default HeaderToolbarEdit;