export const SET_PAGE = 'DataTable/SET_PAGE';
export const SET_SIZE_PER_PAGE = 'DataTable/SET_SIZE_PER_PAGE';

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch(type) {
    case SET_PAGE:
      return { ...state, page: payload };
    case SET_SIZE_PER_PAGE:
      return { ...state, sizePerPage: payload };
    
    default:
      return state;
  }
}

export const SIZE_PER_PAGE_LIST = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
]

export const initialState = {
  page: 1,
  sizePerPage: SIZE_PER_PAGE_LIST[0].value,
}

