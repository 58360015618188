/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography
} from '@material-ui/core';
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks';

export function UserProfileDropdown() {
  const history = useHistory();

  const { user, role } = useSelector(state => state.auth);

  const popupState = usePopupState({
    variant: 'popover'
  });

  return (
    <Grid container item alignItems="center">
      <Typography
        variant="h6"
        style={{ color: '#ffffff', marginRight: '0.5rem' }}
      >
        {user}
      </Typography>
      <IconButton {...bindTrigger(popupState)}>
        <Avatar>{(user?.[0] || '').toUpperCase()}</Avatar>
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableScrollLock={true}
      >
        <Paper>
          <MenuList>
            <MenuItem
              onClick={() => {
                history.push('/reset-password');
              }}
            >
              Reset Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </Grid>
  );
}
