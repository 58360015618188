import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-disabled ': {
      color: theme.palette.text.primary
    },
    '& .Mui-disabled fieldset': {
      borderColor: 'transparent !important'
    },
    '& .Mui-disabled .MuiSelect-icon': {
      display: 'none'
    },
    '& .Mui-disabled .MuiInputAdornment-root': {
      display: 'none'
    },
    '& .Mui-disabled .MuiAutocomplete-endAdornment': {
      display: 'none'
    },
    '& .Mui-disabled.MuiFormHelperText-root': {
      display: 'none'
    },
    '& .MuiInputBase-marginDense.MuiOutlinedInput-adornedEnd': {
      paddingRight: '0'
    }
  }
}));

const CustomTextField = ({ ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      fullWidth
      variant="outlined"
      size="small"
      className={classes.root}
    />
  );
};

export default CustomTextField;
