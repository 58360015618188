import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

const EditColumn = (cell, row, rowIndex, { editLink, renderCustomActions }) => {
  return (
    <>
      {renderCustomActions && renderCustomActions(row)}
      {
        row ? <Link to={`${editLink}${row.id}`}>
          <IconButton size="small">
            <EditIcon />
          </IconButton>
        </Link> : ''
      }
     
    </>
  );
};

export default EditColumn;
