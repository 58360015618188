import api from '../../api/userAPI';
import apiNeo from '../../api/userAPINEO'
import { setErrorNotification, setSuccessNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'appointmentType.detail/FETCH_BEGIN';
export const FETCH_SUCCESS = 'appointmentType.detail/FETCH_SUCCESS';
export const FETCH_FAILED = 'appointmentType.detail/FETCH_FAILED';
export const EDIT_BEGIN = 'appointmentType.detail/EDIT_BEGIN';
export const EDIT_SUCCESS = 'appointmentType.detail/EDIT_SUCCESS';
export const EDIT_FAILED = 'appointmentType.detail/EDIT_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchAppointmentType = id => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api.get(`retrieve-booking-types?id=${id}`);

    console.log(res);

    dispatch(fetchSuccess(res.data.result[0]));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const fetchAppointmentTypev2 = id => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api.get(`retrieve-booking-types?id=${id}`);


    dispatch(fetchSuccess(res.data.result.find(x=>x.id == id)));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const edit = (id,values) => async dispatch => {
  dispatch(editBegin());

  try {
    
    const res = await apiNeo.put(`v1/bookingtype/${id}`, values);

    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully edited data'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, data: null, isFetching: true, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isFetching: false };
    case FETCH_FAILED:
      return { ...state, isFetching: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: null,
  isFetching: false,
  error: null
};
