import api from '../../api/diagnosisAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'disease.detail/FETCH_BEGIN';
export const FETCH_SUCCESS = 'disease.detail/FETCH_SUCCESS';
export const FETCH_FAILED = 'disease.detail/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchDisease = id => async (dispatch, getState) => {
  dispatch(fetchBegin());

  try {
    const res = await api.get(`retrieve-disease?disease_id=${id}`);

    if (res.data.result.length === 0) throw new Error('Disease not found');

    let medicine_list = [];
    if (Array.isArray(JSON.parse(res.data.result[0].medicine_array))) {
      medicine_list = JSON.parse(res.data.result[0].medicine_array);
    } else {
      medicine_list = [JSON.parse(res.data.result[0].medicine_array)];
    }

    const formattedResult = {
      id: res.data.result[0].disease_id,
      disease_name: res.data.result[0].disease_name,
      animal_type_id: res.data.result[0].animal_type_id,
      symptom_location_list: [],
      medicine_list
    };

    console.log(formattedResult);

    for (let curr of res.data.result) {
      formattedResult.symptom_location_list.push({
        symptomId: curr.symptom_id,
        symptomName: curr.symptom_name,
        locationId: curr.anatomy_id,
        locationName: curr.part_name
      });
    }

    dispatch(fetchSuccess(formattedResult));
  } catch (error) {
    console.log('oever ere');
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload };
    case FETCH_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: null,
  error: null
};
