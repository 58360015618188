import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const EDIT_BEGIN = 'medicalRecord.edit/EDIT_BEGIN';
export const EDIT_SUCCESS = 'medicalRecord.edit/EDIT_SUCCESS';
export const EDIT_FAILED = 'medicalRecord.edit/EDIT_FAILED';

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editMedicalRecord = (id, data) => async dispatch => {
  dispatch(editBegin());

  const formData = new FormData();
  formData.append('id', id);
  formData.append('appointment_id', data.appointmentID);
  formData.append('description', data.description);
  formData.append('medication', data.medication);
  formData.append('mc_attachment', data.attachment);

  try {
    await api({
      url: 'update-medical-record',
      method: 'POST',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully edited data'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case EDIT_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case EDIT_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
