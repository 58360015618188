import ApiRequest from '../../utils/APIRequest';

export default class MedicalRecord {
  submitMedicalRecord = async (newMedicalRecord) => {
    return await ApiRequest.set('v1/medical-record', 'POST', newMedicalRecord);
  };

  getMedicalRecordByAppointmentUserId = async (userId) => {
    return await ApiRequest.set('v1/medical-records/appointment-user/'+userId, 'GET');
  };

  getMedicalRecordByPatientId = async (pid) => {
    return await ApiRequest.set('v1/medical-records/patient/'+pid, 'GET');
  };

  getMedicalRecordByUserIdForDoctor = async (userId) => {
    return await ApiRequest.set('v1/medical-records/appointment-user/'+userId, 'POST', {});
  };

  getMedicalRecordById = async (medicalRecordId) => {
    return await ApiRequest.set('v1/medical-record/'+medicalRecordId, 'GET');
  };

  edit = async (medicalRecordId, medicalRecordData) => {
    return await ApiRequest.set('v1/medical-record/'+medicalRecordId, 'PUT', medicalRecordData);
  };
}
