import { combineReducers } from 'redux';

import detail from './detail';
import edit from './edit';
import list from './list';
import ops from './ops';

export default combineReducers({
  edit,
  detail,
  list,
  ops
});
