import api from '../../api/diagnosisAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'symptom.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'symptom.ops/ADD_SUCCESS';
export const ADD_FAILED = 'symptom.ops/ADD_FAILED';
export const EDIT_BEGIN = 'symptom.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'symptom.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'symptom.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'symptom.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'symptom.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'symptom.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addSymptom = data => async dispatch => {
  dispatch(addBegin());

  const formattedData = {
    symptom_name: data.name
  };

  try {
    const res = await api.post('add-symptom', formattedData);
    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editSymptom = (id, data) => async dispatch => {
  dispatch(editBegin());

  const formattedData = {
    id,
    symptom_name: data.name
  };

  try {
    const res = await api.post('update-symptom', formattedData);
    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully edited data'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deleteSymptom = id => async dispatch => {
  dispatch(deleteBegin());

  const data = {
    id
  };

  try {
    const res = await api.delete(`delete-symptom`, { data });

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case EDIT_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, error: null };

    case ADD_SUCCESS:
    case EDIT_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true };

    case ADD_FAILED:
    case EDIT_FAILED:
    case DELETE_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
};

const initialState = {
  completed: false,
  error: null
};
