import { makeStyles } from '@material-ui/core/styles';

export const CELL_HEIGHT = '7rem';

export const useStyles = makeStyles(theme => ({
  table: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr repeat(24, 0.2fr)',

    '& > div': {
      position: 'relative',
      border: `1px solid ${theme.palette.divider}`,
      height: CELL_HEIGHT
    }
  },
  hourMarkContainer: {
    marginTop: `calc(-${CELL_HEIGHT} / 4)`,
    marginRight: '0.5rem',
    backgroundColor: theme.palette.common.white,
    textAlign: 'end'
  },
  hourMark: {
    backgroundColor: 'white'
  },
  tableDate: {
    fontSize: '0.8rem'
  },
  // unavailable: {
  //   backgroundColor: theme.palette.divider
  // },
  event: {
    position: 'absolute',
    padding: '0.25rem',
    width: '95%',
    color: theme.palette.primary.contrastText,
    borderRadius: '0.25rem',
    zIndex: 2,
    boxShadow: '0 2px 4px 0 #00000040',
    overflow: 'hidden'
  },
  eventUnDone : {
    backgroundColor: theme.palette.primary.light
  },
  eventDone : {
    backgroundColor: theme.palette.success.light
  },
  eventWaiting : {
    backgroundColor: "#e2c1a4"
  },
  eventBan : {
    backgroundColor: "#ffffff",
    color : "#ffffff"
  },
  eventFaded: {
    backgroundColor:"#e0e0e0",
    color: theme.palette.text.primary
  },
  eventClickable: {
    '&:hover': {
      cursor: 'pointer'
    },
    '&:focus': {
      outline: `1px solid ${theme.palette.common.black}`
    }
  },
  noHorizontalBorder: {
    borderLeft: 'transparent !important',
    borderRight: 'transarent !important'
  },
  noTopBorder: {
    borderTop: 'transparent !important'
  },
  noTopLeftBorder: {
    borderTop: 'transparent !important',
    borderLeft: 'transparent !important'
  },
  eventTitle: {
    fontSize: '0.9rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  eventTime: {
    fontSize: '0.7rem'
  }
}));
