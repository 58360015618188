import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import crypto from 'crypto';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import {
  TextField,
  PasswordTextField
} from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { addCustomer } from '../../../redux/customer/ops';

const CustomerAddPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { completed } = useSelector(state => state.customer.ops);
  const isFetching = useSelector(state => state.loading.customer);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      password: '',
      email: '',
      phone: '',
      dateOfBirth: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      password: Yup.string().required('Cannot be empty'),
      email: Yup.string(),
      phone: Yup.string().required('Cannot be empty'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable()
    }),
    onSubmit: values => {
      const formattedValues = {
        ...values,
        password: crypto
          .createHash('sha256')
          .update(values.password)
          .digest('hex'),
        dateOfBirth:
          values.dateOfBirth && format(values.dateOfBirth, 'yyyy-MM-dd')
      };

      dispatch(addCustomer(formattedValues));
    }
  });

  return (
    <Card>
      <CardHeader title="Customer details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Full Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                id="password"
                value={values.password}
                onChange={handleChange}
                label="Password *"
                helperText={
                  touched.password && errors.password ? errors.password : ''
                }
                error={touched.password && errors.password ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                label="Email"
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && errors.email ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phone"
                value={values.phone}
                onChange={handleChange}
                label="Phone"
                helperText={touched.phone && errors.phone ? errors.phone : ''}
                error={touched.phone && errors.phone ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth"
                helperText={
                  touched.dateOfBirth && errors.dateOfBirth
                    ? 'Invalid date'
                    : ''
                }
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
              />
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default CustomerAddPage;
