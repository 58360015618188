import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { addMedicine } from '../../../redux/medicine/ops';

export default function MedicineAddPage() {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { completed } = useSelector(state => state.medicine.ops);
  const isFetching = useSelector(state => state.loading.medicine);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      sideEffect: '',
      dosage: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(addMedicine(values));
    }
  });

  return (
    <Card>
      <CardHeader title="Add Symptom"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Medicine Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="sideEffect"
                value={values.sideEffect}
                onChange={handleChange}
                label="Side Effects"
                helperText={
                  touched.sideEffect && errors.sideEffect
                    ? errors.sideEffect
                    : ''
                }
                error={touched.sideEffect && errors.sideEffect ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="dosage"
                value={values.dosage}
                onChange={handleChange}
                label="Dosage Info"
                helperText={
                  touched.dosage && errors.dosage ? errors.dosage : ''
                }
                error={touched.dosage && errors.dosage ? true : false}
                multiline
                rows={4}
              />
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
}
