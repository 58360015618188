import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../../components/common/DataTable';
import LoadingCard from 'app/components/common/LoadingCard/LoadingCard';

import { fetchMedicalRecordListByPatientID } from 'redux/medicalRecord/list';
import MedicalRecord from 'app/models/MedicalRecord';

const MedicalRecordListCard = ({ patientID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {id} = useParams()
  const { role } = useSelector(state => state.auth)

  const [medicalRecordList, setMedicalRecordList] = useState([])

  const getMedicalRecordList = async () => {
    let medicalRecordModel = new MedicalRecord()

    try{
      let result = await medicalRecordModel.getMedicalRecordByPatientId(parseInt(id))
      console.log("medical record", result)
      setMedicalRecordList(result)
    }catch(e){
      console.log('error: ',e)
    }
  }

  const handleNewMedicalRecord = () => {
    history.push({
      pathname: '/medicalRecord/add/'+id,
    });
  };

  useEffect(() => {
    dispatch(fetchMedicalRecordListByPatientID(patientID));
    getMedicalRecordList()
  }, []);

  const { data, isLoading } = useSelector(state => state.medicalRecord.list);

  const columns = [
    {
      dataField: 'date_created',
      text: 'Created Date',
      style: {
        width: '90px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && format(parseISO(cell), 'dd MMMM yyyy');
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{whiteSpace : "pre-line"}}>{cell}</p>
       },
    },
    {
      dataField: 'medication',
      text: 'Medication',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
       return cell && <p style={{whiteSpace : "pre-line"}}>{cell}</p>
      }
    },
    {
      dataField: 'doctor',
      text: 'Doctor name',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{whiteSpace : "pre-line"}}>{cell.doctor_name}</p>
       },
    },
  ];

  if (isLoading) {
    <LoadingCard />;
  }

  return (
    <Card>
      <CardHeader
        title="Medical record list 1"
        toolbar={
          <Grid item className="my-6">
            <Button color="primary" variant="contained" onClick={handleNewMedicalRecord}>
              New Medical Record
            </Button>
          </Grid>
        }
      />
      <CardBody>
        <DataTable
          data={medicalRecordList}
          loading={false}
          totalDataCount={medicalRecordList.length}
          columns={columns}
          editLink="/medicalRecord/edit/"
        />
      </CardBody>
    </Card>
  );
};

export default MedicalRecordListCard;
