// appointment
import {
  FETCH_BEGIN as APPOINTMENT_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as APPOINTMENT_LIST_FETCH_SUCCESS,
  FETCH_FAILED as APPOINTMENT_LIST_FETCH_FAILED
} from '../appointment/list';
// import {
//   FETCH_BEGIN as APPOINTMENT_DETAIL_FETCH_BEGIN,
//   FETCH_SUCCESS as APPOINTMENT_DETAIL_FETCH_SUCCESS,
//   FETCH_FAILED as APPOINTMENT_DETAIL_FETCH_FAILED
// } from '../appointment/detail';
import {
  ADD_BEGIN as APPOINTMENT_ADD_BEGIN,
  ADD_SUCCESS as APPOINTMENT_ADD_SUCCESS,
  ADD_FAILED as APPOINTMENT_ADD_FAILED,
  EDIT_BEGIN as APPOINTMENT_EDIT_BEGIN,
  EDIT_SUCCESS as APPOINTMENT_EDIT_SUCCESS,
  EDIT_FAILED as APPOINTMENT_EDIT_FAILED,
  DELETE_BEGIN as APPOINTMENT_DELETE_BEGIN,
  DELETE_SUCCESS as APPOINTMENT_DELETE_SUCCESS,
  DELETE_FAILED as APPOINTMENT_DELETE_FAILED
} from '../appointment/ops';

// customer
import {
  FETCH_BEGIN as CUSTOMER_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as CUSTOMER_LIST_FETCH_SUCCESS,
  FETCH_FAILED as CUSTOMER_LIST_FETCH_FAILED
} from '../customer/list';
import {
  FETCH_BEGIN as CUSTOMER_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as CUSTOMER_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as CUSTOMER_DETAIL_FETCH_FAILED
} from '../customer/detail';
import {
  ADD_BEGIN as CUSTOMER_ADD_BEGIN,
  ADD_SUCCESS as CUSTOMER_ADD_SUCCESS,
  ADD_FAILED as CUSTOMER_ADD_FAILED,
  EDIT_BEGIN as CUSTOMER_EDIT_BEGIN,
  EDIT_SUCCESS as CUSTOMER_EDIT_SUCCESS,
  EDIT_FAILED as CUSTOMER_EDIT_FAILED,
  DELETE_BEGIN as CUSTOMER_DELETE_BEGIN,
  DELETE_SUCCESS as CUSTOMER_DELETE_SUCCESS,
  DELETE_FAILED as CUSTOMER_DELETE_FAILED
} from '../customer/ops';

// patient
import {
  FETCH_BEGIN as PATIENT_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as PATIENT_LIST_FETCH_SUCCESS,
  FETCH_FAILED as PATIENT_LIST_FETCH_FAILED
} from '../patient/list';
import {
  FETCH_BEGIN as PATIENT_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as PATIENT_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as PATIENT_DETAIL_FETCH_FAILED
} from '../patient/detail';
import {
  ADD_BEGIN as PATIENT_ADD_BEGIN,
  ADD_SUCCESS as PATIENT_ADD_SUCCESS,
  ADD_FAILED as PATIENT_ADD_FAILED,
  EDIT_BEGIN as PATIENT_EDIT_BEGIN,
  EDIT_SUCCESS as PATIENT_EDIT_SUCCESS,
  EDIT_FAILED as PATIENT_EDIT_FAILED,
  DELETE_BEGIN as PATIENT_DELETE_BEGIN,
  DELETE_SUCCESS as PATIENT_DELETE_SUCCESS,
  DELETE_FAILED as PATIENT_DELETE_FAILED
} from '../patient/ops';

// diagnose
import {
  DIAGNOSE_BEGIN,
  DIAGNOSE_SUCCESS,
  DIAGNOSE_FAILED
} from '../diagnosis';

// disease
import {
  FETCH_BEGIN as DISEASE_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as DISEASE_LIST_FETCH_SUCCESS,
  FETCH_FAILED as DISEASE_LIST_FETCH_FAILED
} from '../disease/list';
import {
  FETCH_BEGIN as DISEASE_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as DISEASE_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as DISEASE_DETAIL_FETCH_FAILED
} from '../disease/detail';
import {
  ADD_BEGIN as DISEASE_ADD_BEGIN,
  ADD_SUCCESS as DISEASE_ADD_SUCCESS,
  ADD_FAILED as DISEASE_ADD_FAILED,
  EDIT_BEGIN as DISEASE_EDIT_BEGIN,
  EDIT_SUCCESS as DISEASE_EDIT_SUCCESS,
  EDIT_FAILED as DISEASE_EDIT_FAILED,
  DELETE_BEGIN as DISEASE_DELETE_BEGIN,
  DELETE_SUCCESS as DISEASE_DELETE_SUCCESS,
  DELETE_FAILED as DISEASE_DELETE_FAILED
} from '../disease/ops';

// symptom
import {
  FETCH_BEGIN as SYMPTOM_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as SYMPTOM_LIST_FETCH_SUCCESS,
  FETCH_FAILED as SYMPTOM_LIST_FETCH_FAILED
} from '../symptom/list';
import {
  FETCH_BEGIN as SYMPTOM_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as SYMPTOM_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as SYMPTOM_DETAIL_FETCH_FAILED
} from '../symptom/detail';
import {
  ADD_BEGIN as SYMPTOM_ADD_BEGIN,
  ADD_SUCCESS as SYMPTOM_ADD_SUCCESS,
  ADD_FAILED as SYMPTOM_ADD_FAILED,
  EDIT_BEGIN as SYMPTOM_EDIT_BEGIN,
  EDIT_SUCCESS as SYMPTOM_EDIT_SUCCESS,
  EDIT_FAILED as SYMPTOM_EDIT_FAILED,
  DELETE_BEGIN as SYMPTOM_DELETE_BEGIN,
  DELETE_SUCCESS as SYMPTOM_DELETE_SUCCESS,
  DELETE_FAILED as SYMPTOM_DELETE_FAILED
} from '../symptom/ops';

// medicine
import {
  FETCH_BEGIN as MEDICINE_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as MEDICINE_LIST_FETCH_SUCCESS,
  FETCH_FAILED as MEDICINE_LIST_FETCH_FAILED
} from '../medicine/list';
import {
  FETCH_BEGIN as MEDICINE_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as MEDICINE_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as MEDICINE_DETAIL_FETCH_FAILED
} from '../medicine/detail';
import {
  ADD_BEGIN as MEDICINE_ADD_BEGIN,
  ADD_SUCCESS as MEDICINE_ADD_SUCCESS,
  ADD_FAILED as MEDICINE_ADD_FAILED,
  EDIT_BEGIN as MEDICINE_EDIT_BEGIN,
  EDIT_SUCCESS as MEDICINE_EDIT_SUCCESS,
  EDIT_FAILED as MEDICINE_EDIT_FAILED,
  DELETE_BEGIN as MEDICINE_DELETE_BEGIN,
  DELETE_SUCCESS as MEDICINE_DELETE_SUCCESS,
  DELETE_FAILED as MEDICINE_DELETE_FAILED
} from '../medicine/ops';

// animalType
import {
  FETCH_BEGIN as ANIMAL_TYPE_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as ANIMAL_TYPE_LIST_FETCH_SUCCESS,
  FETCH_FAILED as ANIMAL_TYPE_LIST_FETCH_FAILED
} from '../animalType/list';
import {
  FETCH_BEGIN as ANIMAL_TYPE_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as ANIMAL_TYPE_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as ANIMAL_TYPE_DETAIL_FETCH_FAILED
} from '../animalType/detail';
import {
  ADD_BEGIN as ANIMAL_TYPE_ADD_BEGIN,
  ADD_SUCCESS as ANIMAL_TYPE_ADD_SUCCESS,
  ADD_FAILED as ANIMAL_TYPE_ADD_FAILED,
  EDIT_BEGIN as ANIMAL_TYPE_EDIT_BEGIN,
  EDIT_SUCCESS as ANIMAL_TYPE_EDIT_SUCCESS,
  EDIT_FAILED as ANIMAL_TYPE_EDIT_FAILED,
  DELETE_BEGIN as ANIMAL_TYPE_DELETE_BEGIN,
  DELETE_SUCCESS as ANIMAL_TYPE_DELETE_SUCCESS,
  DELETE_FAILED as ANIMAL_TYPE_DELETE_FAILED
} from '../animalType/ops';

// animalCategory
import {
  FETCH_BEGIN as ANIMAL_CATEGORY_LIST_FETCH_BEGIN,
  FETCH_SUCCESS as ANIMAL_CATEGORY_LIST_FETCH_SUCCESS,
  FETCH_FAILED as ANIMAL_CATEGORY_LIST_FETCH_FAILED
} from '../animalCategory/list';
import {
  FETCH_BEGIN as ANIMAL_CATEGORY_DETAIL_FETCH_BEGIN,
  FETCH_SUCCESS as ANIMAL_CATEGORY_DETAIL_FETCH_SUCCESS,
  FETCH_FAILED as ANIMAL_CATEGORY_DETAIL_FETCH_FAILED
} from '../animalCategory/detail';
import {
  ADD_BEGIN as ANIMAL_CATEGORY_ADD_BEGIN,
  ADD_SUCCESS as ANIMAL_CATEGORY_ADD_SUCCESS,
  ADD_FAILED as ANIMAL_CATEGORY_ADD_FAILED,
  EDIT_BEGIN as ANIMAL_CATEGORY_EDIT_BEGIN,
  EDIT_SUCCESS as ANIMAL_CATEGORY_EDIT_SUCCESS,
  EDIT_FAILED as ANIMAL_CATEGORY_EDIT_FAILED,
  DELETE_BEGIN as ANIMAL_CATEGORY_DELETE_BEGIN,
  DELETE_SUCCESS as ANIMAL_CATEGORY_DELETE_SUCCESS,
  DELETE_FAILED as ANIMAL_CATEGORY_DELETE_FAILED
} from '../animalCategory/ops';

export default (state = {}, action) => {
  const { type } = action;
  const status = getStatus(type);

  switch (type) {
    case APPOINTMENT_LIST_FETCH_BEGIN:
    case APPOINTMENT_LIST_FETCH_SUCCESS:
    case APPOINTMENT_LIST_FETCH_FAILED:
    // case APPOINTMENT_DETAIL_FETCH_BEGIN:
    // case APPOINTMENT_DETAIL_FETCH_SUCCESS:
    // case APPOINTMENT_DETAIL_FETCH_FAILED:
    case APPOINTMENT_ADD_BEGIN:
    case APPOINTMENT_ADD_SUCCESS:
    case APPOINTMENT_ADD_FAILED:
      // case APPOINTMENT_EDIT_BEGIN:
      // case APPOINTMENT_EDIT_SUCCESS:
      // case APPOINTMENT_EDIT_FAILED:
      // case APPOINTMENT_DELETE_BEGIN:
      // case APPOINTMENT_DELETE_SUCCESS:
      // case APPOINTMENT_DELETE_FAILED:
      return { ...state, appointment: status === 'BEGIN' };

    case CUSTOMER_LIST_FETCH_BEGIN:
    case CUSTOMER_LIST_FETCH_SUCCESS:
    case CUSTOMER_LIST_FETCH_FAILED:
    case CUSTOMER_DETAIL_FETCH_BEGIN:
    case CUSTOMER_DETAIL_FETCH_SUCCESS:
    case CUSTOMER_DETAIL_FETCH_FAILED:
    case CUSTOMER_ADD_BEGIN:
    case CUSTOMER_ADD_SUCCESS:
    case CUSTOMER_ADD_FAILED:
    case CUSTOMER_EDIT_BEGIN:
    case CUSTOMER_EDIT_SUCCESS:
    case CUSTOMER_EDIT_FAILED:
    case CUSTOMER_DELETE_BEGIN:
    case CUSTOMER_DELETE_SUCCESS:
    case CUSTOMER_DELETE_FAILED:
      return { ...state, customer: status === 'BEGIN' };

    case PATIENT_LIST_FETCH_BEGIN:
    case PATIENT_LIST_FETCH_SUCCESS:
    case PATIENT_LIST_FETCH_FAILED:
    case PATIENT_DETAIL_FETCH_BEGIN:
    case PATIENT_DETAIL_FETCH_SUCCESS:
    case PATIENT_DETAIL_FETCH_FAILED:
    case PATIENT_ADD_BEGIN:
    case PATIENT_ADD_SUCCESS:
    case PATIENT_ADD_FAILED:
    case PATIENT_EDIT_BEGIN:
    case PATIENT_EDIT_SUCCESS:
    case PATIENT_EDIT_FAILED:
    case PATIENT_DELETE_BEGIN:
    case PATIENT_DELETE_SUCCESS:
    case PATIENT_DELETE_FAILED:
      return { ...state, patient: status === 'BEGIN' };

    case DIAGNOSE_BEGIN:
    case DIAGNOSE_SUCCESS:
    case DIAGNOSE_FAILED:
      return { ...state, diagnose: status === 'BEGIN' };

    case DISEASE_LIST_FETCH_BEGIN:
    case DISEASE_LIST_FETCH_SUCCESS:
    case DISEASE_LIST_FETCH_FAILED:
    case DISEASE_DETAIL_FETCH_BEGIN:
    case DISEASE_DETAIL_FETCH_SUCCESS:
    case DISEASE_DETAIL_FETCH_FAILED:
    case DISEASE_ADD_BEGIN:
    case DISEASE_ADD_SUCCESS:
    case DISEASE_ADD_FAILED:
    case DISEASE_EDIT_BEGIN:
    case DISEASE_EDIT_SUCCESS:
    case DISEASE_EDIT_FAILED:
    case DISEASE_DELETE_BEGIN:
    case DISEASE_DELETE_SUCCESS:
    case DISEASE_DELETE_FAILED:
      return { ...state, disease: status === 'BEGIN' };

    case SYMPTOM_LIST_FETCH_BEGIN:
    case SYMPTOM_LIST_FETCH_SUCCESS:
    case SYMPTOM_LIST_FETCH_FAILED:
    case SYMPTOM_DETAIL_FETCH_BEGIN:
    case SYMPTOM_DETAIL_FETCH_SUCCESS:
    case SYMPTOM_DETAIL_FETCH_FAILED:
    case SYMPTOM_ADD_BEGIN:
    case SYMPTOM_ADD_SUCCESS:
    case SYMPTOM_ADD_FAILED:
    case SYMPTOM_EDIT_BEGIN:
    case SYMPTOM_EDIT_SUCCESS:
    case SYMPTOM_EDIT_FAILED:
    case SYMPTOM_DELETE_BEGIN:
    case SYMPTOM_DELETE_SUCCESS:
    case SYMPTOM_DELETE_FAILED:
      return { ...state, symptom: status === 'BEGIN' };

    case MEDICINE_LIST_FETCH_BEGIN:
    case MEDICINE_LIST_FETCH_SUCCESS:
    case MEDICINE_LIST_FETCH_FAILED:
    case MEDICINE_DETAIL_FETCH_BEGIN:
    case MEDICINE_DETAIL_FETCH_SUCCESS:
    case MEDICINE_DETAIL_FETCH_FAILED:
    case MEDICINE_ADD_BEGIN:
    case MEDICINE_ADD_SUCCESS:
    case MEDICINE_ADD_FAILED:
    case MEDICINE_EDIT_BEGIN:
    case MEDICINE_EDIT_SUCCESS:
    case MEDICINE_EDIT_FAILED:
    case MEDICINE_DELETE_BEGIN:
    case MEDICINE_DELETE_SUCCESS:
    case MEDICINE_DELETE_FAILED:
      return { ...state, medicine: status === 'BEGIN' };

    case ANIMAL_TYPE_LIST_FETCH_BEGIN:
    case ANIMAL_TYPE_LIST_FETCH_SUCCESS:
    case ANIMAL_TYPE_LIST_FETCH_FAILED:
    case ANIMAL_TYPE_DETAIL_FETCH_BEGIN:
    case ANIMAL_TYPE_DETAIL_FETCH_SUCCESS:
    case ANIMAL_TYPE_DETAIL_FETCH_FAILED:
    case ANIMAL_TYPE_ADD_BEGIN:
    case ANIMAL_TYPE_ADD_SUCCESS:
    case ANIMAL_TYPE_ADD_FAILED:
    case ANIMAL_TYPE_EDIT_BEGIN:
    case ANIMAL_TYPE_EDIT_SUCCESS:
    case ANIMAL_TYPE_EDIT_FAILED:
    case ANIMAL_TYPE_DELETE_BEGIN:
    case ANIMAL_TYPE_DELETE_SUCCESS:
    case ANIMAL_TYPE_DELETE_FAILED:
      return { ...state, animalType: status === 'BEGIN' };

    case ANIMAL_CATEGORY_LIST_FETCH_BEGIN:
    case ANIMAL_CATEGORY_LIST_FETCH_SUCCESS:
    case ANIMAL_CATEGORY_LIST_FETCH_FAILED:
    case ANIMAL_CATEGORY_DETAIL_FETCH_BEGIN:
    case ANIMAL_CATEGORY_DETAIL_FETCH_SUCCESS:
    case ANIMAL_CATEGORY_DETAIL_FETCH_FAILED:
    case ANIMAL_CATEGORY_ADD_BEGIN:
    case ANIMAL_CATEGORY_ADD_SUCCESS:
    case ANIMAL_CATEGORY_ADD_FAILED:
    case ANIMAL_CATEGORY_EDIT_BEGIN:
    case ANIMAL_CATEGORY_EDIT_SUCCESS:
    case ANIMAL_CATEGORY_EDIT_FAILED:
    case ANIMAL_CATEGORY_DELETE_BEGIN:
    case ANIMAL_CATEGORY_DELETE_SUCCESS:
    case ANIMAL_CATEGORY_DELETE_FAILED:
      return { ...state, animalCategory: status === 'BEGIN' };

    default:
      return state;
  }
};

const getStatus = type => {
  const moduleAndStatus = type.split('/');
  const statusList = moduleAndStatus[1].split('_');
  const status = statusList[statusList.length - 1];

  return status;
};
