import APIRequest from "../utils/APIRequest";

export default class PatientModel {

    static updatePatient = async(id, body)=>{
        return await APIRequest.set(`v1/adminPatient/${id}`, 'PUT', {
            ...body
        });
    }

}
