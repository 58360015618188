import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

import { useStyles } from './ActionBarCss';

const ActionBar = ({ isFetching = false, children }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={1} className={classes.root}>
      {
        isFetching ? 
          <Grid item style={{ paddingTop: '4px', paddingBottom: '5px' }}><CircularProgress size={24} /></Grid> : 
          children
      }
    </Grid>
  )
}

export default ActionBar;