import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'appointment.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'appointment.ops/ADD_SUCCESS';
export const ADD_FAILED = 'appointment.ops/ADD_FAILED';
export const EDIT_BEGIN = 'appointment.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'appointment.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'appointment.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'appointment.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'appointment.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'appointment.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addAppointment = data => async dispatch => {
  dispatch(addBegin());

  try {
    const formData = new FormData();

    formData.append('appointment_id', data.appointmentSlotID);
    formData.append('patient_id', data.patientID);
    formData.append('additional_question', data.description);
    formData.append('payment_attachment', data.paymentProof);

    await api({
      url: 'use-appointment-slot',
      method: 'POST',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const addAppointments = data => async dispatch => {
  dispatch(addBegin());

  const promises = data.map(datum => {
    const formattedData = {
      appointment_name: datum.name,
      appointment_time: datum.time,
      duration: datum.duration,
      doctor_id: datum.doctor,
      is_real_appointment: datum.isRealAppointment
    };

    return api.post('add-appointment', formattedData);
  });

  try {
    const res = await Promise.all(promises).then(values => values);

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const approveAppointment = (id, data) => async dispatch => {
  dispatch(editBegin());

  const formattedData = {
    id,
    appointment_time: data.time,
    duration: data.duration
  };

  try {
    const res = await api.post('approve-appointment', formattedData);

    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully approved appointment'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deleteAppointment = id => async dispatch => {
  dispatch(deleteBegin());

  try {
    const res = await api.post(`cancel-appointment`, {
      id
    });

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export const deleteAppointments = ids => async dispatch => {
  dispatch(deleteBegin());

  const promises = ids.map(id => {
    return api.delete(`delete-appointment?id=${id}`);
  });

  try {
    const res = await Promise.all(promises).then(values => values);

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case ADD_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case ADD_FAILED:
    case DELETE_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
