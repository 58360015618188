import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import { addSymptom } from '../../../redux/symptom/ops';

const SymptomAddPage = () => {
  // Prereq Hooks
  const dispatch = useDispatch();

  const { completed } = useSelector(state => state.symptom.ops);
  const isFetching = useSelector(state => state.loading.symptom);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    resetForm
  } = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      dispatch(addSymptom(values));
    }
  });

  return (
    <Card>
      <CardHeader title="Add Symptom"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                value={values.name}
                onChange={handleChange}
                label="Symptom Name *"
                helperText={touched.name && errors.name ? errors.name : ''}
                error={touched.name && errors.name ? true : false}
              />
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default SymptomAddPage;
