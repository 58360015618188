import React from 'react';
import ImageMap from 'image-map';

const DogAnatomyMap = ({
  show,
  handleHeadClick,
  handleChestClick,
  handleBodyClick,
  handleFrontLegClick,
  handleBellyClick,
  handleHindLegClick,
  handleHipsClick,
  handleTailClick
}) => {
  const handleDefaultClick = () => {
    alert('woi');
  };

  ImageMap('img[usemap]');
  return (
    <>
      <img
        src="/media/diagnosis/dog_anatomy_new.jpg"
        alt="dog_anatomy"
        useMap="#image-map-2"
        style={{ maxWidth: '100%', display: show ? 'block' : 'none' }}
      />

      <map name="image-map-2">
        <area
          onClick={handleHeadClick}
          alt="head"
          title="head"
          coords="693,331,732,377,815,388,860,331,918,321,939,257,881,247,807,203,751,228,724,283"
          shape="poly"
        />
        <area
          onClick={handleChestClick}
          alt="chest"
          title="chest"
          coords="733,376,616,528,629,541,635,560,637,591,731,585,769,553,799,499,812,441,817,388"
          shape="poly"
        />
        <area
          onClick={handleBodyClick}
          alt="body"
          title="body"
          coords="732,375,692,333,650,346,592,362,503,355,428,351,404,513,429,522,451,546,466,527,496,509,538,507,579,511,614,528"
          shape="poly"
        />
        <area
          onClick={handleFrontLegClick}
          alt="front-leg"
          title="front-leg"
          coords="636,590,646,649,660,704,681,751,704,773,749,775,765,772,767,761,754,748,730,739,713,688,733,583"
          shape="poly"
        />
        <area
          onClick={handleBellyClick}
          alt="belly"
          title="belly"
          coords="462,531,495,507,544,504,584,512,615,528,635,555,636,592,571,576,513,557"
          shape="poly"
        />
        <area
          onClick={handleHindLegClick}
          alt="hind-leg"
          title="hind-leg"
          coords="449,548,432,525,400,512,369,517,329,547,316,529,289,523,247,561,233,608,204,641,194,674,195,762,219,776,252,781,252,762,233,739,240,686,318,623,304,671,342,736,353,770,412,777,414,736,383,735,353,681,405,617"
          shape="poly"
        />
        <area
          onClick={handleHipsClick}
          alt="hips"
          title="hips"
          coords="403,514,431,355,377,360,310,386,277,424,238,479,246,566,288,526,320,532,332,550,360,521,407,519"
          shape="poly"
        />
        <area
          onClick={handleTailClick}
          alt="tail"
          title="tail"
          coords="238,481,194,537,143,580,87,599,49,596,68,635,108,653,169,640,194,630,247,565,247,516"
          shape="poly"
        />
      </map>
    </>
  );
};

export default DogAnatomyMap;
