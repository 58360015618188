import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { TextField, PasswordTextField } from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar, ActionBarEdit } from '../../components/common/ActionBar';
import File from 'app/models/File';
import swal from 'sweetalert2';
import Doctor from 'app/models/Doctor';
import { useParams } from 'react-router-dom';

const DoctorEditPageV2 = () => {
  const { id } = useParams();
  const [doctorDetail, setDoctorDetail] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumbersCount, setNewPhoneNumbersCount] = useState([]);

  const getDoctorData = async () => {
    let doctorModel = new Doctor();

    try {
      let result = await doctorModel.getDoctorById(id);
      setDoctorDetail(result);
      setPhoneNumbers(result.phone_numbers);
    } catch (e) {
      console.log('error ', e);
    }
  };

  const handleEditDoctor = async values => {
    let doctorModel = new Doctor();
    let uploadModel = new File();
    let formattedValues = {
      ...values,
      birthdate: typeof values.dateOfBirth === 'string' ? values.dateOfBirth : format(values.dateOfBirth, 'yyyy-MM-dd'),
      mobile: values.phone,
      doctor_name: values.user_name
    };

    console.log(values);

    try {
      
      if (typeof values.doctorImage !== 'undefined' && typeof values.doctorImage !== 'string') {
        let imgUrl = await uploadModel.uploadFile(values.doctorImage);
        formattedValues = { ...formattedValues, image_url: imgUrl.location };
      } else {
        formattedValues = { ...formattedValues, image_url: values.doctorImage };
      }
      console.log(formattedValues)
      let result = await doctorModel.edit(id, formattedValues);
      swal.fire({
        title: 'Success',
        text: 'Doctor edited successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      //handleReset();
    } catch (e) {
      console.log('error', e);
      swal.fire({
        title: 'Error',
        text: e.error_message ? e.error_message : 'Error occured',
        icon: 'error',
        confirmButtonText: 'Retry',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  useEffect(() => {
    getDoctorData();
  }, []);
  const { values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      user_name: doctorDetail?.user_name,
      email: doctorDetail?.email,
      phone: doctorDetail?.mobile,
      dateOfBirth: doctorDetail?.birthdate,
      doctorImage: doctorDetail?.image_url
    },
    validationSchema: Yup.object({
      user_name: Yup.string().required('Cannot be empty'),
      email: Yup.string().required('Cannot be empty'),
      phone: Yup.number().required('Cannot be empty'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable()
    }),
    onSubmit: values => {
      handleEditDoctor(values);
    },
    enableReinitialize: true
  });

  return (
    <Card>
      <CardHeader title="Doctor details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                id="user_name"
                value={values.user_name}
                onChange={handleChange}
                label="Full Name"
                helperText={touched.user_name && errors.user_name ? errors.user_name : ''}
                error={touched.user_name && errors.user_name ? true : false}
                InputLabelProps={{ shrink: !!values.user_name }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                label="Email"
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && errors.email ? true : false}
                InputLabelProps={{ shrink: !!values.email }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phone"
                value={values.phone}
                onChange={handleChange}
                label="Phone"
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && errors.email ? true : false}
                InputLabelProps={{ shrink: !!values.email }}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth"
                helperText={touched.dateOfBirth && errors.dateOfBirth ? 'Invalid date' : ''}
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
                InputLabelProps={{ shrink: !!values.dateOfBirth }}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                id="doctorImage"
                onChange={event => {
                  setFieldValue('doctorImage', event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="doctorImage">
                <Button color="primary" variant="outlined" component="span">
                  Upload Image
                </Button>
                <span style={{ marginLeft: '1rem' }}>{values.doctorImage?.name || ''}</span>
              </label>
            </Grid>
            {doctorDetail?.image_url && (
              <img src={doctorDetail?.image_url} alt={doctorDetail?.doctor_name} style={{ width: '300px' }} />
            )}
            <ActionBarEdit handleSubmit={handleSubmit} isEditing={true} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default DoctorEditPageV2;
