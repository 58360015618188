import React from 'react';

import { CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import ButtonLink from '../ButtonLink';

export default function HeaderToolbarList({
  addLink,
  addText,
  additionalComponent
}) {
  return (
    <CardHeaderToolbar>
      {additionalComponent}
      <ButtonLink link={addLink} variant="contained" color="primary">
        {addText}
      </ButtonLink>
    </CardHeaderToolbar>
  );
}
