import { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { ArrowBackIosOutlined } from '@material-ui/icons';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import TextField from 'app/components/common/TextField';
import { ActionBarAdd } from 'app/components/common/ActionBar';
import Clinic from 'app/models/Clinic';
import OperationTable from 'app/models/OperationTable';
import swal from 'sweetalert2';

const OperationTableAddPage = ({ data, isEditing = true, headerToolbar, mode }) => {
  let [clinicList, setClinicList] = useState([]);
  const history = useHistory();

  const fetchClinicList = async () => {
    let clinicModel = new Clinic();
    try {
      let result = await clinicModel.getAllClinic();

      setClinicList(result);
      console.log(result);
    } catch (e) {
      console.log('ERROR' + e);
    }
  };
  useEffect(() => {
    fetchClinicList();
  }, []);

  const { values, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      operationName: data?.operationName || '',
      clinicType: data?.clinicType || null,
      operationType: data?.operationType || null
    },
    validationSchema: Yup.object({
      operationName: Yup.string().required('Cannot be empty')
    }),
    onSubmit: formValues => {
      addOperationTable(formValues);
    }
  });

  const addOperationTable = formValues => {
    console.log(formValues);

    let operationTableModel = new OperationTable();

    try {
      let result = operationTableModel.addOperationTable({
        clinic_id: formValues.clinicType,
        type: formValues.operationType,
        name: formValues.operationName
      });
      swal.fire({
        title: 'Success',
        text: 'Operation Table added successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      handleReset();
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const operationOptions = ['SMALL', 'BIG'];

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Add Operation Table</h3>
        </div>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="clinicType"
                select
                value={values.clinicType}
                onChange={event => {
                  setFieldValue('clinicType', event.target.value);
                }}
                label="Clinic Name *"
              >
                {clinicList.map((obj, key) => (
                  <MenuItem key={obj.id} value={obj.id}>
                    {`${obj.name}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField value={values.operationName} onChange={handleChange} name="operationName" label="Input Name" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="operationType"
                select
                value={values.operationType}
                onChange={event => {
                  setFieldValue('operationType', event.target.value);
                }}
                label="Operation Name Type *"
              >
                {operationOptions.map(obj => (
                  <MenuItem key={obj} value={obj}>
                    {`${obj}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <ActionBarAdd handleSubmit={handleSubmit} handleReset={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default OperationTableAddPage;
