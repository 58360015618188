import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Link as LinkIcon, CloudUploadOutlined, ArrowBackIosOutlined } from '@material-ui/icons';
import { Button, Grid, IconButton, Modal } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';

import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { Card, CardBody, CardHeaderToolbar, CardHeaderTitle } from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarAdd } from '../../components/common/ActionBar';

import swal from 'sweetalert2';
import File from 'app/models/File';
import MedicalRecord from 'app/models/MedicalRecord';

const MedicalRecordAddPageV2 = () => {
  const history = useHistory();
  const {id} = useParams();

  const [imageWithDesc, setImageWithDesc] = useState([]);
  const [openImgModal, setOpenImgModal] = useState(false);


  const { values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik({
    initialValues: {
      description: '',
      medication: '',
      medicine: '',
      attachment: null,
      imageDesc: ''
    },
    validationSchema: Yup.object({
      description: Yup.string(),
      medication: Yup.string(),
      medicine: Yup.string()
    }),
    onSubmit: formValues => {
      submitMedicalRecord(formValues);
    }
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const pushImageAndDesc = async () => {
    let uploadModel = new File();
    let mediaUrl;

    try {
      mediaUrl = await uploadModel.uploadFile(values.attachment);
      console.log(mediaUrl);
      setImageWithDesc([
        ...imageWithDesc,
        {
          media_url: mediaUrl.location,
          description: values.imageDesc
        }
      ]);
      setFieldValue('attachment', null);
      setFieldValue('imageDesc', '');
      setOpenImgModal(false)
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const submitMedicalRecord = async formValues => {
    let medicalRecordModel = new MedicalRecord();

    let formData = {
      appointment_user_id: id,
      description: formValues.description,
      medication: formValues.medication,
      attachments: imageWithDesc
    };
    try {
      let result = await medicalRecordModel.submitMedicalRecord(formData);
      console.log(result);
      swal
        .fire({
          title: 'Success',
          text: 'Medical Record added successfully',
          icon: 'success',
          confirmButtonText: 'Go back',
          customClass: {
            container: 'swal-z'
          }
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            history.goBack();
          }
        });
      handleReset();
      setImageWithDesc([]);
    } catch (e) {
      console.log('error: ', e);
    }
  };
  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Medical Record Details</h3>
        </div>
        <CardHeaderToolbar>
          <Grid item>
            Related Appointment
            <Link to={`/appointment/${id}`}>
              <IconButton size="small" color="primary">
                <LinkIcon />
              </IconButton>
            </Link>
          </Grid>
        </CardHeaderToolbar>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="description"
                value={values.description}
                onChange={handleChange}
                label="Description (Catatan internal)"
                helperText={touched.description && errors.description ? errors.description : ''}
                error={touched.description && errors.description ? true : false}
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="medication"
                value={values.medication}
                onChange={handleChange}
                label="Medication (Catatan untuk pasien)"
                helperText={touched.medication && errors.medication ? errors.medication : ''}
                error={touched.medication && errors.medication ? true : false}
                multiline
                rows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="medicine"
                value={values.medicine}
                onChange={handleChange}
                label="Medicine (Nama Obat)"
                helperText={touched.medicine && errors.medicine ? errors.medicine : ''}
                error={touched.medicine && errors.medicine ? true : false}
                multiline
                rows={3}
              />
              <Button
                color="primary"
                variant="outlined"
                component="span"
                onClick={() => setOpenImgModal(true)}
                className="mt-5"
              >
                Add Media
              </Button>
            </Grid>
            <Modal
              open={openImgModal}
              onClose={() => setOpenImgModal(false)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Card style={{ minWidth: '600px' }}>
                <CardHeaderTitle className="mt-8 pl-9">Add Attachment</CardHeaderTitle>
                <hr></hr>
                <CardBody className="pt-0">
                  <Row>
                    <Col>
                      <input
                        id="attachment"
                        onChange={event => {
                          setFieldValue('attachment', event.currentTarget.files[0]);
                        }}
                        accept=".jpg, .png, .jpeg, .gif, .mp4|image/*|video/*"
                        style={{ display: 'none' }}
                        multiple
                        type="file"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="attachment">
                        <Button color="primary" variant="outlined" component="span" className="w-100">
                          Upload Media <CloudUploadOutlined />
                        </Button>
                        <span style={{ marginLeft: '1rem' }}>{values.attachment?.name || ''}</span>
                      </label>
                      <TextField
                        id="imageDesc"
                        value={values.imageDesc}
                        onChange={handleChange}
                        label="Image Description"
                        helperText={touched.imageDesc && errors.imageDesc ? errors.imageDesc : ''}
                        error={touched.imageDesc && errors.imageDesc ? true : false}
                        multiline
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row item className="mt-10">
                    <Col>
                    <span>
                      *Please add image before clicking
                    </span>
                    <br>
                    </br>
                      <Button color="primary" variant="outlined" component="span" onClick={pushImageAndDesc}>
                        Add Attachment and Desc
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Modal>
            {imageWithDesc.length > 0 && (
              <Grid item xs={12} className="w-100">
                <DataTable
                  data={imageWithDesc}
                  totalDataCount={imageWithDesc.length}
                  columns={[
                    {
                      text: 'Media',
                      dataField: 'media_url',
                      formatter: (cell, row, rowIndex) => {
                        let content = row.media_url.includes('.mp4') ? (
                          <video width="300" height="300" controls>
                            <source src={row.media_url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={row.media_url} width={'300px'} alt={row.description}></img>
                        );

                        return content;
                      }
                    },
                    {
                      text: 'Description',
                      dataField: 'description'
                    }
                  ]}
                  editLink="/clinic/edit/"
                  noEditAction={true}
                  noSearchAction={true}
                  style={{ width: '100%' }}
                />
              </Grid>
            )}
            <ActionBarAdd handleSubmit={handleSubmit} handleReset={() => {setImageWithDesc([]); handleReset()}} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default MedicalRecordAddPageV2;
