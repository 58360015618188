import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Grid, IconButton, Button} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
    Assignment as AssignmentIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import {endOfWeek, format, parseISO} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
    Card,
    CardBody,
    CardHeader
} from '../../../_metronic/_partials/controls';
import ButtonLink from 'app/components/common/ButtonLink';
import {TextField} from 'app/components/common/TextField';
import {sortCaret} from '../../../_metronic/_helpers';
import {ListHeaderToolbar} from '../../components/common/HeaderToolbar';
import {DataTableLocal as DataTable} from '../../components/common/DataTable';
import ApproveAppointmentDialog from './components/ApproveAppointmentDialog';
import {CardHeaderToolbar} from '../../../_metronic/_partials/controls';
import AddAppointmentDialog from './components/AddAppointmentDialog';

import moment from "moment"

import qs from "qs"
import Appointment from "../../models/Appointment";

let contentTimer;

const startOfDay = (paramDate) => {
    let startOfDay = new moment(paramDate)
    return startOfDay.set({hour: 0, minute: 0, second: 0}).toDate()
}

const AppointmentListPageV2 = (props) => {

    const history = useHistory();

    const editSearchQueryValue = (value) => {
        clearTimeout(contentTimer);

        contentTimer = setTimeout(async () => {
            setSearchQuery(value)
            changeHistoryPush(value)
            // history.push(`/appointment?q=${value}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
        }, 500);
    }

    const usePrevious = value => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const [searchQuery, setSearchQuery] = useState("")
    const [searchRef, setSearchRef] = useState(null)
    const [defaultSearchValue, setDefaultSearchValue] = useState(null)
    const [data, setData] = useState([])
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    const {data: keywords} = useSelector(
        state => state.appointment.approve
    );

    const isFetching = useSelector(state => state.loading.appointment);
    const {completed} = useSelector(state => state.appointment.ops);
    const previousCompleted = usePrevious(completed);
    const {completed: approveCompleted} = useSelector(
        state => state.appointment.approve
    );

    useEffect(() => {
        if (completed && completed !== previousCompleted) {
            fetchAppointmentConditionally();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed, previousCompleted]);

    useEffect(() => {
        approveCompleted && fetchAppointmentConditionally();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveCompleted]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [appointmentType, setAppointmentType] = useState('');

    const onChangeStartDate = (newValue) => {
        setStartDate(newValue)
        changeHistoryPush()
    }

    const onChangeEndDate = (newValue) => {
        setEndDate(newValue)
        changeHistoryPush()
    }

    const changeHistoryWhenClearDateFilter = () => {
        history.push(`/appointment?q=${searchQuery}&startDate=null&endDate=null`)
    }

    const changeHistoryPush = (value) => {

        let sd = moment(startDate).format('yyyy-MM-DD HH:mm:ss')
        let ed = moment(endDate).format('yyyy-MM-DD HH:mm:ss')

        let startDateFragment = null
        let endDateFragment = null

        if (startDate) {
            startDateFragment = `&startDate=${sd}`
        } else {
            startDateFragment = ""
        }

        if (endDate) {
            endDateFragment = `&endDate=${ed}`
        } else {
            endDateFragment = ""
        }

        if (value !== undefined) {
            history.push(`/appointment?q=${value}${startDateFragment}${endDateFragment}`)
        } else {
            history.push(`/appointment?q=${searchQuery}${startDateFragment}${endDateFragment}`)
        }
    }

    useEffect(() => {

        const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
        if (queryParams.q) {
            setDefaultSearchValue(queryParams.q)
            setSearchQuery(queryParams.q)
        }

        setStartDate(startOfDay(new Date()));
        // setEndDate(addDays(startOfDay(new Date()), 1));
        setEndDate(endOfWeek(startOfDay(new Date()), {weekStartsOn: 1}));

        // console.log("QP", queryParams.startDate, queryParams.endDate)
        if (queryParams.startDate) {
            if (queryParams.startDate === "null") {
                setStartDate(null)
            } else {
                setStartDate(new Date(queryParams.startDate))
            }
        }

        if (queryParams.endDate) {
            if (queryParams.endDate === "null") {
                setEndDate(null)
            } else {
                setEndDate(new Date(queryParams.endDate))
            }
        }


    }, []);

    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        setFilteredData(data);
    }, [data, appointmentType]);


    useEffect(() => {
        fetchAppointmentConditionally();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        fetchAppointmentConditionally();
    }, [])

    const fetchAppointmentConditionally = () => {

        console.log("APS FETCHING APPOINTMENT")

        if (startDate && endDate) {
            const formattedStartDate = moment(startDate).utc().format('yyyy-MM-DD HH:mm:ss');
            const formattedEndDate = moment(endDate).utc().format('yyyy-MM-DD HH:mm:ss');

            fetchAppointmentByDateRange(formattedStartDate, formattedEndDate)
        } else if (startDate === null && endDate === null) {
            fetchAppointment()
        }
    };

    const fetchAppointmentByDateRange = async (startDate, endDate) => {

        let appointmentModel = new Appointment()

        try {
            let appointments = await appointmentModel.getAppointmentByDate(startDate, endDate)
            console.log("APS", appointments)
            setData(appointments)
        } catch (e) {
            console.log(e)
        }

    }

    const fetchAppointment = async () => {

        let appointmentModel = new Appointment()

        console.log("APS FETCHING APPOINTMENT")

        try {

            let startDate = startOfDay(new Date())
            let endDate = endOfWeek(startOfDay(new Date()), {weekStartsOn: 1})
            // setEndDate(addDays(startOfDay(new Date()), 1));

            let appointments = await appointmentModel.getAppointmentByDate(startDate, endDate)

            console.log("APS", appointments)

            setData(appointments)

        } catch (e) {
            console.log(e)
        }

    }

    const columns = [
        {
            dataField: 'start_time',
            text: 'time',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return row && `${format(parseISO(row.start_time), 'yyyy-MM-dd HH:mm')} - ${format(parseISO(row.end_time), 'HH:mm')}`;
            },
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'customer_name',
            text: 'customer',
            formatter: (cell, row) => {
                return row && <Link to={`/customer/${row?.customer_id}`}>{cell}</Link>;
            }
        },
        {
            dataField: 'patients',
            text: 'patient',
            formatter: (cell, row) => {
                return row && <div>
                    {cell?.map((obj, key) => {
                        return `${obj.patient_name}${key < cell.length - 1 ? ", " : ""}`
                    })}
                </div>;
            }
        },
        {
            dataField: 'doctor_name',
            text: 'doctor'
        },
        {
            dataField: 'booking_type_name',
            text: 'type',
            formatter: (cell, row) => {
                return row.bookingType?.booking_type_name
            }
        },
        {
            dataField: 'status',
            text: 'status',
            formatter: (cell, row) => {

                let status = ""

                if (row.status === "DONE") {
                    status = "Done"
                } else if (row.payment_status === "PAID") {
                    status = "Need admin confirmation"
                } else if (row.payment_status === "PAID_CONFIRMED" || row.payment_status === "ADMIN_CONFIRMED") {
                    status = "Confirmed"
                } else if (row.payment_status === "UNPAID") {
                    status = "Waiting for Payment"
                } else if (row.payment_status === "EXPIRED") {
                    status = "Expired"
                } else {
                    status = `${row.status} | ${row.payment_status}`
                }

                return row && <>
                    {status}
                </>
            }
        }
    ];

    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(undefined);

    const handleClearDateClick = () => {
        setStartDate(null);
        setEndDate(null);
        changeHistoryWhenClearDateFilter()
    };

    console.log('filtered_data', filteredData.length)
    // console.log('isi data',filteredData)
    return (
        <>
            <Card>
                <CardHeader title="Appointment list">
                    <CardHeaderToolbar>
                        <ButtonLink to="/appointmentType" style={{marginRight: '1rem'}}>
                            Manage Type
                        </ButtonLink>
                        {/*<Button onClick={() => setIsAddDialogOpen(true)} variant="contained" color="primary">*/}
                        {/*  New Appointment*/}
                        {/*</Button>*/}
                    </CardHeaderToolbar>

                </CardHeader>
                <CardBody>
                    <DataTable
                        defaultSearchValue={defaultSearchValue}
                        data={filteredData}
                        loading={isFetching}
                        totalDataCount={filteredData.length}
                        columns={columns}
                        editLink="/appointment/"
                        handleSearchChange={(value) => {
                            editSearchQueryValue(value)
                            // console.log("boi", value)
                        }}
                        setSearchRef={setSearchRef}
                        // renderCustomActions={row => {
                        //     return (
                        //         <Tooltip title="Approve">
                        //             <IconButton
                        //                 onClick={() => {
                        //                     setSelectedAppointment({...row});
                        //                     setIsApproveDialogOpen(true);
                        //                 }}
                        //                 size="small"
                        //             >
                        //                 <AssignmentIcon/>
                        //             </IconButton>
                        //         </Tooltip>
                        //     );
                        // }}
                        customActionBar={
                            <Grid container alignItems="center">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item style={{marginLeft: '1rem'}}>
                                        <DatePicker
                                            value={startDate}
                                            onChange={onChangeStartDate}
                                            label="Start date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            style={{
                                                width: '160px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{marginLeft: '0.25rem', marginRight: '0.25rem'}}
                                    >
                                        <span> - </span>
                                    </Grid>
                                    <Grid item>
                                        <DatePicker
                                            disabled={!startDate}
                                            value={endDate}
                                            onChange={onChangeEndDate}
                                            label="End date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            style={{
                                                width: '160px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleClearDateClick} size="small">
                                            <CloseIcon/>
                                        </IconButton>
                                    </Grid>
                                    {/* <Grid item style={{ marginLeft: '1rem' }}>
                    <TextField
                      value={appointmentType}
                      onChange={e => {
                        setAppointmentType(e.target.value);
                      }}
                      select
                      label="Type"
                    >
                      <MenuItem value="">&nbsp;</MenuItem>
                      <MenuItem value="CHECK-UP">CHECK-UP</MenuItem>
                      <MenuItem value="GROOMING">GROOMING</MenuItem>
                      <MenuItem value="RAJANTI TALK WITH ANIMALS">
                        RAJANTI TALK WITH ANIMALS
                      </MenuItem>
                    </TextField>
                  </Grid> */}
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }
                    />
                </CardBody>
            </Card>
            <ApproveAppointmentDialog
                open={isApproveDialogOpen}
                handleClose={() => setIsApproveDialogOpen(false)}
                data={selectedAppointment}
            />
            <AddAppointmentDialog
                isOpen={isAddDialogOpen}
                handleClose={(refresh) => {
                    setIsAddDialogOpen(false);
                }}
                //   doctorID={id}
                //   data={selectedAppointmentSlot}
            />
        </>
    );
};

export default AppointmentListPageV2;
