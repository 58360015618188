import { combineReducers } from 'redux';

import add from './add';
import deleteReducer from './delete';
import detail from './detail';
import list from './list';

export default combineReducers({
  add,
  delete: deleteReducer,
  detail,
  list
});
