import axios from '../../api/diagnosisAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'animalType.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'animalType.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'animalType.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = (data) => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = (error) => ({ type: FETCH_FAILED, payload: error });
export const fetchAnimalTypeList = () => async (dispatch) => {
  dispatch(fetchBegin());
  
  try {
    const res = await axios.get('retrieve-animal-type');
    
    dispatch(fetchSuccess(res.data.result));
  } catch(error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()))
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch(type) {
    case FETCH_BEGIN:
      return { ...state, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload };
    case FETCH_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
}

const initialState = {
  data: [],
  error: null,
}
