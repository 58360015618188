/**
 * @todo: implement edit patient later
 */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, Grid, MenuItem} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import MedicalRecordListCard from './components/MedicalRecordListCard';
import {EditHeaderToolbar as HeaderToolbar} from '../../components/common/HeaderToolbar';
import {TextField} from '../../components/common/TextField';
import {ActionBarEdit as ActionBar} from '../../components/common/ActionBar';
import {fetchCustomerList} from '../../../redux/customer/list';
import {fetchAnimalTypeList} from '../../../redux/animalType/list';
import {fetchPatient} from '../../../redux/patient/detail';
import {editPatient, deletePatient} from '../../../redux/patient/ops';

import {fetchPatientList} from '../../../redux/patient/list';
import PatientModel from "../../../model/PatientModel";
import UploadModel from "../../../app/models/File";

import moment from "moment";

const PatientEditPage = () => {
  const dispatch = useDispatch();
  const {id} = useParams();

  useEffect(() => {
    dispatch(fetchCustomerList());
    dispatch(fetchAnimalTypeList());
    dispatch(fetchPatient(id));

    dispatch(fetchPatientList());
  }, []);
  const {role} = useSelector(state => state.auth);
  const {data: ownerList} = useSelector(state => state.customer.list);
  const {data: animalTypeList} = useSelector(state => state.animalType.list);
  const {data} = useSelector(state => state.patient.detail);

  const {completed} = useSelector(state => state.patient.ops);
  const isFetching = useSelector(state => state.loading.patient);

  const [uploadedImage, setUploadedImage] = useState(null)

  useEffect(() => {
    if (completed) {
      dispatch(fetchPatient(id));
      setIsEditing(false);
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      name: data?.patient_name || '',
      animalType: data?.animal_type_id || '',
      breed: data?.breed || '',
      owner: data?.pet_owner_id || '',
      age: data?.age || 0,
      ageMonth: data?.age_month % 12 || 0,
      ageString: data?.age_string || '',
      birthdate: moment(data?.birthdate).format("yyyy-MM-DD"),
      gender: data?.gender || '',
      patientImage: null,
      weight: data?.weight || '',
      microchip: data?.microchip || '',
      tatoo: data?.tatoo || '',
      blood_type: data?.blood_type || '',
      allergy_type: data?.allergy_type || '',
      dna: data?.dna || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      animalType: Yup.string().required('Cannot be empty'),
      breed: Yup.string().required('Cannot be empty'),
      owner: Yup.string().required('Cannot be empty'),
      age: Yup.number()
        .min(0, 'Must be greater than 0')
        .required('Cannot be empty'),
      weight: Yup.number()
        .min(0, 'Must be greater than 0')
        .required('Cannot be empty'),
      gender: Yup.string().required('Cannot be empty')
    }),
    onSubmit: async values => {

      let formattedValues = {
        ...values,
        patient_gender: values.gender
      };

      if (values.patientImageNew) {
        try {
          let uploadModel = new UploadModel();
          let imgUrl = await uploadModel.uploadFile(values.patientImage);
          console.log("img url", imgUrl);
          if (imgUrl.location) {
            formattedValues.patient_picture = imgUrl.location
          }
        } catch (e) {
          console.log('error: ', e);
        }
      }

      console.log("is submitting values", formattedValues)

      try {
        let result = await PatientModel.updatePatient(id, formattedValues)
        dispatch(fetchPatient(id));
        setIsEditing(false)
        console.log("RESULT", result)
      } catch (e) {
        console.log(e)
      }


    },
    enableReinitialize: true
  });

  const [isEditing, setIsEditing] = useState(false);

  const [ownerInput, setOwnerInput] = useState('');

  useEffect(() => {
    const selectedOwner = ownerList.find(
      currOwner => currOwner.id === values.owner
    );
    selectedOwner && setOwnerInput(`${selectedOwner.user_name} ${role !== "DOCTOR" ? `(${selectedOwner.mobile})` : ""}`);
  }, [values.owner, ownerList]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Patient details">
            {role !== 'DOCTOR' && (
              <HeaderToolbar
                isEditing={isEditing}
                handleEdit={() => setIsEditing(true)}
                handleCancel={() => {
                  resetForm();
                  setIsEditing(false);
                }}
              />
            )}
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {data?.picture && data?.picture !== 'No Attachment' && (
                  <Grid item xs={12}>
                    {
                      uploadedImage ?
                        <img
                          src={uploadedImage}
                          alt="patient"
                          style={{height: '160px', marginBottom: '1rem'}}
                        />
                        :
                        data?.picture ?
                          data.picture.includes("https://") ?
                            <img
                              src={data.picture}
                              alt="patient"
                              style={{height: '160px', marginBottom: '1rem'}}
                            />
                            :
                            <img
                              src={`${process.env.REACT_APP_USER_API_V3_CONFIG}/api/image?image_name=${data?.picture}`}
                              alt="patient"
                              style={{height: '160px', marginBottom: '1rem'}}
                            />
                          :
                          ""
                    }
                  </Grid>
                )}

                {isEditing && (
                  <Grid item xs={12}>
                    <input
                      id="patientImage"
                      onChange={event => {
                        setFieldValue(
                          'patientImage',
                          event.currentTarget.files[0]
                        )

                        setUploadedImage(event.currentTarget.value)

                        setFieldValue(
                          'patientImageNew',
                          event.currentTarget.files[0]
                        );

                        let reader = new FileReader();

                        reader.onload = function(event) {
                          setUploadedImage(event.target.result)
                        };

                        reader.readAsDataURL(event.currentTarget.files[0]);

                      }}
                      accept="image/*"
                      style={{display: 'none'}}
                      multiple
                      type="file"
                    />
                    <label htmlFor="patientImage">
                      <Button
                        color="primary"
                        variant="outlined"
                        component="span"
                      >
                        Upload Patient Image
                      </Button>
                      <span style={{marginLeft: '1rem'}}>
                        {values.patientImage?.name || ''}
                      </span>
                    </label>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    disabled={!isEditing}
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Name *"
                    helperText={touched.name && errors.name ? errors.name : ''}
                    error={touched.name && errors.name ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disabled
                    value={values.owner}
                    onChange={(event, newValue) => {
                      setFieldValue('owner', newValue?.id);
                    }}
                    options={ownerList}
                    getOptionLabel={option =>
                      option ? `${option.user_name} (${option.mobile})` : ''
                    }
                    getOptionSelected={(option, val) => {
                      return option.id === val;
                    }}
                    inputValue={ownerInput}
                    onInputChange={(event, newInputValue) => {
                      event?.type !== 'blur' && setOwnerInput(newInputValue);
                    }}
                    size="small"
                    renderInput={params => (
                      <TextField
                        {...params}
                        helperText={
                          touched.owner && errors.owner ? errors.owner : ''
                        }
                        error={touched.owner && errors.owner ? true : false}
                        label="Owner *"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!isEditing}
                    id="animalType"
                    select
                    value={values.animalType}
                    onChange={event => {
                      setFieldValue('animalType', event.target.value);
                    }}
                    label="AnimalType *"
                    helperText={touched.animalType ? errors.animalType : ''}
                    error={
                      touched.animalType && errors.animalType ? true : false
                    }
                  >
                    {animalTypeList.map(({id, animal_name}) => (
                      <MenuItem key={id} value={id}>
                        {animal_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!isEditing}
                    id="breed"
                    value={values.breed}
                    onChange={handleChange}
                    label="Breed *"
                    helperText={
                      touched.breed && errors.breed ? errors.breed : ''
                    }
                    error={touched.breed && errors.breed ? true : false}
                  />
                </Grid>
                {!isEditing ? (
                  <Grid item xs={6}>
                    <TextField
                      disabled={true}
                      id="ageString"
                      value={values.ageString}
                      onChange={handleChange}
                      label="Age *"
                      helperText={touched.age && errors.age ? errors.age : ''}
                      error={touched.age && errors.age ? true : false}
                      type="text"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        id="age"
                        value={values.birthdate}
                        onChange={(e) => {
                          console.log("TANGGAL", e.target.value)
                          // e.target.value = moment(e).format("yyyy-MM-DD")
                          // handleChange(e.target.value)
                          setFieldValue("birthdate", moment(e.target.value).format("yyyy-MM-DD"))
                          // console.log("TANGGAL FORMATTED", e.target.value)
                        }}
                        label="Birth Date *"
                        helperText={touched.birthdate && errors.birthdate ? errors.birthdate : ''}
                        error={touched.birthdate && errors.birthdate ? true : false}
                        type="date"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    select
                    id="gender"
                    value={values.gender}
                    onChange={(event, newValue) => {
                      setFieldValue('gender', newValue.props.value);
                    }}
                    label="Gender *"
                    helperText={
                      touched.gender && errors.gender ? errors.gender : ''
                    }
                    error={touched.gender && errors.gender ? true : false}
                  >
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="weight"
                    value={values.weight + ""}
                    onChange={handleChange}
                    label="Weight (Kg) *"
                    helperText={
                      touched.weight && errors.weight ? errors.weight : ''
                    }
                    type={"number"}
                    error={touched.weight && errors.weight ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="microchip"
                    value={values.microchip}
                    onChange={handleChange}
                    label="Microchip"
                    helperText={
                      touched.microchip && errors.microchip ? errors.microchip : ''
                    }
                    error={touched.microchip && errors.microchip ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="tatoo"
                    value={values.tatoo}
                    onChange={handleChange}
                    label="Tattoo"
                    helperText={
                      touched.tatoo && errors.tatoo ? errors.tatoo : ''
                    }
                    error={touched.tatoo && errors.tatoo ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="blood_type"
                    value={values.blood_type}
                    onChange={handleChange}
                    label="Blood Type"
                    helperText={
                      touched.blood_type && errors.blood_type ? errors.blood_type : ''
                    }
                    error={touched.blood_type && errors.blood_type ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="allergy_type"
                    value={values.allergy_type}
                    onChange={handleChange}
                    label="Allergy Type"
                    helperText={
                      touched.allergy_type && errors.allergy_type ? errors.allergy_type : ''
                    }
                    error={touched.allergy_type && errors.allergy_type ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditing}
                    id="dna"
                    value={values.dna}
                    onChange={handleChange}
                    label="DNA"
                    helperText={
                      touched.dna && errors.dna ? errors.dna : ''
                    }
                    error={touched.dna && errors.dna ? true : false}
                  />
                </Grid>
                <ActionBar
                  isEditing={isEditing}
                  isFetching={isFetching}
                  handleSubmit={handleSubmit}
                  handleDelete={() => dispatch(deletePatient(id))}
                />
              </Grid>
            </form>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <MedicalRecordListCard patientID={id}/>
      </Grid>
    </Grid>
  );
};

export default PatientEditPage;
