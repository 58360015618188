import api from '../../api/diagnosisAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'medicine.detail/FETCH_BEGIN';
export const FETCH_SUCCESS = 'medicine.detail/FETCH_SUCCESS';
export const FETCH_FAILED = 'medicine.detail/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchMedicine = id => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await api.get(`retrieve-medicine?id=${id}`);

    dispatch(fetchSuccess(res.data.result[0]));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, error: null };
    case FETCH_SUCCESS:
      return { ...state, data: payload };
    case FETCH_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
};

const initialState = {
  data: null,
  error: null
};
