import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import TextField from '../TextField';

export default function PasswordTextField({ ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      type={showPassword && !props.disabled ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              disabled={props.disabled}
            >
              {showPassword ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon style={{ opacity: '0.4' }} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
}
