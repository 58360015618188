import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ActionBarEdit as ActionBar } from '../../components/common/ActionBar';
import AppointmentTypeForm from './components/AppointmentTypeForm';

import { edit, fetchAppointmentType, fetchAppointmentTypev2 } from 'redux/appointmentType/detail';
import AppointmentModel from 'model/AppointmentModel';
// import { addAppointmentType } from 'redux/appointmentType/ops';

const AppointmentTypeEditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppointmentTypev2(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetching } = useSelector(
    state => state.appointmentType.detail
  );
  const { completed, isFetching: isLoadingOperation } = useSelector(
    state => state.appointmentType.ops
  );
  console.log("data",data)
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: data?.id,
      name: data?.booking_type_name || '',
      duration: data?.duration || 3,
      isPaymentProofRequired: !!data?.payment_proof_required,
      category_id : data?.category_id
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Cannot be empty'),
      duration: Yup.number().required('Cannot be empty')
    }),
    onSubmit: values => {
      values = {
        id: values.id,
        booking_type_name:values.name,
        duration: values.duration,
        payment_proof_required: !!values.isPaymentProofRequired,
        category_id : values.category_id
      }
      console.log(values)
      dispatch(edit(id,values))
      // dispatch(addAppointmentType(values));

    },
    enableReinitialize: true
  });

  if (isFetching) {
    return <CircularProgress />;
  }

  return (
    <AppointmentTypeForm
      isEditing={true}
      values={values}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      errors={errors}
      touched={touched}
      actionBar={
        <ActionBar
          isEditing={true}
          isFetching={isLoadingOperation}
          handleSubmit={handleSubmit}
          // handleReset={handleReset}
        />
      }
    />
  );
};

export default AppointmentTypeEditPage;
