import React, { useReducer, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import EditColumn from './EditColumn';
import { reducer, initialState, SIZE_PER_PAGE_LIST } from './actionsReducers';
import { overlayFactory } from './overlayFactory';
import { indicatorFactory } from './NoDataIndicator';
import { TramOutlined } from '@material-ui/icons';
import { Button } from "react-bootstrap";

const MySearch = (props) => {

  const [firstTime, setFirstTime] = useState(true)
  const [inputValue, setInputValue] = useState(props.value ? props.value : "")

  useEffect(() => {
    // console.log("props value", props.value)
    if (firstTime && props.value) {
      // console.log("FIRST TIME", props.value)
      handleChangeSpecial(props.value)
      setFirstTime(false)
    } else {
      console.log("SECOND TIME", props.value)
    }
  }, [props.value])

  let input;

  const handleChange = (e) => {
    console.log("SEARCHING??", e.target.value)
    setInputValue(e.target.value)
    props.onSearch(input.value);
    if (props.onCustomChange) {
      props.onCustomChange(input.value)
    }
    // console.log("ONSEARCH")
  };

  const handleChangeSpecial = (value) => {

    let contentTimer = setTimeout(async () => {
      props.onSearch(value);
      setInputValue(value)
      if (input) {
        // input.value = value
        console.log("SETTING INPUT", value)
      } else {
        console.log("NO INPUT")
      }
      // history.push(`/appointment?q=${value}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
    }, 500);

  }

  return (
    <div>
      <input
        onChange={(e) => {
          handleChange(e)
        }}
        className="form-control"
        {...props}
        ref={n => {
          // console.log("REFFING", n)
          input = n
        }}
        value={inputValue ? inputValue : ""}
        type="text"
      />
    </div>
  );
};


const DataTableLocal = ({
  data,
  loading,
  totalDataCount,
  columns,
  editLink,
  renderCustomActions,
  customActionBar,
  noEditAction,
  handleSearchChange,
  setSearchRef,
  noSearchAction = false,
  defaultSearchValue
}) => {

  let searchBarComponent;
  // console.log(renderCustomActions && noEditAction)
  const { SearchBar } = Search;

  const [queryParams] = useReducer(reducer, initialState);

  console.log("queryparams", queryParams)

  const tableColumns = [
    ...columns,
    !renderCustomActions && noEditAction
      ? {}
      : renderCustomActions && noEditAction ? {
        dataField: 'id',
        text: 'Actions',
        formatter: renderCustomActions,
        classes: 'text-right pr-0',
        headerClasses: 'text-right pr-3',
        style: {
          minWidth: '50px'
        }
      }
        : {
          dataField: 'id',
          text: 'Actions',
          formatter: EditColumn,
          formatExtraData: {
            editLink,
            renderCustomActions
          },
          classes: 'text-right pr-0',
          headerClasses: 'text-right pr-3',
          style: {
            minWidth: '50px'
          }
        }
  ];
  const [dataCount, setDataCount] = useState(0)
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: dataCount,
    sizePerPageList: SIZE_PER_PAGE_LIST,
    sizePerPage: queryParams.sizePerPage,
    // page: queryParams.page
  };

  return (
    <ToolkitProvider
      onSearch={() => { console.log("TEST1") }}
      keyField="id" data={data} columns={tableColumns} search>
      {props => (
        <div>
          <Grid container spacing={1}>
          {!noSearchAction &&
            <Grid item>
              <MySearch
                value={defaultSearchValue}
                onCustomChange={handleSearchChange}
                {...props.searchProps} />
            </Grid>
            }
            <Grid item>{customActionBar}</Grid>
          </Grid>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <Grid>
                <BootstrapTable
                  // remote={true}
                  onDataSizeChange={(result) => {
                    console.log('ppage result',result)
                    setDataCount(result.dataSize)
                  }}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  keyField="id"
                  data={data}
                  columns={tableColumns}
                  loading={loading}
                  overlay={overlayFactory()}
                  noDataIndication={indicatorFactory()}
                  {...paginationTableProps}
                  {...props.baseProps}
                ></BootstrapTable>
                <Grid container>
                  <Grid item xs={6}>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    direction="column"
                    alignItems="flex-end"
                  >
                    <PaginationListStandalone {...paginationProps} />
                    <PaginationTotalStandalone {...paginationProps} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </PaginationProvider>
        </div>
      )}
    </ToolkitProvider>
    // <PaginationProvider pagination={paginationFactory(paginationOptions)}>
    //   {({ paginationProps, paginationTableProps }) => (
    //     <Grid>
    //       <BootstrapTable
    //         wrapperClasses="table-responsive"
    //         bordered={false}
    //         classes="table table-head-custom table-vertical-center overflow-hidden"
    //         bootstrap4
    //         keyField="id"
    //         data={data}
    //         columns={tableColumns}
    //         loading={loading}
    //         overlay={overlayFactory()}
    //         noDataIndication={indicatorFactory()}
    //         {...paginationTableProps}
    //       ></BootstrapTable>
    //       <Grid container>
    //         <Grid item xs={6}>
    //           <SizePerPageDropdownStandalone {...paginationProps} />
    //         </Grid>
    //         <Grid
    //           item
    //           xs={6}
    //           container
    //           direction="column"
    //           alignItems="flex-end"
    //         >
    //           <PaginationListStandalone {...paginationProps} />
    //           <PaginationTotalStandalone {...paginationProps} />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   )}
    // </PaginationProvider>
  );
};

export default DataTableLocal;
