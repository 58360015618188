/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import {
  // Redirect,
  Switch,
  Route
} from 'react-router-dom';
import {
  // shallowEqual,
  useSelector
} from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
// import { Logout, AuthPage } from "./modules/Auth";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

import LoginPage from './pages/login/login';
import { ErrorPage1 } from './modules/ErrorsExamples/ErrorPage1';
import AppointmentPrintPreviewPage from 'app/pages/appointment/AppointmentPrintPreviewPage';
import MedicalRecordPrintPreviewPage from 'app/pages/medicalRecord/MedicalRecordPrintPreviewPage';

export function Routes() {
  const { user, token } = useSelector(state => state.auth);

  const isAuthorized = () => {
    if (user && token) {
      return true;
    } else {
      return false;
    }
  };

  const renderLogin = () => {
    return <Route component={LoginPage} />;
  };

  const renderHome = () => {
    return (
      <Layout>
        <BasePage />
      </Layout>
    );
  };

  return (
    <Switch>
      <Route exact path="/error" component={ErrorPage1} />
      <Route
        exact
        path="/print/appointment"
        component={AppointmentPrintPreviewPage}
      />
      <Route
        exact
        path="/print/medicalRecord"
        component={MedicalRecordPrintPreviewPage}
      />
      {isAuthorized() ? renderHome() : renderLogin()}
    </Switch>
  );

  // return (
  // 	<Switch>
  // 		{!isAuthorized ? (
  // 			/*Render auth page when user at `/auth` and not authorized.*/
  // 			<Route>
  // 				<AuthPage />
  // 			</Route>
  // 		) : (
  // 			/*Otherwise redirect to root page (`/`)*/
  // 			<Redirect from="/auth" to="/"/>
  // 		)}

  // 		<Route path="/error" component={ErrorsPage}/>
  // 		<Route path="/logout" component={Logout}/>

  // 		{!isAuthorized ? (
  // 			/*Redirect to `/auth` when user is not authorized*/
  // 			<Redirect to="/auth/login"/>
  // 		) : (
  // 			<Layout>
  // 				<BasePage/>
  // 			</Layout>
  // 		)}
  // 	</Switch>
  // );
}
