import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';
import {
  TextField,
  PasswordTextField
} from '../../components/common/TextField';
import KeyboardDatePicker from '../../components/common/KeyboardDatePicker';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';
import File from 'app/models/File';
import swal from 'sweetalert2';
import Doctor from 'app/models/Doctor';

const DoctorAddPage = () => {

  const { completed } = useSelector(state => state.doctor.ops);
  const isFetching = useSelector(state => state.loading.doctor);
  useEffect(() => {
    if (completed) {
      resetForm();
    }
  }, [completed]);

  const handleAddDoctor = async (values) => {
    let doctorModel = new Doctor()
    let uploadModel = new File()
    let formattedValues = {
      ...values,
      password: values.password,
      birthdate: format(values.dateOfBirth, 'yyyy-MM-dd'),
      mobile: values.phone
    }

    try{
      if(values.doctorImage){
        let imgUrl = await uploadModel.uploadFile(values.doctorImage);
        formattedValues = {...formattedValues, image_url: imgUrl.location}
      }
      let result = await doctorModel.create(formattedValues)
      console.log(result)
      console.log(formattedValues)
      swal
        .fire({
          title: 'Success',
          text: 'Doctor added successfully',
          icon: 'success',
          confirmButtonText: 'Go back',
          customClass: {
            container: 'swal-z'
          }
        })
      handleReset()
    }catch(e){
      console.log("error", e)
      swal
        .fire({
          title: 'Error',
          text: e.error_message ? e.error_message : 'Error occured',
          icon: 'error',
          confirmButtonText: 'Retry',
          customClass: {
            container: 'swal-z'
          }
        })
    }
  }
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      user_name: '',
      password: '',
      email: '',
      phone: '',
      dateOfBirth: null,
      doctorImage: null,
    },
    validationSchema: Yup.object({
      user_name: Yup.string().required('Cannot be empty'),
      password: Yup.string().required('Cannot be empty'),
      email: Yup.string().required('Cannot be empty'),
      phone: Yup.number().required('Cannot be empty'),
      dateOfBirth: Yup.date()
        .max(new Date(), 'Date cannot be in the future')
        .nullable(),
    }),
    onSubmit: values => {
      handleAddDoctor(values)
    }
  });

  return (
    <Card>
      <CardHeader title="Doctor details"></CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="user_name"
                value={values.user_name}
                onChange={handleChange}
                label="Full Name *"
                helperText={touched.user_name && errors.user_name ? errors.user_name : ''}
                error={touched.user_name && errors.user_name ? true : false}
              />
            </Grid>
            
            <Grid item xs={12}>
              <PasswordTextField
                id="password"
                value={values.password}
                onChange={handleChange}
                label="Password *"
                helperText={
                  touched.password && errors.password ? errors.password : ''
                }
                error={touched.password && errors.password ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                label="Email *"
                helperText={touched.email && errors.email ? errors.email : ''}
                error={touched.email && errors.email ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phone"
                value={values.phone}
                onChange={handleChange}
                label="Phone *"
                helperText={touched.phone && errors.phone ? errors.phone : ''}
                error={touched.phone && errors.phone ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                id="dateOfBirth"
                value={values.dateOfBirth}
                onChange={date => setFieldValue('dateOfBirth', date, true)}
                label="Date of Birth *"
                helperText={
                  touched.dateOfBirth && errors.dateOfBirth
                    ? 'Invalid date'
                    : ''
                }
                error={touched.dateOfBirth && errors.dateOfBirth ? true : false}
                disableFuture
                clearable
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                id="doctorImage"
                onChange={event => {
                  setFieldValue('doctorImage', event.currentTarget.files[0]);
                }}
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                type="file"
              />
              <label htmlFor="doctorImage">
                <Button color="primary" variant="outlined" component="span">
                  Upload Image
                </Button>
                <span style={{ marginLeft: '1rem' }}>{values.doctorImage?.name || ''}</span>
              </label>
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default DoctorAddPage;
