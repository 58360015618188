import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import { fetchAppointment } from 'redux/appointment/detail';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import Reminder from 'app/models/Reminder';
import AppointmentModel from '../../../model/AppointmentModel';
import TimePicker from 'app/components/common/TimePicker';

import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { TextField } from '../../components/common/TextField';
import { ActionBarAdd as ActionBar } from '../../components/common/ActionBar';

const ReminderAddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { role } = useSelector(state => state.auth);
  const [startTime, setStartTime] = useState(null);

  const createReminder = async values => {
    let reminderModel = new Reminder();

    try {
      let result = await reminderModel.create(values);
      swal.fire({
        title: 'Success',
        text: 'Reminder added successfully',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      resetForm();
    } catch (e) {
      console.log(e);
    }
  };

  const { data } = useSelector(state => state.appointment.detail);

  const fetchAppointmentForDoctor = async () => {
    try {
      let res = await AppointmentModel.getAppointmentDetail(id);

      dispatch({
        type: 'appointment.detail/FETCH_SUCCESS',
        payload: {
          id: res.id,
          start_time: res.start_time,
          end_time: res.end_time,
          created_at: res.created_at,
          expire_at: res.expire_at,
          clinic: res.clinic,
          operation_table: res.operation_table,
          owner: res.owner,
          description: res.description,
          additional_storage: res.description,
          doctor_id: res.doctor.id,
          doctor_name: res.doctor.doctor_name,
          co_doctor_id: null,
          co_doctor_name: null,
          patient_id: res.patients[0].id,
          patient_name: res.patients[0].patient_name,
          patients: res.patients,
          status: res.status,
          customer_id: res.owner.id,
          customer_name: res.owner.user_name,
          customer_mobile: res.owner.mobile,
          customer_email: res.owner.email,
          booking_type_name: res.bookingType.booking_type_name,
          duration: res.bookingType.duration,
          payment_status: res.payment_status
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    role === 'DOCTOR' ? fetchAppointmentForDoctor() : dispatch(fetchAppointment(id));
    console.log(data);
  }, []);

  const renderTimeInput = props => {
    return (
      <TextField
        disabled={props.disabled}
        value={`${props.value}`}
        onChange={props.onChange}
        onClick={props.onClick}
        label={props.label}
      />
    );
  };

  const { values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue, resetForm } = useFormik({
    initialValues: {
      appointment_user_id: id,
      user_id: data ? data.customer_id : '',
      patient_id: '',
      doctor_id: data ? data.doctor_id : '',
      title: '',
      description: '',
      notified_at: null,
      owner: data ? data.customer_name : '',
      email: data ? data.customer_email : '',
      start_time: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Cannot be empty'),
      description: Yup.string().required('Cannot be empty'),
      notified_at: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      const formattedValues = {
        appointment_user_id: id,
        user_id: data.customer_id,
        patient_id: values.patient_id,
        doctor_id: data.doctor_id,
        title: values.title,
        description: values.description,
        notified_at: values.notified_at + ' ' + values.start_time
      };

      createReminder(formattedValues);
    },
    enableReinitialize: 'true'
  });

  return (
    <Card>
      <div className="card-header">
        <div className="mt-4 card-title">
          <span
            style={{
              cursor: 'pointer'
            }}
          >
            <ArrowBackIosOutlined onClick={() => history.goBack()}></ArrowBackIosOutlined>
          </span>
          <h3 className="ml-3 mt-3 mb-2">Add Reminder</h3>
        </div>
      </div>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField id="owner" value={values.owner} onChange={handleChange} label="Owner" disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <TextField id="owner" value={values.email} onChange={handleChange} label="Email" disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                value={values.notified_at || ''}
                onChange={date => setFieldValue('notified_at', date, true)}
                label="Notify at *"
                helperText={touched.notified_at && errors.notified_at ? 'Invalid date' : ''}
                error={touched.notified_at && errors.notified_at ? true : false}
                clearable
                format="dd/MM/yyyy"
                InputLabelProps={{ shrink: values.notified_at ? true : false }}
              />
            </Grid>
            <Grid item xs={12}>
              Start time
              <TimePicker
                value={values.start_time}
                onChange={date => {
                  setStartTime(date);
                }}
                clearable
                TextFieldComponent={renderTimeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="patient_id"
                select
                value={values.patient_id}
                onChange={event => {
                  setFieldValue('patient_id', event.target.value);
                }}
                label="Pick Patient *"
              >
                {data &&
                  data.patients &&
                  data.patients.map((obj, key) => (
                    <MenuItem key={obj.id} value={obj.id}>
                      {`${obj.patient_name}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="title"
                value={values.title}
                onChange={handleChange}
                label="title *"
                helperText={touched.title && errors.title ? errors.title : ''}
                error={touched.title && errors.title ? true : false}
                type="title"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                value={values.description}
                onChange={handleChange}
                label="description"
                helperText={touched.description && errors.description ? errors.description : ''}
                error={touched.description && errors.description ? true : false}
                type="text"
              />
            </Grid>
            <ActionBar isFetching={false} handleSubmit={handleSubmit} handleReset={handleReset} />
          </Grid>
        </form>
      </CardBody>
    </Card>
  );
};

export default ReminderAddPage;
