import axios from '../../api/diagnosisAPI';
import { setErrorNotification } from '../snackbarNotification';

export const FETCH_BEGIN = 'medicine.list/FETCH_BEGIN';
export const FETCH_SUCCESS = 'medicine.list/FETCH_SUCCESS';
export const FETCH_FAILED = 'medicine.list/FETCH_FAILED';

const fetchBegin = () => ({ type: FETCH_BEGIN });
const fetchSuccess = data => ({ type: FETCH_SUCCESS, payload: data });
const fetchFailed = error => ({ type: FETCH_FAILED, payload: error });
export const fetchMedicineList = () => async dispatch => {
  dispatch(fetchBegin());

  try {
    const res = await axios.get('retrieve-medicine');

    dispatch(fetchSuccess(res.data.result));
  } catch (error) {
    dispatch(fetchFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BEGIN:
      return { ...state, error: null, isLoading: true };
    case FETCH_SUCCESS:
      return { ...state, data: payload, isLoading: false };
    case FETCH_FAILED:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  data: [],
  error: null
};
