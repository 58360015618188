import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import { TextField } from 'app/components/common/TextField';
import TimePicker from 'app/components/common/TimePicker';

import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Modal } from 'react-bootstrap';

import moment from 'moment';
import Appointment from '../../../models/Appointment';
import swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  paper: {
    width: '800px',
    maxWidth: '800px'
  },
  title: {
    marginBottom: '2rem',
    padding: '2rem 2rem 1rem 2rem',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  timeSlotContainer: {
    maxWidth: '360px'
  },
  datePicker: {
    marginBottom: '1rem'
  },
  actionContainer: {
    justifyContent: 'flex-start',
    marginTop: '2rem',
    padding: '1.5rem 2rem 1.5rem 2rem',
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

const EditAppointmentModal = ({ isOpen, handleClose, doctorID, data }) => {
  const classes = useStyles();
  const { role } = useSelector(state => state.auth);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);


  const [selectedBookingType, setSelectedBookingType] = useState(null);


  const onClose = refresh => {
    handleClose(refresh);
  };

  const submitForm = async () => {
    let appointmentModel = new Appointment();
    try {
      let result = await appointmentModel.editAppointmentSlot(data.id, { start_time: startTime, end_time: endTime });

      let confirmResult = await swal.fire({
        title: 'Success',
        text: 'Appointment berhasil diubah',
        icon: 'success',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });

      handleClose(true);
    } catch (e) {
      let error = await swal.fire({
        title: 'Error',
        text: e.error_message ? e.error_message : 'Terjadi Kesalahan',
        icon: 'error',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });

      console.log(e);
    }
  };

  useEffect(() => {
    reset();
    if (data) {
      setStartTime(moment(data.start_time, 'YYYY-MM-DDTHH:mm:ssZ').toDate());
      setEndTime(moment(data.end_time, 'YYYY-MM-DDTHH:mm:ssZ').toDate());
      setSelectedBookingType(data)
    }
  }, [data]);

  const reset = () => {
    setStartTime(null);
    setEndTime(null);
  };

  const renderTimeInput = props => {
    return (
      <TextField
        disabled={props.disabled}
        value={`${props.value}`}
        onChange={props.onChange}
        onClick={props.onClick}
        label={props.label}
      />
    );
  };

  return (
    <Modal show={isOpen} onHide={handleClose} classes={{ container: classes.container, paper: classes.paper }}>
      <form>
        <Modal.Title className={classes.title}>Edit Appointment</Modal.Title>
        <Modal.Body>
          <Row>
            <Col md={6}>
              Start time
              <TimePicker
                value={startTime}
                onChange={date => {
                  setStartTime(date);
                }}
                clearable
                TextFieldComponent={renderTimeInput}
              />
            </Col>
            <Col md={6}>
              End time
              <TimePicker
                value={endTime}
                onChange={date => setEndTime(date)}
                clearable
                TextFieldComponent={renderTimeInput}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={classes.actionContainer}>
          <>
            {role !== 'DOCTOR' && (
              <Button color="primary" variant="contained" className="mr-2"onClick={() => submitForm()}>
                Edit Appointment
              </Button>
            )}
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditAppointmentModal;
