import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const UPSERT_BEGIN = 'doctor.upsertAppointmentType/UPSERT_BEGIN';
export const UPSERT_SUCCESS = 'doctor.upsertAppointmentType/UPSERT_SUCCESS';
export const UPSERT_FAILED = 'doctor.upsertAppointmentType/UPSERT_FAILED';

const upsertBegin = () => ({ type: UPSERT_BEGIN });
const upsertSuccess = () => ({ type: UPSERT_SUCCESS });
const upsertFailed = error => ({ type: UPSERT_FAILED, payload: error });
export const upsertDoctorAppointmentTypes = data => async dispatch => {
  dispatch(upsertBegin());

  const formattedData = {
    doctor_id: data.doctor,
    booking_type_name_array: data.appointmentTypes
  };

  try {
    const res = await api.post(
      'bind-and-rebind-doctor-to-booking-type',
      formattedData
    );

    dispatch(upsertSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(upsertFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPSERT_BEGIN:
      return { ...state, completed: false, isLoading: true, error: null };

    case UPSERT_SUCCESS:
      return { ...state, completed: true, isLoading: false };

    case UPSERT_FAILED:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  isLoading: false,
  error: null
};
