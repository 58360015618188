import { Typography } from '@material-ui/core';
import {
  addDays,
  addHours,
  format,
  isSameDay,
  isSameHour, parseISO,
  startOfHour,
  startOfWeek
} from 'date-fns';

import EventMarker from './components/EventMarker';

import { useStyles } from './TimetableCss';
import React from "react";
import {useHistory} from "react-router-dom";

const TimeTable = ({ date, events, setAddModalVisibility, setEditModalVisibility, setBlockModalVisibility }) => {
  const classes = useStyles();
  const history = useHistory();

  const monday = startOfWeek(date, { weekStartsOn: 1 });

  console.log("RESU RENDERING", events)

  const renderCells = () => {
    const html = [];

    for (let i = 0; i < HOURS.length; i++) {
      const extraCell =
        i === 0 ? (
          <div key="extra" className={classes.noTopLeftBorder}></div>
        ) : null;
      extraCell && html.push(extraCell);

      const hourMarker = (
        <div key={i} className={classes.noHorizontalBorder}>
          <div className={classes.hourMarkContainer}>
            <span className={classes.hourMark}>
              {i > 0 && `${String(i).padStart(2, '0')}:00`}
            </span>
          </div>
        </div>
      );
      hourMarker && html.push(hourMarker);
    }

    for (let i = 0; i < WEEKLY.length; i++) {
      const currDate = addDays(monday, i);
      const currDateEvents = events.filter(event =>
        isSameDay(event.startDate, currDate)
      );
      for (let j = 0; j < HOURS.length; j++) {
        const currHour = addHours(currDate, j);
        const currHourEvents = currDateEvents.filter(event =>
          isSameHour(startOfHour(event.startDate), currHour)
        );

        const eventMarker = currHourEvents.map((event, index) => {

          let booked = event.booked

          let componentToReturn = [<EventMarker
              event={event} key={index} faded={event.faded}
          />]

          if(booked) {
            for (let b of booked) {

              let formatStatus = (paymentStatus, status) =>{
                if(status === "BAN"){
                  return "BAN"
                }
                if(status === "DONE"){
                  return "DONE"
                }
                if(paymentStatus === "UNPAID" || paymentStatus === "PAID" ){
                  return "WAITING"
                }
                return "UNDONE"
              }

              let formattedB = {
                ID: b.id,
                startDate: toJakartaDate(parseISO(b.start_time)),
                endDate: toJakartaDate(parseISO(b.end_time)),
                slotStartTime : event.startDate,
                clinic_name : b.clinic_name ? b.clinic_name : null,
                title: b.bookingType? b.bookingType.booking_type_name : "",
                content: b.patients ? b.patients.map(obj=>obj.patient_name).toString() : "",
                status : formatStatus(b.payment_status, b.status),
                booked : b.booked,
                clickAction: ID => {
                  console.log("BRUH", b)
                  history.push(`/appointment/${b.id}`);
                }
              }

              if(b.payment_status === "EXPIRED"){
                continue
              }

              console.log("BBBFS", formatStatus(b.payment_status, b.status))

              // console.log("BBB", b.status, b.payment_status)

              componentToReturn.push(<EventMarker
                  banned={b.status === "BAN"}
                  event={formattedB} key={index} faded={false}
                  setAddModalVisible={setAddModalVisibility}
                  setEditModalVisible={setEditModalVisibility}
                  setBlockModalVisible={setBlockModalVisibility}

              />)
            }
          }

          return componentToReturn

          /**
           * LOGIC :
           * 1. list all booked
           * 2.
           * **/


        });

        const dayMarker =
          j === 0 ? (
            <div key={`${i}-day`} className={classes.noTopBorder}>
              <Typography align="center">{format(currDate, 'EEEE')}</Typography>
              <Typography align="center" className={classes.tableDate}>
                {format(currDate, 'dd MMM yyyy')}
              </Typography>
            </div>
          ) : null;
        dayMarker && html.push(dayMarker);

        const cell = <div
            // style={{
            //   height : "100%"
            // }}
            key={`${i}-${j}-cell`}>
          {/*<div style={{*/}
          {/*  // background : "green",*/}
          {/*  width : "100%",*/}
          {/*  height : "100%",*/}
          {/*  display : "flex",*/}
          {/*  flexDirection : "column"*/}
          {/*}}>*/}
            {eventMarker}
          {/*</div>*/}
        </div>;

        html.push(cell);
      }
    }

    return html;
  };

  return <div className={classes.table}>{renderCells()}</div>;
};

export default TimeTable;

const WEEKLY = [0, 1, 2, 3, 4, 5, 6];
const HOURS = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];

const toJakartaDate = date => {
  let tzDate = new Date(
      date.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
  );

  return tzDate;
};
