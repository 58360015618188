import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { Assignment as AssignmentIcon, DeleteOutlineTwoTone } from '@material-ui/icons';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchDoctorList } from '../../../redux/doctor/list';
import Doctor from 'app/models/Doctor';
import swal from 'sweetalert2';

const DoctorListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDoctorList());
  }, []);

  const { data } = useSelector(state => state.doctor.list);
  const isFetching = useSelector(state => state.loading.doctor);

  const columns = [
    {
      dataField: 'doctor_name',
      text: 'name'
    },
    {
      dataField: 'mobile',
      text: 'phone'
    },
    {
      dataField: 'email',
      text: 'email'
    }
  ];

  const handleDeleteDoctor = async doctor => {
    let doctorModel = new Doctor();

    try {
      await doctorModel.deleteDoctorById(doctor.id);
      dispatch(fetchDoctorList());
      swal.fire({
        title: `${doctor.doctor_name} deleted`,
        icon: 'success',
        confirmButtonText: 'Confirm',
        customClass: {
          container: 'swal-z'
        }
      });
    } catch (e) {
      console.log('error', e)
    }
  };
  return (
    <>
      <Card>
        <CardHeader title="Doctor list">
          <ListHeaderToolbar addLink="/doctor/add" addText="New Doctor" />
        </CardHeader>
        <CardBody>
          <DataTable
            data={data}
            loading={isFetching}
            totalDataCount={data.length}
            columns={columns}
            editLink="/doctor/edit/"
            renderCustomActions={row => {
              return (
                <>
                  <Tooltip title="Schedule">
                    <IconButton
                      onClick={() => {
                        // setSelectedDoctor({ ...row });
                        // setIsScheduleDialogOpen(true);
                        history.push(`/doctor/${row.id}/schedule`);
                      }}
                      size="small"
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        swal
                          .fire({
                            title: `Are you sure you want to delete ${row.doctor_name} ?`,
                            showCancelButton: true,
                            text: 'Confirm the deletion',
                            icon: 'warning',
                            confirmButtonText: 'Confirm',
                            cancelButtonText: 'Cancel',
                            customClass: {
                              container: 'swal-z'
                            }
                          })
                          .then(result => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                              handleDeleteDoctor(row);
                            }
                          });
                      }}
                      size="small"
                    >
                      <DeleteOutlineTwoTone />
                    </IconButton>
                  </Tooltip>
                </>
              );
            }}
          />
        </CardBody>
      </Card>
      {/* <DoctorScheduleDialog
        open={isScheduleDialogOpen}
        handleClose={() => setIsScheduleDialogOpen(false)}
        data={selectedDoctor}
      /> */}
    </>
  );
};

export default DoctorListPage;
