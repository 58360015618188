import ApiRequest from '../../utils/APIRequest';

export default class Reminder {
  create = async (newReminder) => {
    return await ApiRequest.set('v1/visit-reminder', 'POST', newReminder);
  };

  getAllReminderByAppointmentUserId = async (appointmentUserId) => {
    return await ApiRequest.set('v1/visit-reminder/appointment-user/'+appointmentUserId, 'GET');
  };

  getAllReminderByAppointmentUserIdForDoctor = async (appointmentUserId) => {
    return await ApiRequest.set('v1/visit-reminder/appointment-user/'+appointmentUserId, 'POST', {});
  };

  edit = async (reminderId, editValues) => {
    return await ApiRequest.set('v1/visit-reminder/'+reminderId, 'PUT', editValues);
  }

  getById = async (reminderId) => {
    return await ApiRequest.set('v1/visit-reminder/'+reminderId, 'GET');
  }
}
