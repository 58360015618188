import React from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import ResetPasswordForm from './components/ResetPasswordForm';
import ChangePasswordForm from './components/ChangePasswordForm';

const ResetPasswordPage = () => {
  const { role } = useSelector(state => state.auth);

  return (
    <Card>
      <CardHeader title="Reset Password" />
      <CardBody>
        {role === 'ADMIN' ? <ResetPasswordForm /> : <ChangePasswordForm />}
      </CardBody>
    </Card>
  );
};

export default ResetPasswordPage;
