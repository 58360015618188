import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { TextField } from '../../../components/common/TextField';
import { ActionBarAdd as ActionBar } from '../../../components/common/ActionBar';
import { addSymptom } from '../../../../redux/symptom/ops';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#0000004D'
  },
  dividers: {
    borderBottom: 'none'
  }
});

export default function SymptomAddDialog({ open, handleClose }) {
  // Prereq Hooks
  const dispatch = useDispatch();
  const classes = useStyles();

  const { completed } = useSelector(state => state.symptom.ops);
  const isFetching = useSelector(state => state.loading.symptom);
  useEffect(() => {
    if (completed) {
      resetForm();
      handleClose();
    }
  }, [completed]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    resetForm
  } = useFormik({
    initialValues: {
      symptomName: ''
    },
    validationSchema: Yup.object({
      symptomName: Yup.string().required('Cannot be empty')
    }),
    onSubmit: values => {
      const formattedValues = {
        name: values.symptomName
      };

      dispatch(addSymptom(formattedValues));
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      classes={{ root: classes.root }}
    >
      <DialogTitle>Add Symptom</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers classes={{ dividers: classes.dividers }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="symptomName"
                value={values.symptomName}
                onChange={handleChange}
                helperText={
                  touched.symptomName && errors.symptomName
                    ? errors.symptomName
                    : ''
                }
                error={touched.symptomName && errors.symptomName ? true : false}
                label="Symptom Name *"
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            <ActionBar
              isFetching={isFetching}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
}
