import api from '../../api/userAPI';
import {
  setSuccessNotification,
  setErrorNotification
} from '../snackbarNotification';

export const ADD_BEGIN = 'patient.ops/ADD_BEGIN';
export const ADD_SUCCESS = 'patient.ops/ADD_SUCCESS';
export const ADD_FAILED = 'patient.ops/ADD_FAILED';
export const EDIT_BEGIN = 'patient.ops/EDIT_BEGIN';
export const EDIT_SUCCESS = 'patient.ops/EDIT_SUCCESS';
export const EDIT_FAILED = 'patient.ops/EDIT_FAILED';
export const DELETE_BEGIN = 'patient.ops/DELETE_BEGIN';
export const DELETE_SUCCESS = 'patient.ops/DELETE_SUCCESS';
export const DELETE_FAILED = 'patient.ops/DELETE_FAILED';

const addBegin = () => ({ type: ADD_BEGIN });
const addSuccess = () => ({ type: ADD_SUCCESS });
const addFailed = error => ({ type: ADD_FAILED, payload: error });
export const addPatient = data => async dispatch => {
  dispatch(addBegin());

  try {
    const formData = new FormData();

    formData.append('patient_name', data.name);
    formData.append('animal_type', data.animalType);
    formData.append('breed', data.breed);
    formData.append('pet_owner', data.owner);
    formData.append('age', data.age);
    formData.append('gender', data.gender);
    formData.append('patient_attachment', data.patientImage);

    const res = await api({
      url: 'add-patient',
      method: 'POST',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    dispatch(addSuccess());
    dispatch(setSuccessNotification('Successfully added data'));
  } catch (error) {
    dispatch(addFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const editBegin = () => ({ type: EDIT_BEGIN });
const editSuccess = () => ({ type: EDIT_SUCCESS });
const editFailed = error => ({ type: EDIT_FAILED, payload: error });
export const editPatient = (id, data) => async dispatch => {
  dispatch(editBegin());

  //   const res = await api({
  //     url: 'add-patient',
  //     method: 'POST',
  //     data: formData,
  //     headers: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   });

  // const formattedData = {
  //   id,
  //   patient_name: data.name,
  //   pet_owner: data.owner,
  //   animal_type: data.animalType,
  //   breed: data.breed,
  //   age: data.age,
  //   gender: data.gender
  // };

  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('patient_name', data.name);
    formData.append('animal_type', data.animalType);
    formData.append('breed', data.breed);
    formData.append('pet_owner', data.owner);
    formData.append('age', data.age);
    formData.append('gender', data.gender);
    formData.append('patient_attachment', data.patientImage);

    // await api.post('update-patient', formattedData);

    await api({
      url: 'update-patient',
      method: 'POST',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    dispatch(editSuccess());
    dispatch(setSuccessNotification('Successfully edited data'));
  } catch (error) {
    dispatch(editFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const deleteBegin = () => ({ type: DELETE_BEGIN });
const deleteSuccess = () => ({ type: DELETE_SUCCESS });
const deleteFailed = error => ({ type: DELETE_FAILED, payload: error });
export const deletePatient = id => async dispatch => {
  dispatch(deleteBegin());

  const data = {
    id
  };

  try {
    const res = await api.delete(`delete-patient?id=${id}`, { data });

    dispatch(deleteSuccess());
    dispatch(setSuccessNotification('Successfully deleted data'));
  } catch (error) {
    dispatch(deleteFailed(error.toString()));
    dispatch(setErrorNotification(error.toString()));
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_BEGIN:
    case EDIT_BEGIN:
    case DELETE_BEGIN:
      return { ...state, completed: false, error: null };

    case ADD_SUCCESS:
    case EDIT_SUCCESS:
    case DELETE_SUCCESS:
      return { ...state, completed: true };

    case ADD_FAILED:
    case EDIT_FAILED:
    case DELETE_FAILED:
      return { ...state, error: payload };

    default:
      return state;
  }
};

export default reducer;

const initialState = {
  completed: false,
  error: null
};
