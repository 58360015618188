import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import LoadingCard from 'app/components/common/LoadingCard/LoadingCard';

import Reminder from 'app/models/Reminder';
import { fetchAppointment } from 'redux/appointment/detail';

const ReminderListCard = ({ appointmentId }) => {
  const [reminderList, setReminderList] = useState([]);
  const { role } = useSelector(state => state.auth)

  const history = useHistory();

  const getReminderList = async () => {
    let reminderModel = new Reminder();

    try {
      let result = role === 'DOCTOR' ? await reminderModel.getAllReminderByAppointmentUserIdForDoctor(appointmentId): await reminderModel.getAllReminderByAppointmentUserId(appointmentId);
      console.log(result);
      setReminderList(result);
    } catch (e) {
      console.log('error: ', e);
    }
  };

  const handleNewReminder = () => {
    history.push({
      pathname: history.location.pathname+'/reminder',
      state: { appointmentId }
    });
  };
  useEffect(() => {
    getReminderList();
  }, []);


  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{ whiteSpace: 'pre-line' }}>{cell}</p>;
      }
    },
    {
      dataField: 'notified_at',
      text: 'Notify at',
      style: {
        width: '90px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && format(parseISO(cell), 'dd MMMM yyyy');
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: {
        width: '300px'
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell && <p style={{ whiteSpace: 'pre-line' }}>{cell}</p>;
      }
    }
  ];

  return (
    <Card>
      <CardHeader
        title="Reminder list"
        toolbar={
          <Grid item className="my-6">
            <Button color="primary" variant="contained" onClick={handleNewReminder}>
              New Reminder
            </Button>
          </Grid>
        }
      />
      <CardBody>
        <DataTable
          data={reminderList}
          loading={false}
          totalDataCount={reminderList.length}
          columns={columns}
          editLink={`${history.location.pathname}/reminder/edit/`}
        />
      </CardBody>
    </Card>
  );
};

export default ReminderListCard;
